import { RewardType } from "endless-siege-engine/build/main/lib/Types";
import { AudioManager } from "../../../AudioManager";
import { GameVars } from "../../../GameVars";
import { Card } from "./Card";
import { ChestsManager } from "./ChestsManager";

export class Chest {

    private text: Phaser.GameObjects.Text;
    private spine: any;
    private button: Phaser.GameObjects.Image;
    private rewardType: RewardType;
    private position: {x: number, y: number};
    private scene: Phaser.Scene;
    private chestManager: ChestsManager;
    private rewardIndex: number;
    private reward: number;
    private selected: boolean;
    private card: Card;

    constructor(scene: Phaser.Scene, position: {x: number, y: number}, rewardType: RewardType, chestManager: ChestsManager) {
        this.scene = scene;
        this.position = position;
        this.rewardType = rewardType;
        this.chestManager = chestManager;

        this.rewardIndex = 0;
        this.reward = 0;
        this.selected = false;

        this.spine = (this.scene as any).add.spine(position.x, position.y, "chests");
        this.spine.skeleton.setToSetupPose();
        if (GameVars.currentScene.game.device.os.desktop) {
            this.spine.setScale(.8);
        }
        this.spine.scaleY = GameVars.scaleY * .8;
        this.spine.setSkin(null);
        this.chestManager.add(this.spine);
    }

    public start(): void {
        this.text = new Phaser.GameObjects.Text(this.scene, this.position.x - 15, this.position.y + 165, "", {fontFamily: "Supercell", fontSize: "16px", color: "#FFE734", align: "center"});
        this.text.setOrigin(.5);
        this.text.setStroke("#B35B20", 8);
        this.text.setShadow(3, 3, "#700000");

        switch (this.rewardType) {
            case RewardType.NEXT_ROUND_CREDITS: {
                this.spine.setSkinByName("chest_waves_money_01");
                this.text.y += 15;
                this.text.text = GameVars.gameText[GameVars.gameData.language].CREDITS_NEXT_ROUND;
                break; 
            }
            case RewardType.ENEMY_SPEED: {
                this.spine.setSkinByName("chest_waves_slow_enemies_01");
                this.text.text = GameVars.gameText[GameVars.gameData.language].ENEMIES_SPEED;
                break; 
            }
            case RewardType.BALLISTA_RANGE:
            case RewardType.BALLISTA_DMG:
            case RewardType.BALLISTA_COSTS:
                this.spine.setSkinByName("chest_arch_01");
                this.text.text = GameVars.gameText[GameVars.gameData.language].IMPROVE_BALLISTA;
                break; 
            case RewardType.TORCH_RANGE:
            case RewardType.TORCH_DMG:
            case RewardType.TORCH_COSTS:
                this.spine.setSkinByName("chest_waves_torch_01");
                this.text.text = GameVars.gameText[GameVars.gameData.language].IMPROVE_TORCH;
                break; 
            case RewardType.CANNON_RANGE:
            case RewardType.CANNON_DMG:
            case RewardType.CANNON_COSTS:
                this.spine.setSkinByName("chest_waves_cannons_01");
                this.text.text = GameVars.gameText[GameVars.gameData.language].IMPROVE_CANNON;
                break; 
            case RewardType.TIME_WARP_RANGE:
            case RewardType.TIME_WARP_COSTS:
            case RewardType.TIME_WARP_SPEED: 
                this.spine.setSkinByName("chest_waves_time_01");
                this.text.text = GameVars.gameText[GameVars.gameData.language].IMPROVE_TIME_WARP;
                break; 
            default:
                break;
        }
        this.text.visible = false;
        this.chestManager.add(this.text);
        this.spine.play("chest_appears", false);

        this.setButtons();
        this.spineCompleteEvents();
    }

    public onOpenChest(reward: number, rewardIndex: number): void {
        this.reward = reward;
        this.rewardIndex = rewardIndex;
        this.button.visible = false;

        if (this.selected) {
            setTimeout(() => {
                this.scene.tweens.add({
                    targets: this.spine,
                    x: 20,
                    ease: Phaser.Math.Easing.Cubic.In,
                    duration: 450,
                });
                this.scene.tweens.add({
                    targets: this.text,
                    x: this.text.x + (20 - this.spine.x),
                    ease: Phaser.Math.Easing.Cubic.In,
                    duration: 450,
                });
            }, 2200);

            setTimeout(() => {
                this.spine.play("chest_selected_opening", false);
            }, 1000);

            this.card = new Card(this.scene, 0, this.position.y - 55, 0, this.position.y - 100, this.rewardType, this.reward, this.rewardIndex, true);
        } else {
            this.spine.play("chest_no_selected_opening", false);
            this.card = new Card(this.scene, this.position.x - 10, this.position.y + 15, 0, this.position.y - 80, this.rewardType, this.reward, this.rewardIndex, false);
        }
        this.card.visible = false;
        this.chestManager.add(this.card);
    }

    public getRewardType(): RewardType {
        return this.rewardType;
    }

    public playAnimation(anim: string) {
        this.spine.play(anim, false);
    }

    private spineCompleteEvents(): void {
        this.spine.state.addListener({
            complete: (entry, state) => {
                if (entry.animation.name === "chest_appears") {
                    this.spine.play("chest_selected", false);
                    this.button.visible = true;
                    this.text.visible = true;
                    this.chestManager.chestReady();
                } else if (entry.animation.name === "chest_selected_opening") {
                    this.chestManager.onSelectedChestOpened(this);
                } else if (entry.animation.name === "chest_no_selected_opening") {
                    setTimeout(() => {
                        this.spine.play("chest_no_selected_alpha", false);
                        this.scene.tweens.add({
                            targets: this.card,
                            alpha: 0,
                            ease: Phaser.Math.Easing.Cubic.In,
                            duration: 400,
                        });
                        this.scene.tweens.add({
                            targets: this.text,
                            alpha: 0,
                            ease: Phaser.Math.Easing.Cubic.In,
                            duration: 400,
                        });
                    }, 200);
                } 
            },
            event: (entry, event) => {
                if (event.data.name == "open_discarded") {
                    AudioManager.playSoundEffect("open_discarded");
                } else if (event.data.name == "chest_thud") {
                    AudioManager.playSoundEffect("chest_thud");
                }
                else if (event.data.name == "chest_shakes") {
                    AudioManager.playSoundEffect("chest_shakes");
                }
                else if (event.data.name == "chest_rollover") {
                    AudioManager.playSoundEffect("chest_rollover");
                }
                else if (event.data.name == "chest_falls") {
                    AudioManager.playSoundEffect("chest_falls");
                }
                else if (event.data.name == "chest_opens") {
                    AudioManager.playSoundEffect("chest_opens");
                }
                else if (event.data.name == "chest_revealed") {
                   if (this.rewardIndex > 6) {
                        AudioManager.playSoundEffect("top_card_revealed");
                   }
                   else {
                        AudioManager.playSoundEffect("regular_card_revealed");
                   }
                }
            }
        }, this);        
    }

    private setButtons(): void {
        this.button = new Phaser.GameObjects.Image(this.scene, this.position.x - 20, this.position.y + 40, "texture_atlas_1", "btn_yellow");

        this.button.setInteractive({ useHandCursor: true });
        this.button.scaleX = 1.9;
        this.button.scaleY = 1.75;
        this.button.alpha = 0.00001;
        this.button.visible = false;

        this.button.on("pointerover", () => {
            if (this.button.visible && this.chestManager.areChestsReady()) {
                this.chestManager.onChestOver(this);
            }
        });
        this.button.on("pointerout", () => { 
            if (this.button.visible && this.chestManager.areChestsReady()) {
                this.chestManager.onChestOut(this);
            }
        });
        this.button.on("pointerdown", () => {
            if (this.button.visible && this.chestManager.areChestsReady()) {
                this.selected = true;
                this.chestManager.onChestSelected(this);
            }
        });

        this.chestManager.add(this.button);
    }
}
