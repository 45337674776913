import { CoinsEffect } from "./coins/CoinsEffect";
import { BoardContainer } from "./board-container/BoardContainer";
import { GUI } from "./gui/GUI";
import { HUD } from "./hud/HUD";
import { BattleManager } from "./BattleManager";
import { GameVars } from "../../GameVars";
import { AudioManager } from "../../AudioManager";
import { FxEnemyTraspass } from "./FxEnemyTraspass";
import { TurretMenu } from "./turret-menu/TurretMenu";
import { Bug } from "../../utils/Bug";
import { GameOverLayer } from "./GameOverLayer";
import { TutorialManager } from "./TutorialManager";
import { TutorialLayer } from "./TutorialLayer";
import { GameConstants } from "../../GameConstants";
import { GameManager } from "../../GameManager";
import { CoinsEffectPool } from "./coins/CoinsEffectPool";
import { ChestsManager } from "./chests/ChestsManager";
import * as Creepts from "endless-siege-engine";

export class BattleScene extends Phaser.Scene {

    public static currentInstance: BattleScene;

    public hud: HUD;
    public gui: GUI;
    public boardContainer: BoardContainer;
    public chestsManager: ChestsManager;

    private turretMenuContainer: Phaser.GameObjects.Container;
    private coinsContainer: Phaser.GameObjects.Container;
    private turretMenu: TurretMenu;
    private tutorialLayer: TutorialLayer;
    private coinsOnStage: number;
    private coinsEffectPool: CoinsEffectPool;

    constructor() {

        super("BattleScene");

        BattleScene.currentInstance = this;
    }

    public create(): void {

        GameVars.currentScene = this;

        GameVars.doingPayChestRound = false;
        GameVars.timeToReward = false;

        this.coinsOnStage = 0;
        this.coinsEffectPool = new CoinsEffectPool(this, 5);

        this.anims.resumeAll();

        BattleManager.init();

        this.cameras.main.fadeIn(500, 33, 56, 68);

        AudioManager.playMusic("endless_siege_game_soundtrack");

        BattleManager.semiResume();

        BattleScene.currentInstance.gui.activeNextWave();
    }

    public start() {
        this.boardContainer = new BoardContainer(this);
        this.add.existing(this.boardContainer);

        this.hud = new HUD(this);
        this.add.existing(this.hud);

        this.gui = new GUI(this);
        this.add.existing(this.gui);

        this.coinsContainer = this.add.container(0, 0);
        this.boardContainer.bringToTop(this.coinsContainer);

        this.turretMenuContainer = this.add.container(this.boardContainer.x, this.boardContainer.y);
        this.turretMenuContainer.setScale(this.boardContainer.scaleX, this.boardContainer.scaleY);

        GameVars.ballistaUpgraded = false;
        GameVars.torchUpgraded = false;
        GameVars.cannonUpgraded = false;
        GameVars.timeWarpUpgraded = false;
        GameVars.timeToReward = false;

        if (Math.random() > .995) {
            this.anims.create({ key: "bug_walking", frames: this.anims.generateFrameNames("texture_atlas_1", { prefix: "bug_", start: 1, end: 15, zeroPad: 4, suffix: "" }), frameRate: 20, repeat: -1 });
            const bug = new Bug(this);
            this.add.existing(bug);
        }

        if (!GameVars.gameData.tutorialSeen) {

            TutorialManager.init();

            this.tutorialLayer = new TutorialLayer(this);
            this.add.existing(this.tutorialLayer);

            this.tutorialLayer.show(GameVars.gameText[GameVars.gameData.language].TUTORIAL_2, GameVars.gameText[GameVars.gameData.language].TUTORIAL_1);

        } else {
            this.tutorialLayer = null;
        }

        this.cameras.main.fadeIn(500, 33, 56, 68);

        AudioManager.playMusic("endless_siege_game_soundtrack");

        this.turretMenu = new TurretMenu(this);
        this.turretMenuContainer.add(this.turretMenu);
        this.turretMenu.visible = false;
    }

    public update(time: number, delta: number): void {

        if (!GameVars.paused && !GameVars.semiPaused && !GameVars.waveOver) {
            BattleManager.update(time, delta);
            this.boardContainer.update(time, delta);
        }

        if (this.hud) {
            this.hud.update(time, delta);
        }

        if (this.boardContainer) {
            this.boardContainer.sortActors();
            this.boardContainer.updatePauseMenu();
        }

        if (this.coinsEffectPool) {
            this.coinsEffectPool.update();
        }

        if (this.chestsManager) {
            this.chestsManager.update();
        }

        if (this.turretMenu && this.turretMenu.visible) {
            this.turretMenu.updateMenu();
        }
    }

    public showChestsLayer(): void {
        this.chestsManager = new ChestsManager(this);
        this.add.existing(this.chestsManager);
        this.chestsManager.setScale(this.boardContainer.scaleX, this.boardContainer.scaleY);
        this.chestsManager.x = this.boardContainer.x;
        this.chestsManager.y = this.boardContainer.y - 150;
        this.boardContainer.hideRangeCircles();
        this.hideTurretMenu();
    }

    public hideChestLayer(): void {
        this.chestsManager.destroy();
        GameVars.paused = false;
        BattleManager.engine.rewardFinishedAndContinueGame();

        BattleManager.AddOnContinueAfterChestAction();
    }

    public showChestLayerAgain(): void {
        this.chestsManager.destroy();
        GameVars.doingPayChestRound = true;
        this.showChestsLayer();
    }

    public createTurret(type: string): void {

        this.gui.createTurret(type);
        this.hideTurretMenu();
        this.boardContainer.hideRangeCircles();
        this.boardContainer.showAvailablePositions();
    }

    public showTurretMenu(turret: Creepts.Turret): void {
        if (!this.turretMenu.visible) {
            this.turretMenu.visible = true;
            this.turretMenu.setTurretMenu(turret);
            this.boardContainer.showTurretsLevelLabel();
        }
    }

    public hideTurretMenu(): void {

        if (this.turretMenu != null && this.turretMenu.visible) {
            this.boardContainer.hideTurretsLevelLabel();
            this.turretMenu.clearTurretMenu();
            this.turretMenu.visible = false;
        }
    }

    public updateTurretMenu(): void {

        if (this.turretMenu) {
            this.turretMenu.checkAndUpdateInfo();
        }
    }

    public onEnemyReachedExit(): void {

        const fx = new FxEnemyTraspass(this);
        this.add.existing(fx);

        this.hud.onEnemyReachedExit();
    }

    public addCoinsEffect(x: number, y: number, value: number): void {

        if (GameVars.gameOver || this.coinsOnStage > 75) {
            return;
        }

        const px = this.boardContainer.x + (this.boardContainer.board.x + x) * GameVars.scaleCorrectionFactor;
        const py = this.boardContainer.y + (this.boardContainer.board.y + y - 50) * GameVars.scaleCorrectionFactor * GameVars.scaleY;

        const coinsEffect = this.coinsEffectPool.getCoinsEffect(px, py, value);
        this.coinsContainer.add(coinsEffect);

        this.coinsOnStage += coinsEffect.n;
    }

    public onCoinsRemoved(coinsEffect: CoinsEffect): void {

        this.coinsOnStage -= coinsEffect.n;
        coinsEffect.visible = false;
        this.coinsEffectPool.onCoinsEffectRemoved(coinsEffect);
        this.coinsContainer.remove(coinsEffect);
    }

    public showGameOverLayer(): void {

        this.boardContainer.onGameOver();

        const gameOverLayer = new GameOverLayer(this);
        this.add.existing(gameOverLayer);

        this.hideTurretMenu();

        AudioManager.stopMusic();

        this.time.delayedCall(850, function (): void {
            AudioManager.playSoundEffect("gameover");
        }, [], this);

        this.time.delayedCall(1500, function (): void {
            AudioManager.playMusic("gameover_loop");
        }, [], this);
    }

    public newWave(bonus: number): void {

        this.hud.updateRound();
    }

    public hideButtonsAtTutorialStart(): void {

        this.gui.hideButtonsAtTutorialStart();
    }

    public onTutorialTurredSelected(): void {

        this.boardContainer.markCellOnTutorial();

        this.tutorialLayer.hide();
    }

    public onTutorialTurretPlaced(): void {

        this.gui.showNextWaveButtonInTutorial();

        this.tutorialLayer.show(GameVars.gameText[GameVars.gameData.language].TUTORIAL_3);
    }

    public onFirstWaveReleased(): void {

        this.tutorialLayer.hide();
    }

    public onFirstEnemyKilled(): void {

        this.tutorialLayer.show(GameVars.gameText[GameVars.gameData.language].TUTORIAL_4);

        this.boardContainer.onFirstEnemyKilled();
    }

    public onTurretMenuShown(): void {

        this.tutorialLayer.hide();
    }

    public onTurretUpgraded(): void {

        this.gui.showButtonsAfterTutorialCompletion();

        this.boardContainer.hideRangeCircles();
        this.hideTurretMenu();

        this.tutorialLayer.show(GameVars.gameText[GameVars.gameData.language].TUTORIAL_5);
    }

    public onSpeedX2(): void {

        this.tutorialLayer.show(GameVars.gameText[GameVars.gameData.language].TUTORIAL_6);
    }

    public onSpeedX3(): void {

        this.tutorialLayer.show(GameVars.gameText[GameVars.gameData.language].TUTORIAL_7);
    }

    public onSpeedX1(): void {

        this.tutorialLayer.hide();
    }

    public onTutorialOver(): void {

        this.tutorialLayer.showFinalMenu(GameVars.gameText[GameVars.gameData.language].TUTORIAL_8);
    }

    public semiPause(): void {

        this.boardContainer.showTurretsLevelLabel();
    }

    public semiResume(): void {

        this.boardContainer.hideTurretsLevelLabel();
    }
}
