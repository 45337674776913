import "phaser";
import "howler";

import { Game } from "./Game";
import { GameConstants } from "./GameConstants";
import { BootScene } from "./scenes/BootScene";
import { PreloadScene } from "./scenes/PreloadScene";
import { BattleScene } from "./scenes/battle-scene/BattleScene";
import { SplashScene } from "./scenes/splash-scene/SplashScene";
import { GameManager } from "./GameManager";
import { GraphicsScene } from "./scenes/graphics/GraphicsScene";
import { AudioManager } from "./AudioManager";
import { GameVars } from "./GameVars";

let game: Game;

declare global {
    interface Window {
      HackneyGamesSDK?: any;
      Telegram?: any;
      devtodev?: any;
    }
}

window.onload = () => {

    const gameConfig = {

        version: GameConstants.VERSION,
        type: Phaser.WEBGL,
        backgroundColor: "#000000",
        scale: {
            mode: Phaser.Scale.FIT,
            autoCenter: Phaser.Scale.CENTER_BOTH,
            parent: "content",
            width: GameConstants.GAME_WIDTH,
            height: GameConstants.GAME_HEIGHT
        },
        plugins: {
            scene: [
                {
                    key: "SpineWebGLPlugin",
                    plugin: SpineWebGLPlugin,
                    mapping: "spine"
                }
            ]
        },
        scene: [
            BootScene,
            PreloadScene,
            SplashScene,
            BattleScene,
            GraphicsScene
        ]
    };

    game = new Game(gameConfig);

    window.focus();
    resize();

    let w = window.innerWidth;
    let h = window.innerHeight;
    setInterval(() => {
        if (window.innerWidth != w || window.innerHeight != h) {
            w = window.innerWidth;
            h = window.innerHeight;
            window.dispatchEvent(new Event("resize"));
        }
    }, 100);

    // window.addEventListener("resize", resize, false);
    window.addEventListener("orientationchange", onOrientationChange, false);
};

function onOrientationChange(): void {

    setTimeout(() => {

        const w = window.innerWidth;
        const h = window.innerHeight;

        let isPortrait = w < h;

        if (isPortrait) {

            if (BootScene.bootedInWrongOrientation) {
                BootScene.bootedInWrongOrientation = false;
                GameManager.init();
            }

            if (AudioManager.music) {
                if (!GameVars.gameData.musicMuted) {
                    AudioManager.music.mute(false);
                }

                if (!GameVars.gameData.soundMuted) {
                    AudioManager.sound.mute(false);
                }

                if (GameVars.stopOnOrientation) {
                    GameVars.stopOnOrientation = false;
                    GameVars.paused = false;
                }
            }

            document.getElementById("orientation").style.display = "none";
            document.getElementById("content").style.display = "block";

        } else {
            if (AudioManager.music) {
                AudioManager.music.mute(true);
                AudioManager.sound.mute(true);

                if (!GameVars.paused) {
                    GameVars.stopOnOrientation = true;
                    GameVars.paused = true;
                }
            }

            document.getElementById("orientation").style.display = "block";
            document.getElementById("content").style.display = "none";
        }
    }, 50);
}

function resize(): void {

    const canvas = document.querySelector("canvas");
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const windowRatio = windowWidth / windowHeight;

    const gameWidth: any = game.config.width;
    const gameHeight: any = game.config.height;

    const gameRatio = gameWidth / gameHeight;

    if (windowRatio < gameRatio) {
        canvas.style.width = windowWidth + "px";
        canvas.style.height = (windowWidth / gameRatio) + "px";
    } else {
        canvas.style.width = (windowHeight * gameRatio) + "px";
        canvas.style.height = windowHeight + "px";
    }
}
