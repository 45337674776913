import { BattleScene } from "./../BattleScene";
import { GameConstants } from "../../../GameConstants";
import { GameVars } from "../../../GameVars";
import { LifeBar } from "./LifeBar";
import { AudioManager } from "../../../AudioManager";
import * as Creepts from "endless-siege-engine";
import { EnemyType } from "endless-siege-engine/build/main/lib/Types";

export class EnemyActor extends Phaser.GameObjects.Container {

    public type: string;
    public id: number;
    public isActive: boolean;
    public isDead: boolean;
   
    protected sprite: Phaser.GameObjects.Sprite;
    protected lifeBar: LifeBar;
    protected creeptsEnemy: Creepts.Enemy;

    private dx: number;
    private dy: number;
    private frameRate: number;
    private enemyFramerate: number;
    private sparks: Phaser.GameObjects.Sprite;
    private mirrored: boolean;
    
    constructor(scene: Phaser.Scene) {

        super(scene);

        this.sprite = this.scene.add.sprite(0, 0, "texture_atlas_2");
        this.sprite.setOrigin(.5, .65);
        this.add(this.sprite);

        this.lifeBar = new LifeBar(this.scene);
        this.add(this.lifeBar);
    }

    public reset(creeptsEnemy: Creepts.Enemy, position: {r: number, c: number}) {
        this.isActive = true;
        this.isDead = false;
        this.creeptsEnemy = creeptsEnemy;
        this.id = this.creeptsEnemy.id;
        this.type = this.creeptsEnemy.type;
        this.alpha = 1;
        this.sparks = null;

        this.sprite.alpha = 1;
        this.sprite.scaleX = 1;
        this.sprite.scaleY = 1;

        this.enemyFramerate = GameConstants.ENEMY_FRAMERATE;

        if (creeptsEnemy.type === EnemyType.BLOB) {
            this.enemyFramerate *= .5;
        } else if (creeptsEnemy.type === EnemyType.RUNNER) {
            this.enemyFramerate *= 2;
        }

        this.x = GameConstants.CELLS_SIZE * (position.c + .5);
        this.y = GameConstants.CELLS_SIZE * (position.r + .5);

        this.lifeBar.reset(this.creeptsEnemy.health, this.type);

        this.dx = 6 - 12 * Math.random();
        this.dy = -6 * Math.random();

        this.mirrored = Math.random() > .5;

        switch (this.type) {
            case "soldier":
                this.frameRate = GameConstants.ENEMY_FRAMERATE;
                break;
            case "runner":
                this.frameRate = GameConstants.ENEMY_FRAMERATE + 8;
                break;
            case "blob":
                this.frameRate = GameConstants.ENEMY_FRAMERATE - 8;    
                break;
            case "healer":
                this.frameRate = GameConstants.ENEMY_FRAMERATE;
                break;
            case "flier":
                this.frameRate = GameConstants.ENEMY_FRAMERATE + 6;
                break;
            default:
                break;
        }

        this.sprite.anims.play("enemy_" + this.type + "_front");
    }

    public update(time: number, delta: number): void {

        if (this.creeptsEnemy.health === 0 || !this.isActive) {
            return; 
        }

        if (this.creeptsEnemy.teleporting) {
            if (GameVars.timeStepFactor === 8) {
                this.x = this.creeptsEnemy.x * GameConstants.CELLS_SIZE;
                this.y = this.creeptsEnemy.y * GameConstants.CELLS_SIZE;
                this.scaleX = 0;
                this.scaleY = 0;
            } else {
                this.scaleX = .3;
                this.scaleY = .3;
            }
            return;
        }

        let framerate = this.enemyFramerate * GameVars.timeStepFactor;
        let animationSuffix: string;

        if (this.creeptsEnemy.affectedByGlue || this.creeptsEnemy.affectedByGlueBullet) {

            framerate *= .5;
            
            if (this.creeptsEnemy.hasBeenTeleported) {
                animationSuffix = "_frozen_and_teleported";
            } else {
                animationSuffix = "_frozen";
            }
        } else if (this.creeptsEnemy.hasBeenTeleported) {
            animationSuffix = "_teleported";
        } else {
            animationSuffix = "";
        }

        this.sprite.anims.msPerFrame = 1000 / framerate;

        this.scaleX = 1;
        this.scaleY = 1;

        let smoothFactor: number;

        if (GameConstants.INTERPOLATE_TRAJECTORIES) {
            smoothFactor = GameVars.timeStepFactor === 1 ? .15 : .5;
        } else {
            smoothFactor = 1;
        }

        const offX = (this.creeptsEnemy.x * GameConstants.CELLS_SIZE - this.x + this.dx) * smoothFactor;
        const offY = (this.creeptsEnemy.y * GameConstants.CELLS_SIZE - this.y + this.dy) * smoothFactor;

        this.x += offX;
        this.y += offY;

        this.sprite.anims.msPerFrame = 1000 / (this.frameRate * GameVars.timeStepFactor);

        if (offX > .1 && Math.abs(offX) > Math.abs(offY)) {

            if (this.mirrored) {

                if (this.sprite.anims.currentAnim.key !== "enemy_" + this.type + "_left" + animationSuffix) {
                    const startFrame = this.sprite.anims.currentFrame.frame.name.substring(this.sprite.anims.currentFrame.frame.name.length - 2);
                    this.sprite.anims.play("enemy_" + this.type + "_left" + animationSuffix, false, parseInt(startFrame));
                }

                this.sprite.scaleX = -1;

            } else {

                if (this.sprite.anims.currentAnim.key !== "enemy_" + this.type + "_right" + animationSuffix) {
                    const startFrame = this.sprite.anims.currentFrame.frame.name.substring(this.sprite.anims.currentFrame.frame.name.length - 2);
                    this.sprite.anims.play("enemy_" + this.type + "_right" + animationSuffix, false, parseInt(startFrame));
                }
            }

        } else if (offX < -.1 && Math.abs(offX) > Math.abs(offY)) {
            
            if (this.mirrored) {

                if (this.sprite.anims.currentAnim.key !== "enemy_" + this.type + "_right" + animationSuffix) {
                    const startFrame = this.sprite.anims.currentFrame.frame.name.substring(this.sprite.anims.currentFrame.frame.name.length - 2);
                    this.sprite.anims.play("enemy_" + this.type + "_right" + animationSuffix, false, parseInt(startFrame));
                }

                this.sprite.scaleX = -1;

            } else {

                if (this.sprite.anims.currentAnim.key !== "enemy_" + this.type + "_left" + animationSuffix) {
                    const startFrame = this.sprite.anims.currentFrame.frame.name.substring(this.sprite.anims.currentFrame.frame.name.length - 2);
                    this.sprite.anims.play("enemy_" + this.type + "_left" + animationSuffix, false, parseInt(startFrame));
                }
            }

        } else if (offY > .1 && Math.abs(offX) < Math.abs(offY)) {

            if (this.sprite.anims.currentAnim.key !== "enemy_" + this.type + "_front" + animationSuffix) {
                const startFrame = this.sprite.anims.currentFrame.frame.name.substring(this.sprite.anims.currentFrame.frame.name.length - 2);
                this.sprite.anims.play("enemy_" + this.type + "_front" + animationSuffix, false, parseInt(startFrame));
            }

            if (this.mirrored) {
                this.sprite.scaleX = -1;
            }

        } else if (offY < -.1 && Math.abs(offX) < Math.abs(offY)) {

            if (this.sprite.anims.currentAnim.key !== "enemy_" + this.type + "_back" + animationSuffix) {
                const startFrame = this.sprite.anims.currentFrame.frame.name.substring(this.sprite.anims.currentFrame.frame.name.length - 2);
                this.sprite.anims.play("enemy_" + this.type + "_back" + animationSuffix, false, parseInt(startFrame));
            }

            if (this.mirrored) {
                this.sprite.scaleX = -1;
            }
        }

        this.lifeBar.updateValue(this.creeptsEnemy.health);

        if (this.alpha < 1) {
            this.alpha += GameVars.timeStepFactor === 1 ? .025 : .075;
        }
    }

    public hit(impactType: string): void {

        this.lifeBar.visible = true;

        if (this.sparks === null && (impactType === GameConstants.IMPACT_ELECTRIC || impactType === GameConstants.IMPACT_FIRE_ELECTRIC)) {

            this.sparks = this.scene.add.sprite(0, 0, "texture_atlas_1", "electric_damage_00");
            this.sparks.setScale(this.sprite.width / this.sparks.width * .85, this.sprite.height / this.sparks.height * .85);
            this.sparks.angle = Math.random() * 360;
            this.add(this.sparks);

            this.sparks.anims.play("electric_damage");

            this.sparks.on("animationcomplete", () => {
                if (this.sparks) {
                    this.sparks.destroy();
                }
                
                this.sparks = null;
            }, this);
        } 
        
        if (impactType === GameConstants.IMPACT_FIRE || impactType === GameConstants.IMPACT_FIRE_ELECTRIC) {

            if (GameVars.timeStepFactor !== 8) {

                const smoke = new Phaser.GameObjects.Image(this.scene, 0, -25, "texture_atlas_1", "smoke_particle");
                this.add(smoke);

                smoke.setScale(.2);

                let smokeScale = this.type === "blob" ? 1 : .75;

                this.scene.tweens.add({
                    targets: smoke,
                    y: smoke.y - 30,
                    alpha: 0,
                    scaleY: smokeScale,
                    scaleX: smokeScale,
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: GameVars.timeStepFactor === 1 ? 2000 : 500,
                    onComplete: () => {
                        smoke.destroy();
                    },
                    onCompleteScope: this
                });
            }
        }
    }

    public glueHit(): void {
        const fx = this.scene.add.sprite(0, 0, "texture_atlas_1");
        fx.setScale(.3);
        this.add(fx);
        fx.anims.play("snow_shoot");

        fx.on("animationcomplete", () => {
            fx.destroy();
        }, this);
    }

    public teleport(glueTurret: Creepts.GlueTurret): void {
        const glueTurret_px = (glueTurret.position.c + .5) * GameConstants.CELLS_SIZE;
        const glueTurret_py = (glueTurret.position.r + .5) * GameConstants.CELLS_SIZE - 8;

        if (GameVars.timeStepFactor === 8) {
            this.x = glueTurret_px;
            this.y = glueTurret_py;
        } else {
            this.scene.tweens.add({
                targets: this,
                x: glueTurret_px,
                y: glueTurret_py,
                scaleX: .175,
                scaleY: .175,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: GameVars.timeStepFactor === 1 ? 500 : 100
            });
        }
    }

    public die(): void {
        this.isDead = true;
        this.lifeBar.visible = false;

        if (this.sparks) {
            this.sparks.destroy();
            this.sparks = null;
        }

        this.scene.tweens.add({
            targets: this.sprite,
            scaleX: .85,
            scaleY: .5,
            alpha: 0,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 350
        });

        const splat = new Phaser.GameObjects.Sprite(this.scene, 0, 0, "texture_atlas_1");
        splat.angle = Math.random() * 360;
        this.scene.add.existing(splat);

        let s = 1;

        if (this.type === "runner") {
            splat.play("red-splat");
        } else {
            splat.play("splat");
            if (this.type === "blob") {
                s = 1.5;
            }
        }
        this.add(splat);

        splat.on("animationcomplete", function(): void {
            BattleScene.currentInstance.boardContainer.removeEnemy(this.id);
            
        }, this);

        this.scene.tweens.add({
            targets: splat,
            scaleX: s * 1.35,
            scaleY: s * 1.35,
            y: -15,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 350
        });

        BattleScene.currentInstance.addCoinsEffect(this.x, this.y, this.creeptsEnemy.value);
        AudioManager.playSoundEffect("enemy_dies");
    }
}
