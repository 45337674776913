import { GameConstants } from "../GameConstants";
import { GameVars } from "../GameVars";

export class Bug extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.scaleY = GameVars.scaleY;

        const bugContainer = new Phaser.GameObjects.Container(this.scene);
        bugContainer.x = GameConstants.GAME_WIDTH + 30;
        this.add(bugContainer);

        const bug = new Phaser.GameObjects.Sprite(this.scene, 0, 145, "texture_atlas_1");
        bug.angle = -90;
        bug.setScale(1.45);
        this.scene.add.existing(bug);
        bugContainer.add(bug);

        bug.play("bug_walking");

        this.scene.tweens.add({
            targets: bugContainer,
            angle: 120,
            ease: Phaser.Math.Easing.Linear,
            duration: 10000,
            delay: 0, // 2500
            onComplete: function(): void {
                this.destroy();
            },
            onCompleteScope: this
        });
    }
}
