import { TurretMenu } from "./TurretMenu";
import { GameVars } from "../../../GameVars";
import * as Creepts from "endless-siege-engine";
import { TurretType } from "endless-siege-engine/build/main/lib/Types";

export class InfoLayer extends Phaser.GameObjects.Container {
    
    private turretMenu: TurretMenu;
    private damageText: Phaser.GameObjects.BitmapText;
    private damageIncText: Phaser.GameObjects.BitmapText;
    private intensityText: Phaser.GameObjects.BitmapText;
    private intensityIncText: Phaser.GameObjects.BitmapText;
    private distanceText: Phaser.GameObjects.BitmapText;
    private distanceIncText: Phaser.GameObjects.BitmapText;
    private durationText: Phaser.GameObjects.BitmapText;
    private durationIncText: Phaser.GameObjects.BitmapText;
    private reloadText: Phaser.GameObjects.BitmapText;
    private reloadIncText: Phaser.GameObjects.BitmapText;
    private rangeText: Phaser.GameObjects.BitmapText;
    private rangeIncText: Phaser.GameObjects.BitmapText;
    private explosionRangeText: Phaser.GameObjects.BitmapText;
    private explosionRangeIncText: Phaser.GameObjects.BitmapText;
    private inflictedText: Phaser.GameObjects.BitmapText;
    private incContainer: Phaser.GameObjects.Container;

    constructor(scene: Phaser.Scene, turretMenu: TurretMenu) {

        super(scene);

        this.y = -190;

        this.turretMenu = turretMenu;

        this.incContainer = new Phaser.GameObjects.Container(this.scene);

        let offY = 0;
        let bck, img, offInc;
        let text: Phaser.GameObjects.BitmapText;

        if (this.turretMenu.turret.type === TurretType.GLUE) {
            if (this.turretMenu.turret.grade === 3) {
                offInc = 90; 
            } else {
                offInc = 60; 
            }
        } else if (this.turretMenu.turret.type === TurretType.LAUNCH) {
            offInc = 45; 
        } else {
            offInc = 60;
        }

        if (this.turretMenu.turret.type !== TurretType.GLUE) {

            // DAMAGE

            bck = new Phaser.GameObjects.Graphics(this.scene);
            bck.fillStyle(0xAAAAAA, .35);
            bck.fillRoundedRect(-160, offY, 320, 40, 5);
            this.add(bck);

            img = new Phaser.GameObjects.Image(this.scene, -140, offY + 20, "texture_atlas_1", "icon_damage");
            this.add(img);

            text = new Phaser.GameObjects.BitmapText(this.scene, -115, offY + 22, "supercell-white", GameVars.gameText[GameVars.gameData.language].DAMAGE + ":", 16);
            text.setOrigin(0, .5);
            this.add(text);

            this.damageText = new Phaser.GameObjects.BitmapText(this.scene, 75, offY + 22, "supercell-white", GameVars.formatNumber(Number.parseFloat(this.turretMenu.turret.damage.toFixed(2))), 16);
            this.damageText.setOrigin(1, .5);
            this.add(this.damageText);

            this.damageIncText = new Phaser.GameObjects.BitmapText(this.scene, 148, offY + 22, "supercell-green", "+" + GameVars.formatNumber(Number.parseFloat((this.turretMenu.turret.nextDamage - this.turretMenu.turret.damage).toFixed(2))), 16);
            this.damageIncText.setOrigin(1, .5);
            this.incContainer.add(this.damageIncText);

            if (this.turretMenu.turret.nextDamage === 0) {
                this.damageIncText.visible = false;
            }

            offY += offInc;
        } else {

            if (this.turretMenu.turret.grade === 3) {

                // DISTANCE

                bck = new Phaser.GameObjects.Graphics(this.scene);
                bck.fillStyle(0xAAAAAA, .35);
                bck.fillRoundedRect(-160, offY, 320, 40, 5);
                this.add(bck);

                img = new Phaser.GameObjects.Image(this.scene, -140, offY + 20, "texture_atlas_1", "icon_distance_teleport");
                this.add(img);

                text = new Phaser.GameObjects.BitmapText(this.scene,  -120, offY + 22, "supercell-white", GameVars.gameText[GameVars.gameData.language].DISTANCE + ":", 16);
                text.setOrigin(0, .5);
                this.add(text);

                this.distanceText = new Phaser.GameObjects.BitmapText(this.scene, 75, offY + 22, "supercell-white", GameVars.formatNumber((this.turretMenu.turret as Creepts.GlueTurret).teleportDistance), 16);
                this.distanceText.setOrigin(1, .5);
                this.add(this.distanceText);

                this.distanceIncText = new Phaser.GameObjects.BitmapText(this.scene, 148, offY + 22, "supercell-green", "+" + GameVars.formatNumber(Number.parseFloat((GameVars.fixNumber((this.turretMenu.turret as Creepts.GlueTurret).nextTeleportDistance - (this.turretMenu.turret as Creepts.GlueTurret).teleportDistance)).toFixed(2))), 16);
                this.distanceIncText.setOrigin(1, .5);
                this.incContainer.add(this.distanceIncText);

                offY += offInc;

            } else {

                // INTENSITY

                bck = new Phaser.GameObjects.Graphics(this.scene);
                bck.fillStyle(0xAAAAAA, .35);
                bck.fillRoundedRect(-160, offY, 320, 40, 5);
                this.add(bck);

                img = new Phaser.GameObjects.Image(this.scene, -140, offY + 20, "texture_atlas_1", "icon_intensity");
                this.add(img);

                text = new Phaser.GameObjects.BitmapText(this.scene,  -115, offY + 22, "supercell-white", "Intensity:", 16);
                text.setOrigin(0, .5);
                this.add(text);

                this.intensityText = new Phaser.GameObjects.BitmapText(this.scene, 75, offY + 20, "supercell-white", GameVars.formatNumber(GameVars.fixNumber(Number.parseFloat((this.turretMenu.turret as Creepts.GlueTurret).intensity.toFixed(2)))), 16);
                this.intensityText.setOrigin(1, .5);
                this.add(this.intensityText);

                this.intensityIncText = new Phaser.GameObjects.BitmapText(this.scene, 148, offY + 20, "supercell-green", "+" + GameVars.formatNumber(GameVars.fixNumber(Number.parseFloat(((this.turretMenu.turret as Creepts.GlueTurret).nextIntensity - (this.turretMenu.turret as Creepts.GlueTurret).intensity).toFixed(2)))), 16);
                this.intensityIncText.setOrigin(1, .5);
                this.incContainer.add(this.intensityIncText);

                offY += offInc;

                // DURATION
                bck = new Phaser.GameObjects.Graphics(this.scene);
                bck.fillStyle(0xAAAAAA, .35);
                bck.fillRoundedRect(-160, offY, 320, 40, 5);
                this.add(bck);

                img = new Phaser.GameObjects.Image(this.scene, -140, offY + 20, "texture_atlas_1", "icon_duration");
                this.add(img);

                text = new Phaser.GameObjects.BitmapText(this.scene, -115, offY + 22, "supercell-white", "Duration:", 16);
                text.setOrigin(0, .5);
                this.add(text);

                this.durationText = new Phaser.GameObjects.BitmapText(this.scene, 75, offY + 22, "supercell-white", GameVars.formatNumber(Number.parseFloat(((this.turretMenu.turret as Creepts.GlueTurret).duration).toFixed(2))), 16);
                this.durationText.setOrigin(1, .5);
                this.add(this.durationText);

                this.durationIncText = new Phaser.GameObjects.BitmapText(this.scene, 148, offY + 20, "supercell-green", "+" + GameVars.formatNumber(Number.parseFloat((GameVars.fixNumber((this.turretMenu.turret as Creepts.GlueTurret).nextDuration - (this.turretMenu.turret as Creepts.GlueTurret).duration).toFixed(2)))), 16);
                this.durationIncText.setOrigin(1, .5);
                this.incContainer.add(this.durationIncText);

                offY += offInc;
            }
        }

        // RELOAD

        bck = new Phaser.GameObjects.Graphics(this.scene);
        bck.fillStyle(0xAAAAAA, .35);
        bck.fillRoundedRect(-160, offY, 320, 40, 5);
        this.add(bck);

        img = new Phaser.GameObjects.Image(this.scene, -140, offY + 20, "texture_atlas_1", "icon_reload");
        this.add(img);

        text = new Phaser.GameObjects.BitmapText(this.scene,  -115, offY + 22, "supercell-white", "Reload:", 16);
        text.setOrigin(0, .5);
        this.add(text);

        this.reloadText = new Phaser.GameObjects.BitmapText(this.scene, 75, offY + 22, "supercell-white", GameVars.formatNumber(this.turretMenu.turret.reload), 16);
        this.reloadText.setOrigin(1, .5);
        this.add(this.reloadText);

        this.reloadIncText = new Phaser.GameObjects.BitmapText(this.scene, 148, offY + 22, "supercell-green", GameVars.formatNumber(Number.parseFloat((GameVars.fixNumber(this.turretMenu.turret.nextReload - this.turretMenu.turret.reload).toFixed(2)))), 16);
        this.reloadIncText.setOrigin(1, .5);
        this.incContainer.add(this.reloadIncText);

        if (this.turretMenu.turret.nextReload === 0) {
            this.reloadIncText.visible = false;
        }

        // RANGE

        offY += offInc;

        bck = new Phaser.GameObjects.Graphics(this.scene);
        bck.fillStyle(0xAAAAAA, .35);
        bck.fillRoundedRect(-160, offY, 320, 40, 5);
        this.add(bck);

        img = new Phaser.GameObjects.Image(this.scene, -140, offY + 20, "texture_atlas_1", "icon_range");
        this.add(img);

        text = new Phaser.GameObjects.BitmapText(this.scene,  -115, offY + 22, "supercell-white", GameVars.gameText[GameVars.gameData.language].RANGE + ":", 16);
        text.setOrigin(0, .5);
        this.add(text);

        this.rangeText = new Phaser.GameObjects.BitmapText(this.scene, 75, offY + 22, "supercell-white", GameVars.formatNumber(this.turretMenu.turret.range), 16);
        this.rangeText.setOrigin(1, .5);
        this.add(this.rangeText);

        this.rangeIncText = new Phaser.GameObjects.BitmapText(this.scene, 148, offY + 22, "supercell-green", "+" + GameVars.formatNumber(Number.parseFloat((GameVars.fixNumber(this.turretMenu.turret.nextRange - this.turretMenu.turret.range)).toFixed(2))), 16);
        this.rangeIncText.setOrigin(1, .5);
        this.incContainer.add(this.rangeIncText);

        if (this.turretMenu.turret.nextRange === 0) {
            this.rangeIncText.visible = false;
        }

        if (this.turretMenu.turret.type === TurretType.LAUNCH) {

            // EXPLOSION RANGE

            offY += offInc;

            bck = new Phaser.GameObjects.Graphics(this.scene);
            bck.fillStyle(0xAAAAAA, .35);
            bck.fillRoundedRect(-160, offY, 320, 40, 5);
            this.add(bck);

            img = new Phaser.GameObjects.Image(this.scene, -140, offY + 20, "texture_atlas_1", "icon_explossion_range");
            this.add(img);

            text = new Phaser.GameObjects.BitmapText(this.scene,  -115, offY + 22, "supercell-white", "E." + GameVars.gameText[GameVars.gameData.language].RANGE + ":", 16);
            text.setOrigin(0, .5);
            this.add(text);

            this.explosionRangeText = new Phaser.GameObjects.BitmapText(this.scene, 75, offY + 22, "supercell-white", GameVars.formatNumber(Number.parseFloat(((this.turretMenu.turret as Creepts.LaunchTurret).explosionRange).toFixed(2))), 16);
            this.explosionRangeText.setOrigin(1, .5);
            this.add(this.explosionRangeText);

            this.explosionRangeIncText = new Phaser.GameObjects.BitmapText(this.scene, 148, offY + 22, "supercell-green", "+" + GameVars.formatNumber(GameVars.fixNumber(Number.parseFloat(((this.turretMenu.turret as Creepts.LaunchTurret).nextExplosionRange - (this.turretMenu.turret as Creepts.LaunchTurret).explosionRange).toFixed(2)))), 16);
            this.explosionRangeIncText.setOrigin(1, .5);
            this.incContainer.add(this.explosionRangeIncText);

            if ((this.turretMenu.turret as Creepts.LaunchTurret).nextExplosionRange === 0) {
                this.explosionRangeIncText.visible = false;
            }
        }

        if (this.turretMenu.turret.type !== TurretType.GLUE) {

            // INFLICTED

            offY += offInc;

            bck = new Phaser.GameObjects.Graphics(this.scene);
            bck.fillStyle(0xAAAAAA, .35);
            bck.fillRoundedRect(-160, offY, 320, 40, 5);
            this.add(bck);

            img = new Phaser.GameObjects.Image(this.scene, -140, offY + 20, "texture_atlas_1", "icon_inflicted");
            this.add(img);

            text = new Phaser.GameObjects.BitmapText(this.scene,  -115, offY + 22, "supercell-white", GameVars.gameText[GameVars.gameData.language].INFLICTED + ":", 16);
            text.setOrigin(0, .5);
            this.add(text);

            this.inflictedText = new Phaser.GameObjects.BitmapText(this.scene, 148, offY + 22, "supercell-white", GameVars.formatNumber(Number.parseFloat(this.turretMenu.turret.inflicted.toFixed(2))), 16);
            this.inflictedText.setOrigin(1, .5);
            this.add(this.inflictedText);
        }

        this.add(this.incContainer);

        this.scaleY = .9;

        this.scene.tweens.add({
            targets: this,
            scaleY: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 250
        });

        this.scene.sys.updateList.add(this);
    }

    public clearTurretMenu(): void {
        this.turretMenu = null;
    }

    public preUpdate(time: number, delta: number): void {
        if (this.turretMenu == null) {
            return;
        }

        if (this.turretMenu.turret.type !== TurretType.GLUE) {
            // DAMAGE

            this.damageText.setText(GameVars.formatNumber(Number.parseFloat(this.turretMenu.turret.damage.toFixed(2)))); 
            this.damageIncText.setText("+" + GameVars.formatNumber(Number.parseFloat((this.turretMenu.turret.nextDamage - this.turretMenu.turret.damage).toFixed(2)))); 

            this.inflictedText.setText(GameVars.formatNumber(Number.parseFloat(this.turretMenu.turret.inflicted.toFixed(2)))); 


        } else {

            if (this.turretMenu.turret.grade === 3) {

                if (this.distanceText) {
                    this.distanceText.setText(GameVars.formatNumber((this.turretMenu.turret as Creepts.GlueTurret).teleportDistance)); 
                    this.distanceIncText.setText("+" + GameVars.formatNumber(GameVars.fixNumber((this.turretMenu.turret as Creepts.GlueTurret).nextTeleportDistance - (this.turretMenu.turret as Creepts.GlueTurret).teleportDistance))); 
                }
                
            } else {
                
                this.intensityText.setText(GameVars.formatNumber(Number.parseFloat(((this.turretMenu.turret as Creepts.GlueTurret).intensity).toFixed(2)))); 
                this.intensityIncText.setText("+" + GameVars.formatNumber(GameVars.fixNumber(Number.parseFloat(((this.turretMenu.turret as Creepts.GlueTurret).nextIntensity - (this.turretMenu.turret as Creepts.GlueTurret).intensity).toFixed(2))))); 

                this.durationText.setText(GameVars.formatNumber((this.turretMenu.turret as Creepts.GlueTurret).duration)); 
                this.durationIncText.setText("+" + GameVars.formatNumber(GameVars.fixNumber(Number.parseFloat(((this.turretMenu.turret as Creepts.GlueTurret).nextDuration - (this.turretMenu.turret as Creepts.GlueTurret).duration).toFixed(2))))); 
            }
        }

        // RELOAD

        this.reloadText.setText(GameVars.formatNumber(this.turretMenu.turret.reload)); 
        this.reloadIncText.setText(GameVars.formatNumber(GameVars.fixNumber(Number.parseFloat((this.turretMenu.turret.nextReload - this.turretMenu.turret.reload).toFixed(2))))); 

        // RANGE

        this.rangeText.setText(GameVars.formatNumber(this.turretMenu.turret.range)); 
        this.rangeIncText.setText("+" + GameVars.formatNumber(Number.parseFloat((GameVars.fixNumber(this.turretMenu.turret.nextRange - this.turretMenu.turret.range).toFixed(2))))); 

        if (this.turretMenu.turret.type === TurretType.LAUNCH) {

            // EXPLOSION RANGE

            this.explosionRangeText.setText(GameVars.formatNumber(Number.parseFloat(((this.turretMenu.turret as Creepts.LaunchTurret).explosionRange).toFixed(2)))); 
            this.explosionRangeIncText.setText("+" + GameVars.formatNumber(Number.parseFloat((GameVars.fixNumber((this.turretMenu.turret as Creepts.LaunchTurret).nextExplosionRange - (this.turretMenu.turret as Creepts.LaunchTurret).explosionRange).toFixed(2))))); 

        }

        if (this.turretMenu.turret.level === this.turretMenu.turret.maxLevel) {
            this.incContainer.visible = false;
        } else {
            this.incContainer.visible = true;
        }
    }
}
