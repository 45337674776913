import { InfoLayer } from "./InfoLayer";
import { ButtonTarget } from "./ButtonTarget";
import { ButtonStrategy } from "./ButtonStrategy";
import { ButtonSell } from "./ButtonSell";
import { ButtonLevelUp } from "./ButtonLevelUp";
import { ButtonUpgrade } from "./ButtonUpgrade";
import { BattleManager } from "../BattleManager";
import { GameVars } from "../../../GameVars";
import * as Creepts from "endless-siege-engine";
import { AudioManager } from "../../../AudioManager";
import { GameConstants } from "../../../GameConstants";
import { TurretType } from "endless-siege-engine/build/main/lib/Types";
import { BattleScene } from "../BattleScene";

export class TurretMenu extends Phaser.GameObjects.Container {

    public turret: Creepts.Turret;
    public buttonLevelUp: ButtonLevelUp;
    public buttonUpgrade: ButtonUpgrade;

    private topContainer: Phaser.GameObjects.Container;
    private bottomContainer: Phaser.GameObjects.Container;
    private infoLayer: InfoLayer;
    private bckBottom: Phaser.GameObjects.Image;
    private bckMid: Phaser.GameObjects.Image;
    private bckTop: Phaser.GameObjects.Image;
    private title: Phaser.GameObjects.BitmapText;
    private titleGreen: Phaser.GameObjects.BitmapText;
    private turrets: Phaser.GameObjects.Container[];
    private images: Phaser.GameObjects.Image[];
    private infoBtn: Phaser.GameObjects.Container;
    private infoText: Phaser.GameObjects.Text;
    private buttonSell: ButtonSell;
    private buttonStrategy: ButtonStrategy;
    private buttonTarget: ButtonTarget;
    private showingInfo: boolean;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.showingInfo = false;

        this.bckMid = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "layout_box_3");
        this.bckMid.scaleY = 7.3;
        this.bckMid.alpha = .8;
        this.add(this.bckMid);

        this.bckTop = new Phaser.GameObjects.Image(this.scene, 0, -(this.bckMid.height / 2) * this.bckMid.scaleY, "texture_atlas_1", "layout_box_2");
        this.bckTop.setOrigin(.5, 1);
        this.bckTop.alpha = .8;
        this.add(this.bckTop);

        this.bckBottom = new Phaser.GameObjects.Image(this.scene, 0, (this.bckMid.height / 2) * this.bckMid.scaleY, "texture_atlas_1", "layout_box_4");
        this.bckBottom.setOrigin(.5, 0);
        this.bckBottom.alpha = .8;
        this.add(this.bckBottom);

        // TOP
        this.topContainer = new Phaser.GameObjects.Container(this.scene);
        this.topContainer.y = -200;
        this.add(this.topContainer);

        this.title = new Phaser.GameObjects.BitmapText(this.scene, 0, 0, "supercell-white", GameVars.turretsData[TurretType.PROJECTILE].name + " -" + GameVars.gameText[GameVars.gameData.language].LEVEL + 1 + "/" + 10, 20);
        this.title.setOrigin(.5);
        this.topContainer.add(this.title);

        this.titleGreen = new Phaser.GameObjects.BitmapText(this.scene, this.title.x + this.title.width / 2, 0, "supercell-green", GameVars.gameText[GameVars.gameData.language].LEVEL + 1 + "/" + 10, 20);
        this.titleGreen.setOrigin(1, .5);
        this.topContainer.add(this.titleGreen);

        // TURRETS
        this.turrets = [];
        this.images = [];
        let type = "archer";
        for (let i = 0; i < 3; i++) {

            const turret = new Phaser.GameObjects.Container(this.scene);
            turret.setPosition(-135 + 85 * i, 70);
            turret.setScale(.8);
            this.topContainer.add(turret);

            this.turrets.push(turret);

            const base = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", type + "_" + (i + 1) + "_base");
            turret.add(base);
            this.images.push(base);

            let top: Phaser.GameObjects.Image;

            top = new Phaser.GameObjects.Image(this.scene, 0, -10, "texture_atlas_1", type + "_" + (i + 1) + "_top");
            top.angle = -90;
            turret.add(top);
            this.images.push(top);
        }

        this.turrets[2].x += 10;

        let iconUpgrade = new Phaser.GameObjects.Image(this.scene, -92, 70, "texture_atlas_1", "icon_upgrade1");
        this.topContainer.add(iconUpgrade);

        iconUpgrade = new Phaser.GameObjects.Image(this.scene, -5, 70, "texture_atlas_1", "icon_upgrade2");
        this.topContainer.add(iconUpgrade);

        // INFO BUTTON

        this.infoBtn = new Phaser.GameObjects.Container(this.scene);
        this.infoBtn.setPosition(125, 75);
        this.topContainer.add(this.infoBtn);

        const infoImg = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_base");
        infoImg.setInteractive({ useHandCursor: true });
        infoImg.on("pointerdown", this.onDownShowInfo, this);
        infoImg.on("pointerover", () => { this.infoBtn.setScale(1.05); }, this);
        infoImg.on("pointerout", () => { this.infoBtn.setScale(1); }, this);
        this.infoBtn.add(infoImg);

        this.infoText = new Phaser.GameObjects.Text(this.scene, 0, 0, GameVars.gameText[GameVars.gameData.language].SHOW_TURRET_INFO, {fontFamily: "Supercell", fontSize: "14px", color: "#FFFFFF", align: "center"});
        this.infoText.setOrigin(.5);
        this.infoText.setStroke("#000000", 4);
        this.infoText.setShadow(3, 3, "#000000");
        this.infoBtn.add(this.infoText);

        // BOTTOM

        this.bottomContainer = new Phaser.GameObjects.Container(this.scene);
        this.bottomContainer.y = -20;
        this.add(this.bottomContainer);

        // BUTTONS MID

        this.buttonLevelUp = new ButtonLevelUp(this.scene, -110, 3);
        this.bottomContainer.add(this.buttonLevelUp);

        this.buttonUpgrade = new ButtonUpgrade(this.scene, 0, 3);
        this.bottomContainer.add(this.buttonUpgrade);

        this.buttonSell = new ButtonSell(this.scene, 110, 3);
        this.bottomContainer.add(this.buttonSell);

        // BUTTONS BOTTOM
        const bck = new Phaser.GameObjects.Graphics(this.scene);
        bck.setPosition(0, 150);
        bck.fillStyle(0xAAAAAA, .35);
        bck.fillRoundedRect(-165, -75, 330, 150, 10);
        this.bottomContainer.add(bck);

        this.buttonStrategy = new ButtonStrategy(this.scene, 0, 115);
        this.bottomContainer.add(this.buttonStrategy);

        this.buttonTarget = new ButtonTarget(this.scene, 0, 185);
        this.bottomContainer.add(this.buttonTarget);

        this.setScale(.875);
        this.setAlpha(.5);

        if (!GameVars.gameData.tutorialSeen) {

            this.buttonUpgrade.visible = false;
            this.buttonSell.visible = false;
            this.infoBtn.visible = false;
            infoImg.visible = false;
            this.infoText.visible = false;
            this.buttonStrategy.visible = false;
            this.buttonTarget.visible = false;
        }

        this.scene.sys.updateList.add(this);
    }

    public preUpdate(time: number, delta: number): void {
        if (this.turret == null) {
            return;
        }

        if (BattleManager.engine.credits < this.turret.priceImprovement) {
            this.buttonLevelUp.setInactiveButton();
        } else if (this.turret.level !== this.turret.maxLevel) {
            this.buttonLevelUp.setActiveButton();
        }
        
        if (BattleManager.engine.credits < this.turret.priceUpgrade - this.turret.sellValue) {
            this.buttonUpgrade.setInactiveButton();
        } else if (this.turret.grade !== 3) {
            this.buttonUpgrade.setActiveButton();
        }
    }

    public onDownShowInfo(): void {

        if (this.showingInfo) {
            this.infoText.setText(GameVars.gameText[GameVars.gameData.language].SHOW_TURRET_INFO);
            this.hideInfo();
        } else {
            this.infoText.setText(GameVars.gameText[GameVars.gameData.language].HIDE_TURRET_INFO);
            this.showInfo();
        }

        this.showingInfo = !this.showingInfo;

        AudioManager.playSoundEffect("btn_click");
    }

    public setTurretMenu(turret: Creepts.Turret): void {
        this.turret = turret;

        // update images
        let type: string;
        switch (this.turret.type) {
            case TurretType.PROJECTILE:
                type = "archer";
                break;
            case TurretType.LAUNCH:
                type = "cannon";
                break;
            case TurretType.LASER:
                type = "fire";
                break;
            case TurretType.GLUE:
                type = "crystal";
                break;
            default:
                break;
        }

        let j = 0;
        let turretName: string;
        
        switch (this.turret.type) {
            case TurretType.PROJECTILE: 
                if (GameVars.ballistaUpgraded) {
                    turretName = "archer_upgraded_";
                } else {
                    turretName = "archer_";
                }
                break;
            case TurretType.GLUE: 
                if (GameVars.timeWarpUpgraded) {
                    turretName = "crystal_upgraded_";
                } else {
                    turretName = "crystal_";
                }
                break;
            case TurretType.LAUNCH: 
                if (GameVars.cannonUpgraded) {
                    turretName = "cannon_upgraded_";
                } else {
                    turretName = "cannon_";
                }
                break;
            case TurretType.LASER:
                if (GameVars.torchUpgraded) {
                    turretName = "fire_upgraded_";
                } else {
                    turretName = "fire_";
                } 
                break;
            default:
                break;
        }

        for (let i = 0; i < 3; i++) {
            this.images[j].setFrame(turretName + (i + 1) + "_base");
            this.images[j + 1].visible = true;
            if (this.turret.type === TurretType.PROJECTILE) {
                this.images[j + 1].setFrame(type + "_" + (i + 1) + "_top", true, true);
                this.images[j + 1].y = -10;
                this.images[j + 1].angle = -90;
            } else if (this.turret.type === TurretType.LAUNCH) {
                if (i !== 1) {
                    this.images[j + 1].setFrame(type + "_" + (i + 1) + "_top_19", true, true);
                    this.images[j + 1].y = -10;
                    this.images[j + 1].angle = 9;
                } else {
                    this.images[j + 1].visible = false;
    
                }
            } else if (this.turret.type === TurretType.GLUE) {
                this.images[j + 1].setFrame(type + "_" + (i + 1) + "_top_01", true, true);
                this.images[j + 1].y = 0;
                this.images[j + 1].angle = 0;
            } else {
                this.images[j + 1].visible = false;
            }
            j += 2;
        }

        // set buttons
        this.buttonLevelUp.setTurretMenu(this);
        this.buttonSell.setTurretMenu(this);
        this.buttonStrategy.setTurretMenu(this);
        this.buttonTarget.setTurretMenu(this);
        this.buttonUpgrade.setTurretMenu(this);

        // update buttons data
        this.checkAndUpdateInfo();

        if (this.showingInfo) {
            this.showInfo();
        }

        this.setScale(.875);
        this.setAlpha(.5);

        this.setPositionInBoard();
        
        this.scene.tweens.add({
            targets: this,
            scaleX: 1,
            scaleY: 1,
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 300
        });
    }

    public clearTurretMenu(): void {
        if (this.infoLayer != null) {
            this.hideInfo();
        }
        this.buttonLevelUp.clearTurretMenu();
        this.buttonSell.clearTurretMenu();
        this.buttonStrategy.clearTurretMenu();
        this.buttonTarget.clearTurretMenu();
        this.buttonUpgrade.clearTurretMenu();

        this.turret = null;
    }

    public updateMenu(): void {

        const priceStr = GameVars.formatNumber(this.turret.sellValue);
        this.buttonSell.priceLabel.setText(priceStr);

        if (priceStr.length >= 6) {
            this.buttonSell.priceLabel.setScale(.9);
        }

        this.buttonUpgrade.priceLabel.setText(GameVars.formatNumber(this.turret.priceUpgrade - this.turret.sellValue));

        if ( this.buttonUpgrade.priceLabel.text.length >= 6) {
            this.buttonUpgrade.priceLabel.setScale(.9);
        }
    }

    public showInfo(): void {
        if (this.turret == null) {
            return;
        }

        this.bckMid.scaleY = 11.8;
        this.bckTop.y = -(this.bckMid.height / 2) * this.bckMid.scaleY;
        this.bckBottom.y = (this.bckMid.height / 2) * this.bckMid.scaleY;

        this.topContainer.y = -317;
        this.bottomContainer.y = 95;

        this.infoLayer = new InfoLayer(this.scene, this);
        this.add(this.infoLayer);
    }

    public hideInfo(): void {
        if (this.turret == null) {
            return;
        }

        this.bckMid.scaleY = 7.3;
        this.bckTop.y = -(this.bckMid.height / 2) * this.bckMid.scaleY;
        this.bckBottom.y = (this.bckMid.height / 2) * this.bckMid.scaleY;

        this.topContainer.y = -200;
        this.bottomContainer.y = -20;

        this.infoLayer.clearTurretMenu();
        this.remove(this.infoLayer);
        this.infoLayer = null;
    }

    public checkAndUpdateInfo(): void {
        if (this.turret == null) {
            return;
        }

        this.title.setText(GameVars.turretsData[this.turret.type].name + " -" + GameVars.gameText[GameVars.gameData.language].LEVEL + this.turret.level + "/" + this.turret.maxLevel);
        this.titleGreen.setText(GameVars.gameText[GameVars.gameData.language].LEVEL + this.turret.level + "/" + this.turret.maxLevel);
        this.titleGreen.x = this.title.x + this.title.width / 2;

        if ((this.turret.type === TurretType.GLUE && this.turret.grade === 1) || (this.turret.type === TurretType.LAUNCH && this.turret.grade === 2)) {
            this.buttonStrategy.setInactiveButton();
            this.buttonTarget.setInactiveButton();
        } else {
            this.buttonStrategy.setActiveButton();
            this.buttonTarget.setActiveButton();
        }

        for (let i = 0; i < this.turrets.length; i++) {

            if (this.turret.grade === (i + 1)) {
                this.turrets[i].alpha = 1;
            } else {
                this.turrets[i].alpha = .5;
            }
        }

        const priceStr = GameVars.formatNumber(this.turret.sellValue);
        this.buttonSell.priceLabel.setText(priceStr);

        if (priceStr.length >= 6) {
            this.buttonSell.priceLabel.setScale(.9);
        }

        this.buttonLevelUp.priceLabel.setText(GameVars.formatNumber(this.turret.priceImprovement));
        this.buttonUpgrade.priceLabel.setText(GameVars.formatNumber(this.turret.priceUpgrade - this.turret.sellValue));

        if ( this.buttonUpgrade.priceLabel.text.length >= 6) {
            this.buttonUpgrade.priceLabel.setScale(.9);
        }

        if (this.turret.level === this.turret.maxLevel) {
            this.buttonLevelUp.setInactiveButton();
        } else {
            this.buttonLevelUp.setActiveButton();
        }

        if (this.turret.grade === 3) {
            this.buttonUpgrade.setInactiveButton();
        } else {
            this.buttonUpgrade.setActiveButton();
        }
    }

    public resetInfo(): void {
        if (this.turret == null) {
            return;
        }

        if (this.infoLayer) {
            this.infoLayer.destroy();
            this.infoLayer = new InfoLayer(this.scene, this);
            this.add(this.infoLayer);
        }
    }

    private setPositionInBoard(): void {
        if (this.turret == null) {
            return;
        }
        this.x = 0;
        this.y = 0;

        const c = Math.floor(this.turret.x);
        const r = Math.floor(this.turret.y);

        if (c >= 3 && c <= 8 && r >= 1 && r <= 12)  {
            if (c <= 5) {
                this.x = 3 * GameConstants.CELLS_SIZE;
            } else {
                this.x = -3 * GameConstants.CELLS_SIZE;
            }
        }
    }
}
