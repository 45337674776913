export class GameConstants {

    public static readonly VERSION = "0.0";
    public static readonly DEVELOPMENT = false;
    public static readonly SHOW_DEBUG_GEOMETRY = false;
    public static readonly INTERPOLATE_TRAJECTORIES = true;
    public static readonly VERBOSE = false;
    public static readonly GAME_WIDTH = 768;
    public static readonly GAME_HEIGHT = 1024;
    public static readonly OFFLINE = false;
    public static readonly DOWNLOAD_FILES = false;

    public static readonly TIME_STEP = 100;
    public static readonly ENEMY_SPAWNING_DELTA_TICKS = 10;
    public static readonly INITIAL_CREDITS = 500;
    public static readonly INITIAL_LIFES = 20;
    public static readonly CELLS_SIZE = 60;

    public static readonly ENEMY_FRAMERATE = 18;

    public static readonly LANGUAGE_ENGLISH = "en";

    // TIPO DE IMPACTOS
    public static readonly IMPACT_ARROW = "impact arrow";
    public static readonly IMPACT_MORTAR = "impact mortar";
    public static readonly IMPACT_MINE = "impact mine";
    public static readonly IMPACT_FIRE = "impact fire";
    public static readonly IMPACT_ELECTRIC = "impact electric";
    public static readonly IMPACT_FIRE_ELECTRIC = "impact fire electric";

    public static readonly ADD_INTRO = 1;
    public static readonly ADD_PLAY = 2;
    public static readonly ADD_RESET = 3;
    public static readonly ADD_RESUME = 4;

    public static readonly SAVED_GAME_DATA_KEY = "endless_siege-data-key";
}
