import { getAccessToken } from "./accessToken";

export const getAuthHeader = () => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return null;
  }

  return { Authorization: "Bearer " + accessToken };
};
