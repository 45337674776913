import { ResultsLayer } from "./ResultsLayer";
import { SplashMapPreview } from "./SplashMapPreview";
import { GameConstants } from "./../../GameConstants";
import { GameVars } from "../../GameVars";
import { GameManager } from "../../GameManager";
import { AudioManager } from "../../AudioManager";
import { GameTitle } from "../GameTitle";

export class SplashScene extends Phaser.Scene {

    public static firstInstantiation = true;
    public static currentInstance: SplashScene;

    private f: number;
    private character: Phaser.GameObjects.Image;
    private gameTitle: GameTitle;
    private midContainer: Phaser.GameObjects.Container;
    private copyrigthLabel: Phaser.GameObjects.Text;
    private fullscreenButton: Phaser.GameObjects.Image;
    private isFullscreen: boolean;

    constructor() {

        super("SplashScene");

        SplashScene.currentInstance = this;
    }

    public create(): void {

        GameVars.currentScene = this;

        this.scene.setVisible(true);

        this.f = 0;

        const backgroundContainer = this.add.container(0, 0);
        backgroundContainer.scaleY = GameVars.scaleY;

        let n = Math.round(GameConstants.GAME_HEIGHT / 128 / GameVars.scaleY) + 1;

        let splashBg = "splash_bg";
        if (GameVars.isHalloween) {
            splashBg = "halloween_splash_bg";
        } else if (GameVars.isXmas) {
            splashBg = "splash_bg";
        }

        for (let i = 0; i < n; i++) {
            const backgroundSlice = new Phaser.GameObjects.Image(this, 0, i * 128, "texture_atlas_0", splashBg);
            backgroundSlice.setOrigin(0, 0);
            backgroundContainer.add(backgroundSlice);
        }

        let gradientColor = "blue_gradient";
        if (GameVars.isHalloween) {
            gradientColor = "purple_gradient";
        } else if (GameVars.isXmas) {
            gradientColor = "blue_gradient";
        }

        const gradient = this.add.image(0, 0, "texture_atlas_0", gradientColor);
        gradient.setOrigin(0);
        gradient.scaleX = GameConstants.GAME_WIDTH / 10;
        gradient.scaleY = 1.35 / GameVars.scaleY;

        const titleBanner = new Phaser.GameObjects.Image(this, 0, 0, "texture_atlas_1", "red_banner");
        titleBanner.x = GameConstants.GAME_WIDTH / 2 + 5;
        titleBanner.y = 160 * GameVars.scaleY;
        this.add.existing(titleBanner);

        this.gameTitle = new GameTitle(this);
        this.gameTitle.x = GameConstants.GAME_WIDTH / 2 + 14;
        this.gameTitle.y = 160 * GameVars.scaleY;
        this.add.existing(this.gameTitle);

        this.gameTitle.activate();

        // mid container
        this.midContainer = new Phaser.GameObjects.Container(this, GameConstants.GAME_WIDTH / 2, GameConstants.GAME_HEIGHT * .515);
        this.midContainer.scaleY = GameVars.scaleY;
        this.midContainer.alpha = SplashScene.firstInstantiation ? 0 : 1;
        this.add.existing(this.midContainer);

        let characterTextureName: string;

        const rnd = Math.random();

        if (rnd < .2) {
            characterTextureName = "pose_blob";
        } else if (rnd < .4) {
            characterTextureName = "pose_flier";
        } else if (rnd < .6) {
            characterTextureName = "pose_healer";
        } else if (rnd < .8) {
            characterTextureName = "pose_normal";
        } else {
            characterTextureName = "pose_runner";
        }

        this.character = new Phaser.GameObjects.Image(this, -145, 90, "texture_atlas_1", characterTextureName);
        this.character.setOrigin(1, .5);

        this.midContainer.add(this.character);

        if (SplashScene.firstInstantiation) {

            this.character.x = -100;
            this.character.alpha = 0;
            this.character.setScale(.85);

            this.tweens.add({
                targets: this.character,
                x: -145,
                alpha: 1,
                scaleX: 1,
                scaleY: 1,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 300,
                delay: 2500
            });
        }

        const splashMapPreview = new SplashMapPreview(this);
        this.midContainer.add(splashMapPreview);

        let bannerName = "banner_red";
        if (GameVars.isHalloween) {
            bannerName = "title_area";
        }
        else if (GameVars.isXmas) {
            bannerName = "banner_red";
        }
        const banner = new Phaser.GameObjects.Image(this, 0, -100, "texture_atlas_1", bannerName);
        banner.setScale(.85),
            this.midContainer.add(banner);

        const title = new Phaser.GameObjects.Text(this, 0, -108, GameVars.gameText[GameVars.gameData.language].MAP_OF_THE_DAY, { fontFamily: "Supercell", fontSize: "24px", color: "#FFFFFF" });
        title.setOrigin(.5);
        title.setStroke("#000000", 6);
        title.setShadow(4, 4, "#000000");
        this.midContainer.add(title);

        const weekDayLabel = new Phaser.GameObjects.Text(this, 0, -60, GameVars.getDate(), { fontFamily: "Supercell", fontSize: "16px", color: "#FFFFFF" });
        weekDayLabel.setOrigin(.5);
        weekDayLabel.setStroke("#000000", 4);
        weekDayLabel.setShadow(3, 3, "#000000");
        this.midContainer.add(weekDayLabel);

        const resultsLayer = new ResultsLayer(this);
        resultsLayer.setPosition(0, 300);
        this.midContainer.add(resultsLayer);

        // restart button
        const playButtonContainer = new Phaser.GameObjects.Container(this, 0, 235);
        this.midContainer.add(playButtonContainer);

        this.tweens.add({
            targets: playButtonContainer,
            scaleX: 1.035,
            scaleY: 1.035,
            ease: Phaser.Math.Easing.Cubic.InOut,
            duration: 350,
            yoyo: true,
            repeat: -1
        });

        const playButton = new Phaser.GameObjects.Image(this, 0, 0, "texture_atlas_1", "btn_play_void");
        playButton.setInteractive({ useHandCursor: true });
        playButton.on("pointerover", () => {
            playButtonContainer.setScale(1.025);
        }, this);
        playButton.on("pointerout", () => {
            playButtonContainer.setScale(1);
        }, this);

        playButton.on("pointerup", () => {
            this.onPlayDown();
        }, this);
        playButtonContainer.add(playButton);

        const playText = new Phaser.GameObjects.Text(this, 0, 0, GameVars.gameText[GameVars.gameData.language].PLAY_MAYUS, { fontFamily: "Supercell", fontSize: "28px", color: "#FFFFFF" });
        playText.setOrigin(.5);
        playText.setStroke("#663333", 8);
        playText.setShadow(5, 5, "#663333");
        playButtonContainer.add(playText);

        this.copyrigthLabel = this.add.text(GameConstants.GAME_WIDTH / 2, GameConstants.GAME_HEIGHT - 15 * GameVars.scaleY, "8XR Game", { fontFamily: "Arial", fontSize: "16px", color: "#FFFFFF" });
        this.copyrigthLabel.setOrigin(.5);
        this.copyrigthLabel.alpha = .65;
        this.copyrigthLabel.scaleY = GameVars.scaleY;
        this.copyrigthLabel.visible = false;

        if (GameVars.currentScene.game.device.os.desktop) {
            this.fullscreenButton = new Phaser.GameObjects.Image(this, 350, 470, "texture_atlas_1", "icon_expand_screen");
            this.fullscreenButton.setInteractive({ useHandCursor: true });
            this.fullscreenButton.on("pointerover", () => {
                this.fullscreenButton.setScale(1.025);
            });
            this.fullscreenButton.on("pointerout", () => {
                this.fullscreenButton.scale = 1;
            });
            this.fullscreenButton.on("pointerdown", () => {
                this.onFullscreenButtonDown();
            });
            this.midContainer.add(this.fullscreenButton);
            this.isFullscreen = false;
            this.fullscreenButton.setVisible(false);
        }

        SplashScene.firstInstantiation = false;
    }

    public update(): void {

        this.f++;

        if (this.f === 550) {

            this.f = 0;

            this.tweens.add({
                targets: this.character,
                y: this.character.y - 6,
                ease: Phaser.Math.Easing.Cubic.InOut,
                duration: 80,
                yoyo: true,
                repeat: 1
            });
        }

        this.gameTitle.update();

        if (GameVars.currentScene.game.device.os.desktop && this.isFullscreen !== GameVars.currentScene.scale.isFullscreen) {
            this.isFullscreen = !this.isFullscreen;
            if (!this.isFullscreen) {
                GameManager.resize();
                this.fullscreenButton.setFrame("icon_expand_screen", false, true);
            } else {
                this.fullscreenButton.setFrame("icon_shrink_screen", false, true);
            }
        }
    }

    public showSplashElelements(): void {

        if (this.midContainer.alpha === 1) {
            return;
        }

        this.midContainer.setScale(.65);

        this.tweens.add({
            targets: this.midContainer,
            alpha: 1,
            scaleX: 1,
            scaleY: 1 * GameVars.scaleY,
            ease: Phaser.Math.Easing.Elastic.Out,
            duration: 1000,
            onComplete: function (): void {
                this.copyrigthLabel.visible = true;
            },
            onCompleteScope: this
        });

        AudioManager.playSoundEffect("title_appears");
    }

    private onPlayDown(): void {

        AudioManager.playSoundEffect("btn_click");

        GameManager.enterBattleScene();
    }

    private onFullscreenButtonDown(): void {

        if (this.isFullscreen) {
            GameVars.currentScene.scale.stopFullscreen();
            this.isFullscreen = false;
            this.fullscreenButton.setFrame("icon_expand_screen", false, true);
            GameManager.resize();
        } else {
            GameVars.currentScene.scale.startFullscreen();
            this.isFullscreen = true;
            this.fullscreenButton.setFrame("icon_shrink_screen", false, true);
        }
    }
}
