import { LifesContainer } from "./LifesContainer";
import { BattleManager } from "../BattleManager";
import { GameConstants } from "../../../GameConstants";
import { GameVars } from "../../../GameVars";
import { CreditsContainer } from "./CreditsContainer";
import { Score } from "./Score";
import { AudioManager } from "../../../AudioManager";

export class HUD extends Phaser.GameObjects.Container {

    public static currentInstance: HUD;

    private roundLabel: Phaser.GameObjects.Text;
    private score: Score;
    private lifesContainer: LifesContainer;
    private creditsContainer: CreditsContainer;
    private fpsLabel: Phaser.GameObjects.Text;
    private topContainer: Phaser.GameObjects.Container;
    private bottomContainer: Phaser.GameObjects.Container;

    constructor(scene: Phaser.Scene) {

        super(scene);

        HUD.currentInstance = this;

        this.topContainer = new Phaser.GameObjects.Container(this.scene);
        this.topContainer.scaleY = GameVars.scaleY;
        this.add(this.topContainer);

        this.bottomContainer = new Phaser.GameObjects.Container(this.scene);
        this.bottomContainer.y = GameConstants.GAME_HEIGHT - 20;
        this.bottomContainer.scaleY = GameVars.scaleY;
        this.add(this.bottomContainer);

        this.score = new Score(this.scene);
        this.topContainer.add(this.score);

        this.creditsContainer = new CreditsContainer(this.scene);
        this.topContainer.add(this.creditsContainer);

        this.lifesContainer = new LifesContainer(this.scene);
        this.topContainer.add(this.lifesContainer);

        if (GameConstants.DEVELOPMENT) {

            this.fpsLabel = new Phaser.GameObjects.Text(this.scene, 5, (GameConstants.GAME_HEIGHT - 30) / GameVars.scaleY, "", { fontFamily: "Arial", fontSize: "22px", color: "#FF0000"});
            this.add(this.fpsLabel);

        } else {

            this.fpsLabel = null;
        }

        const bckGraphic = new Phaser.GameObjects.Graphics(this.scene);
        bckGraphic.fillStyle(0x000000, .75);
        bckGraphic.fillRect(0, -100, GameConstants.GAME_WIDTH, 30);
        this.bottomContainer.add(bckGraphic);

        const bck = new Phaser.GameObjects.Image(this.scene, -40, 65, "texture_atlas_1", "btn_wave");
        bck.setOrigin(0, 1);
        bck.setScale(10, 2);
        this.bottomContainer.add(bck);

        this.roundLabel = new Phaser.GameObjects.Text(this.scene, GameConstants.GAME_WIDTH - 190, - 85, GameVars.gameText[GameVars.gameData.language].ROUND + GameVars.formatNumber(BattleManager.engine.round), {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff"});
        this.roundLabel.setOrigin(0, .5);
        this.roundLabel.setStroke("#000000", 8);
        this.roundLabel.setShadow(4, 4, "#000000");
        this.bottomContainer.add(this.roundLabel);
    }

    public update(time: number, delta: number): void {

        this.creditsContainer.text.setText(GameVars.formatNumber(BattleManager.engine.credits));
        this.score.scoreLabel.setText(GameVars.formatNumber(BattleManager.engine.score));
    
        if (this.fpsLabel) {
            this.fpsLabel.text = Math.round(this.scene.game.loop.actualFps).toString();
        }
    }

    public updateLifes(): void {
        let currentLifes = parseInt(this.lifesContainer.lifesLabel.text);
        this.lifesContainer.lifesLabel.setText(BattleManager.engine.lifes < 0 ? "0" : BattleManager.engine.lifes.toString());

        if (!GameVars.gameOver && BattleManager.engine.lifes < currentLifes) {

            if (BattleManager.engine.lifes > 0) {
                AudioManager.playSoundEffect("heart_lost_" + (Math.floor(3 * Math.random()) + 1).toString(), false, 1, GameVars.dangerRate);
            } else {
                AudioManager.playSoundEffect("heart_final_lost");
            } 
        }
    }

    public updateRound(): void {

        this.roundLabel.setText(GameVars.gameText[GameVars.gameData.language].ROUND + BattleManager.engine.round);
    }

    public onCoinsReachedEndPosition(): void {

       this.creditsContainer.onCoinsReachedEndPosition();
    }

    public onEnemyReachedExit(): void {
        
        this.lifesContainer.onEnemyReachedExit();
    }
}
