
import { GameManager } from "../GameManager"; 
import { GameVars } from "../GameVars";
import { telegramAuthentication } from "../server/server.api";
import { setAccessToken } from "../server/accessToken";
export class BootScene extends Phaser.Scene {

    public static bootedInWrongOrientation: boolean;

    constructor() {

        super("BootScene");
    }

    private waitForTelegramSDK = () => {
        return new Promise<void>((resolve) => {
          const interval = setInterval(() => {
            if ((window as any).Telegram) {
              clearInterval(interval);
              resolve();
            }
          }, 100);
        });
    };

    private auth(): void {
        const authPayload = {
            initData: window.Telegram.WebApp.initData,
            botId: 'EndlessSiegeGameBot',
            initDataUnsafe: window.Telegram.WebApp.initDataUnsafe
        };
        telegramAuthentication(authPayload).then((result) => {
            setAccessToken(result.body.jwt);
            if (!result.body || result.body.length == 0) {
                console.log("Authentication error!")
                return;
            }
        }).catch((error) => {
            console.log("Authentication error!" + error)
        });
    }

    public async preload() {
        this.load.atlas("texture_atlas_0", "assets/texture_atlas_0.png", "assets/texture_atlas_0.json");
        await this.waitForTelegramSDK();

        window.Telegram.WebApp.expand();
        window.HackneyGamesSDK.setup({
            initDataUnsafe: window.Telegram.WebApp.initDataUnsafe,
            botId: 'EndlessSiegeGameBot',
            sdkAuthToken: 'S6YhVz6qwLTQ7zjb4E6MSK7I2KoO1zzB'
        });
        await this.auth();
        window.HackneyGamesSDK.showAd({format: 'fullscreen_banner'});
        window.Telegram.WebApp.headerColor = `#F3F3F3`;

        //Dev2Dev analytics
        window.devtodev.initialize("5fc01f36-eb9b-0fa6-8157-4dc4340d3f2c", 
            { userId: window.Telegram.WebApp.initDataUnsafe.user.id }
        );
    }

    public create(): void {

        this.add.text(-100, -100, "abcdefg", { fontFamily: "Oswald-SemiBold", fontSize: 28, color: "#A6F834" });

        GameManager.setCurrentScene(this);

        document.addEventListener("fullscreenchange", (a: any) => {

            const canvas = document.querySelector("canvas");

            if (!GameVars.currentScene.scale.isFullscreen) {
                canvas.style.width = "";
                canvas.style.height = "";
            }
            
        }, false);

        if (!this.game.device.os.desktop) {

            const w = window.innerWidth;
            const h = window.innerHeight;

            if (w < h) {

                BootScene.bootedInWrongOrientation = false;
                GameManager.init();
                
            } else {

                BootScene.bootedInWrongOrientation = true;

                document.getElementById("orientation").style.display = "block";
                document.getElementById("content").style.display = "none"; 
            }  

        } else {
            GameManager.init();
        }
    }
}
