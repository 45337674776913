
import { AudioManager } from "./AudioManager";
import { GameVars } from "./GameVars";

export class Game extends Phaser.Game {

    public static currentInstance: Phaser.Game;

    constructor(config: Phaser.Types.Core.GameConfig, reactClass?: any) {
        
        super(config);
      
        Game.currentInstance = this;

        GameVars.reactClass = reactClass;
    }

    protected onBlur(): void {

        if (AudioManager.sound) {
            AudioManager.sound.mute(true);
        }

        if (AudioManager.music) {
            AudioManager.music.mute(true);
        }
    }

    protected onFocus(): void {

        if (AudioManager.sound) {
            AudioManager.sound.mute(GameVars.gameData.soundMuted);
        }

        if (AudioManager.music) {
            AudioManager.music.mute(GameVars.gameData.musicMuted);
        }
    }
}
