import { RewardType } from "endless-siege-engine/build/main/lib/Types";
import { GameVars } from "../../../GameVars";

export class Card extends Phaser.GameObjects.Container {
    
    constructor(scene: Phaser.Scene, xContainer: number, yContainer: number, x: number, y: number, rewardType: RewardType, reward: number, index: number, isSelected: boolean) {
        super(scene);

        this.setPosition(xContainer, yContainer);
        this.scale = .2;

        if (isSelected) {
            let effects = new Phaser.GameObjects.Image(this.scene, x - 3, y - 5, "texture_atlas_1", "bg_card_rays");
            effects.scale = 1.5 + index * 0.1;

            this.add(effects);

            this.scene.tweens.add({
                targets: effects,
                angle: 360,
                ease: Phaser.Math.Easing.Linear,
                duration: 19000 - 2000 * index,
                repeat: -1
            });
        }

        let card = new Phaser.GameObjects.Image(this.scene, x, y, "texture_atlas_1", (index <= 5) ? "baseCard" : "baseCard_especial");
        this.add(card);

        switch (rewardType) {
            case RewardType.BALLISTA_RANGE: {
                let ballista = new Phaser.GameObjects.Image(this.scene, x, y + 5, "texture_atlas_1", "patch_bows");
                this.add(ballista);

                let icon = new Phaser.GameObjects.Image(this.scene, x + ((reward >= 0.1) ? 49 : 39), y - 70, "texture_atlas_1", "icon_range");
                icon.scale = .9;
                this.add(icon);

                let text = new Phaser.GameObjects.Text(this.scene, x, y + 75, GameVars.gameText[GameVars.gameData.language].RANGE_, {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#663333", 8);
                this.add(text);

                text = new Phaser.GameObjects.Text(this.scene, x - 15, y - 70, "+" + Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break;
            }
            case RewardType.BALLISTA_DMG: {
                let ballista = new Phaser.GameObjects.Image(this.scene, x, y + 5, "texture_atlas_1", "patch_bows");
                this.add(ballista);

                let icon = new Phaser.GameObjects.Image(this.scene, x + ((reward >= 0.1) ? 45 : 33), y - 70, "texture_atlas_1", "icon_damage");
                icon.scale = 1.2;
                this.add(icon);

                let text = new Phaser.GameObjects.Text(this.scene, x, y + 75, GameVars.gameText[GameVars.gameData.language].DAMAGE_, {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#663333", 8);
                this.add(text);

                text = new Phaser.GameObjects.Text(this.scene, x - 15, y - 70, "+" + Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break; 
            }
            case RewardType.BALLISTA_COSTS: {
                let ballista = new Phaser.GameObjects.Image(this.scene, x, y + 5, "texture_atlas_1", "patch_bows");
                this.add(ballista);

                let icon = new Phaser.GameObjects.Image(this.scene, x + ((reward >= 0.1) ? 45 : 33), y - 70, "texture_atlas_1", "icon_price");
                icon.scale = 1.2;
                this.add(icon);

                let text = new Phaser.GameObjects.Text(this.scene, x, y + 75, GameVars.gameText[GameVars.gameData.language].CHEAPER, {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#663333", 8);
                this.add(text);

                text = new Phaser.GameObjects.Text(this.scene, x - 10, y - 70, Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break; 
            }
            case RewardType.TORCH_RANGE: {
                let torch = new Phaser.GameObjects.Image(this.scene, x, y + 5, "texture_atlas_1", "patch_torch");
                this.add(torch);

                let icon = new Phaser.GameObjects.Image(this.scene, x + ((reward >= 0.1) ? 49 : 39), y - 70, "texture_atlas_1", "icon_range");
                icon.scale = .9;
                this.add(icon);

                let text = new Phaser.GameObjects.Text(this.scene, x, y + 75, GameVars.gameText[GameVars.gameData.language].RANGE_, {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#663333", 8);
                this.add(text);

                text = new Phaser.GameObjects.Text(this.scene, x - 15, y - 70, "+" + Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break; 
            }
            case RewardType.TORCH_DMG: {
                let torch = new Phaser.GameObjects.Image(this.scene, x, y + 5, "texture_atlas_1", "patch_torch");
                this.add(torch);

                let icon = new Phaser.GameObjects.Image(this.scene, x + ((reward >= 0.1) ? 45 : 33), y - 70, "texture_atlas_1", "icon_damage");
                icon.scale = 1.2;
                this.add(icon);

                let text = new Phaser.GameObjects.Text(this.scene, x, y + 75, GameVars.gameText[GameVars.gameData.language].DAMAGE_, {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#663333", 8);
                this.add(text);

                text = new Phaser.GameObjects.Text(this.scene, x - 15, y - 70, "+" + Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break; 
            }
            case RewardType.TORCH_COSTS: {
                let torch = new Phaser.GameObjects.Image(this.scene, x, y + 5, "texture_atlas_1", "patch_torch");
                this.add(torch);

                let icon = new Phaser.GameObjects.Image(this.scene, x + ((reward >= 0.1) ? 45 : 33), y - 70, "texture_atlas_1", "icon_price");
                icon.scale = 1.2;
                this.add(icon);

                let text = new Phaser.GameObjects.Text(this.scene, x, y + 75, GameVars.gameText[GameVars.gameData.language].CHEAPER, {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#663333", 8);
                this.add(text);

                text = new Phaser.GameObjects.Text(this.scene, x - 10, y - 70, Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break;
            }
            case RewardType.CANNON_RANGE: {
                let cannon = new Phaser.GameObjects.Image(this.scene, x, y + 5, "texture_atlas_1", "patch_cannons");
                this.add(cannon);

                let icon = new Phaser.GameObjects.Image(this.scene, x + ((reward >= 0.1) ? 49 : 39), y - 70, "texture_atlas_1", "icon_range");
                icon.scale = .9;
                this.add(icon);

                let text = new Phaser.GameObjects.Text(this.scene, x, y + 75, GameVars.gameText[GameVars.gameData.language].RANGE_, {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#663333", 8);
                this.add(text);

                text = new Phaser.GameObjects.Text(this.scene, x - 15, y - 70, "+" + Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break;  
            }
            case RewardType.CANNON_DMG: {
                let cannon = new Phaser.GameObjects.Image(this.scene, x, y + 5, "texture_atlas_1", "patch_cannons");
                this.add(cannon);

                let icon = new Phaser.GameObjects.Image(this.scene, x + ((reward >= 0.1) ? 45 : 33), y - 70, "texture_atlas_1", "icon_damage");
                icon.scale = 1.2;
                this.add(icon);

                let text = new Phaser.GameObjects.Text(this.scene, x, y + 75, GameVars.gameText[GameVars.gameData.language].DAMAGE_, {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#663333", 8);
                this.add(text);

                text = new Phaser.GameObjects.Text(this.scene, x - 15, y - 70, "+" + Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break; 
            }
            case RewardType.CANNON_COSTS: {
                let cannon = new Phaser.GameObjects.Image(this.scene, x, y + 5, "texture_atlas_1", "patch_cannons");
                this.add(cannon);

                let icon = new Phaser.GameObjects.Image(this.scene, x + ((reward >= 0.1) ? 45 : 33), y - 70, "texture_atlas_1", "icon_price");
                icon.scale = 1.2;
                this.add(icon);

                let text = new Phaser.GameObjects.Text(this.scene, x, y + 75, GameVars.gameText[GameVars.gameData.language].CHEAPER, {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#663333", 8);
                this.add(text);

                text = new Phaser.GameObjects.Text(this.scene, x - 10, y - 70, Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break;  
            }
            case RewardType.TIME_WARP_RANGE: {
                let warp = new Phaser.GameObjects.Image(this.scene, x, y + 5, "texture_atlas_1", "patch_time_wraper");
                this.add(warp);

                let icon = new Phaser.GameObjects.Image(this.scene, x + ((reward >= 0.1) ? 49 : 39), y - 70, "texture_atlas_1", "icon_range");
                icon.scale = .9;
                this.add(icon);

                let text = new Phaser.GameObjects.Text(this.scene, x, y + 75, GameVars.gameText[GameVars.gameData.language].RANGE_, {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#663333", 8);
                this.add(text);

                text = new Phaser.GameObjects.Text(this.scene, x - 15, y - 70, "+" + Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break; 
            }
            case RewardType.TIME_WARP_COSTS: {
                let warp = new Phaser.GameObjects.Image(this.scene, x, y + 5, "texture_atlas_1", "patch_time_wraper");
                this.add(warp);

                let icon = new Phaser.GameObjects.Image(this.scene, x + ((reward >= 0.1) ? 45 : 33), y - 70, "texture_atlas_1", "icon_price");
                icon.scale = 1.2;
                this.add(icon);

                let text = new Phaser.GameObjects.Text(this.scene, x, y + 75, GameVars.gameText[GameVars.gameData.language].CHEAPER, {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#663333", 8);
                this.add(text);

                text = new Phaser.GameObjects.Text(this.scene, x - 10, y - 70, Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break; 
            }
            case RewardType.TIME_WARP_SPEED: {
                let warp = new Phaser.GameObjects.Image(this.scene, x, y + 5, "texture_atlas_1", "patch_time_wraper");
                this.add(warp);

                let icon = new Phaser.GameObjects.Image(this.scene, x + ((reward >= 0.1) ? 47 : 35), y - 70, "texture_atlas_1", "icon_intensity");
                icon.scale = 0.8;
                this.add(icon);

                let text = new Phaser.GameObjects.Text(this.scene, x, y + 75, GameVars.gameText[GameVars.gameData.language].INTENSITY, {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#663333", 8);
                this.add(text);

                text = new Phaser.GameObjects.Text(this.scene, x - 15, y - 70, "+" + Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break;
            }
            case RewardType.NEXT_ROUND_CREDITS: {
                let round = new Phaser.GameObjects.Image(this.scene, x, y + 25, "texture_atlas_1", "patch_waves_money");
                this.add(round);

                let text = new Phaser.GameObjects.Text(this.scene, x, y - 65, "+" + Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break; 
            }
            case RewardType.ENEMY_SPEED: {
                let enemies = new Phaser.GameObjects.Image(this.scene, x, y + 5, "texture_atlas_1", "path_orcs");
                this.add(enemies);

                let icon = new Phaser.GameObjects.Image(this.scene, x + ((reward >= 0.1) ? 43 : 35), y - 70, "texture_atlas_1", "icons_slowerEnemies");
                icon.scale = 1.2;
                this.add(icon);

                let text = new Phaser.GameObjects.Text(this.scene, x, y + 75, GameVars.gameText[GameVars.gameData.language].SLOWER, {fontFamily: "Supercell", fontSize: "15px", color: "#ffffff", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#663333", 8);
                this.add(text);

                text = new Phaser.GameObjects.Text(this.scene, x - 12, y - 70, Math.floor(reward * 100) + "%", {fontFamily: "Supercell", fontSize: "26px", color: "#FFE734", align: "center"});
                text.setOrigin(.5);
                text.setStroke("#B35B20", 8);
                text.setShadow(3, 3, "#700000");
                this.add(text);
                break; 
            }
            default:
                break;
        }


        if (isSelected) {
            setTimeout(() => {
                this.visible = true;
                scene.tweens.add({
                    targets: this,
                    scaleX: 1,
                    scaleY: 1,
                    y: y - 20,
                    ease: Phaser.Math.Easing.Cubic.InOut,
                    duration: 400,
                    onComplete: () => {
                        setTimeout(() => {
                            let effect = new Phaser.GameObjects.Sprite(scene, xContainer - 80, yContainer + 70, "texture_atlas_1", "fx_upgrades_towers_cards_01");
                            effect.scale *= 2;
                            effect.anims.play("gold_turret");
                            scene.add.existing(effect);
                            this.add(effect);
                        }, 300);

                        let effect = new Phaser.GameObjects.Sprite(scene, xContainer - 100, yContainer - 120, "texture_atlas_1", "fx_upgrades_towers_cards_01");
                        effect.anims.play("gold_turret");
                        effect.scale *= 2;
                        scene.add.existing(effect);
                        this.add(effect);

                        setTimeout(() => {
                            let effect = new Phaser.GameObjects.Sprite(scene, xContainer + 100, yContainer - 25, "texture_atlas_1", "fx_upgrades_towers_cards_01");
                            effect.scale *= 2;
                            effect.anims.play("gold_turret");
                            scene.add.existing(effect);
                            this.add(effect);
                        }, 200);
                    }
                });
            }, 3150);
        } else {
            setTimeout(() => {
                this.visible = true;
                scene.tweens.add({
                    targets: this,
                    scaleX: 1,
                    scaleY: 1,
                    y: y - 50,
                    ease: Phaser.Math.Easing.Cubic.InOut,
                    duration: 400,
                });
            }, 200);
        }
    }
}
