import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";

export class GraphicsScene extends Phaser.Scene {

    constructor() {

        super("GraphicsScene");
    }

    public create(): void {

        const background = this.add.graphics();
        background.fillStyle(0x000033);
        background.fillRect(0, 0, GameConstants.GAME_WIDTH, GameConstants.GAME_HEIGHT);
        
        const containerCenter = new Phaser.GameObjects.Container(this, GameConstants.GAME_WIDTH * 0.15, GameConstants.GAME_HEIGHT * 0.65);
        this.add.existing(containerCenter);

        const length = 625;
        const pathLengthFactor = 40 / 40;

        const grahics = new Phaser.GameObjects.Graphics(this);
        containerCenter.add(grahics);

        grahics.lineStyle(2, 0xFFFF00);
        grahics.moveTo(-15, 0);
        grahics.lineTo(length, 0);
        grahics.moveTo(0, 15);
        grahics.lineTo(0, -length);
        grahics.stroke();

        const dx = 3;

        for (let i = 0; i <= 200; i ++) {

            if (i % 25 === 0) {

                const text = new Phaser.GameObjects.Text(this, i * dx, 25, (i).toString(), {fontFamily: "Arial", fontSize: "16px", color: "#FFFFFF", align: "center"});
                text.setOrigin(.5);
                containerCenter.add(text);

                grahics.moveTo(i * dx, 0);
                grahics.lineTo(i * dx, 10);

                grahics.stroke();
            }
        }

        const scaleFactor = .5;

        let enemyHealthModifier = 1;

        for (let round = 1; round <= 200; ++round) {

            grahics.fillStyle(0xff0000);

            enemyHealthModifier += 2 * Math.pow(0.025 * round, 1.25) * pathLengthFactor + round * 0.0225; // current engine formula

            if (round > 125) {
                enemyHealthModifier = enemyHealthModifier * 1.045;
            } else if (round > 60) {
                enemyHealthModifier = enemyHealthModifier * 1.035;
            } else if (round > 45) {
                enemyHealthModifier = enemyHealthModifier * 1.03;
            }

            grahics.fillPoint(round * dx, -enemyHealthModifier * scaleFactor, 2);
        }

        enemyHealthModifier = 1;

        for (let round = 1; round <= 200; ++round) {

            grahics.fillStyle(0xFFFFFF);

            enemyHealthModifier += 1.675 * Math.pow(0.025 * round, 1.25) * pathLengthFactor + round * 0.0225; // current engine formula

            if (round > 75) {
                enemyHealthModifier = enemyHealthModifier * 1.025;
            } else if (round > 50) {
                enemyHealthModifier = enemyHealthModifier * 1.02;
            }

            grahics.fillPoint(round * dx, -enemyHealthModifier * scaleFactor, 2);
        }
    }
}
