
import { AudioManager } from "./../../../AudioManager";
import { TurretActor } from "./TurretActor";
import { GameVars } from "../../../GameVars";
import { EnemyActor } from "../enemy-actors/EnemyActor";
import * as Creepts from "endless-siege-engine";
import { TurretType } from "endless-siege-engine/build/main/lib/Types";
import { BattleScene } from "../BattleScene";

export class LaserTurretActor extends TurretActor {

    private framesDuration: number;
    private f: number;
    private laserShoot: boolean;

    private topAnimation: Phaser.GameObjects.Sprite;

    constructor(scene: Phaser.Scene, position: {r: number, c: number}, turret: Creepts.Turret) {

        super(scene, TurretType.LASER, position, turret);

        this.base = new Phaser.GameObjects.Image(this.scene, 0, -5, "texture_atlas_1", GameVars.torchUpgraded ? "fire_upgraded_1_base" : "fire_1_base");
        this.base.setInteractive({ useHandCursor: true });
        this.base.on("pointerdown", this.onDownTurret, this);
        this.addAt(this.base, 0);

        this.topAnimation = this.scene.add.sprite(0, -40, "texture_atlas_1");
        this.add(this.topAnimation);

        this.topAnimation.anims.play("fire");

        this.laserShoot = false;
    }

    public update(time: number, delta: number): void {

        if (this.laserShoot) {
            if (this.f ++ === this.framesDuration) {
                this.laserShoot = false;
            }
            return;
        }

        super.update(time, delta);
    }

    public upgrade(): void {

        super.upgrade();

        switch (this.turret.grade) {
 
            case 2:
                this.base.setFrame(GameVars.torchUpgraded ? "fire_upgraded_2_base" : "fire_2_base");
                this.topAnimation.anims.play("electricity");
                this.topAnimation.y = -35;
                break;
            case 3: 
                this.base.setFrame(GameVars.torchUpgraded ? "fire_upgraded_3_base" : "fire_3_base");
                this.topAnimation.anims.play("plasma");
                this.topAnimation.y = -35;
                break;
            default:
        }
    }

    public improve(): void {
        
        super.improve();

        this.topAnimation.blendMode = Phaser.BlendModes.SCREEN;
       
        this.scene.time.delayedCall(125, function(): void {
            this.topAnimation.blendMode = Phaser.BlendModes.NORMAL;
        }, [], this);
    }

    public shootLaser(enemyActors: EnemyActor[]): void {

        this.laserShoot = true;
        this.framesDuration = GameVars.timeStepFactor === 1 ? 24 : 6;
        this.f = 0;

        switch (this.turret.grade) {
            case 1:
                AudioManager.playSoundEffect("fireball");
                break;
            case 2: 
                AudioManager.playSoundEffect("t2_laser2");
                break;
            case 3:
                AudioManager.playSoundEffect("fireball");
                AudioManager.playSoundEffect("t2_laser3");
                break;
            default:
        }
    }
}
