import { GameConstants } from './../../../GameConstants';
import { GameVars } from '../../../GameVars';

export class Walls extends Phaser.GameObjects.Container {

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.y = (GameConstants.CELLS_SIZE * GameVars.enemiesPathCells[GameVars.enemiesPathCells.length - 1].r + GameConstants.CELLS_SIZE);

        for (let i = -2; i < 15; i++) {
            let img = new Phaser.GameObjects.Image(this.scene, i * GameConstants.CELLS_SIZE, 0, "texture_atlas_1", "wall");
            img.setOrigin(.5, 1);
            this.add(img);

            for (let j = 0; j < 10; j++) {
                let img = new Phaser.GameObjects.Image(this.scene, i * GameConstants.CELLS_SIZE, (j + 1) * GameConstants.CELLS_SIZE, "texture_atlas_1", "tile_castle");
                img.setOrigin(.5, 1);
                this.add(img);
            }
        }

        // let img = new Phaser.GameObjects.Graphics(this.scene);
        // img.fillStyle(0x2c4a84);
        // img.fillRect(- GameConstants.CELLS_SIZE / 2, 0, GameConstants.CELLS_SIZE * 14, GameConstants.CELLS_SIZE * 10);
        // this.add(img);
    }
}
