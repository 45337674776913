import { GameConstants } from "./../../../GameConstants";
import { BattleManager } from "./../BattleManager";
import { GameVars } from "../../../GameVars";
import { AudioManager } from "../../../AudioManager";
import { TutorialManager } from "../TutorialManager";
import { TurretType } from "endless-siege-engine/build/main/lib/Types";
import { Engine } from "endless-siege-engine/build/main/lib/Engine";

export class TurretButton extends Phaser.GameObjects.Container {

    private turretType: string;
    private maskShape: Phaser.GameObjects.Graphics;
    private base: Phaser.GameObjects.Image;
    private canon: Phaser.GameObjects.Image;
    private button: Phaser.GameObjects.Image;
    private backButton: Phaser.GameObjects.Image;
    private priceLabel: Phaser.GameObjects.BitmapText;
    private handCursor: Phaser.GameObjects.Image;
    private turretPrice: number;
   
    constructor(scene: Phaser.Scene, type: string, index: number) {

        super(scene);

        this.x = index * 110;
        this.turretType = type;
        this.name = type;
        this.handCursor = null;

        this.backButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_yellow_on");
        this.add(this.backButton);

        this.button = this.scene.add.image(this.x + 80, GameConstants.GAME_HEIGHT - 60 * GameVars.scaleY - 20, "texture_atlas_1", "btn_yellow");
        this.button.setInteractive({ useHandCursor: true });
        this.button.scaleY = GameVars.scaleY;
        this.button.on("pointerdown", this.onDownTurret, this);
        this.button.on("pointerover", () => { 

            if (GameVars.gameOver || GameVars.paused) {
                return;
            }
            this.setScale(1.05); 
            this.button.setScale(1.05, 1.05 * GameVars.scaleY);
        }, this);
        this.button.on("pointerout", () => { 
            if (GameVars.gameOver || GameVars.paused) {
                return;
            }
            this.setScale(1); 
            this.button.setScale(1, GameVars.scaleY);
        }, this);

        this.maskShape = new Phaser.GameObjects.Graphics(this.scene);
        this.maskShape.x = this.x + 80;
        this.maskShape.y = GameConstants.GAME_HEIGHT - 60 * GameVars.scaleY;

        const bases = ["archer_1_base", "fire_1_base", "cannon_1_base", "crystal_1_base"];
        const basesY = [8, -12, 0, -3];

        this.base = new Phaser.GameObjects.Image(this.scene, 0, 27 + basesY[index], "texture_atlas_1", bases[index]);
        this.base.setOrigin(.5, 1);
        this.add(this.base);

        const tops = ["archer_1_top", "fire_01", "cannon_1_top_19", "crystal_1_top_01"];
        const canonY = [-65, -80, -73, -67];

        this.canon = new Phaser.GameObjects.Image(this.scene, 0, 27 + canonY[index], "texture_atlas_1", tops[index]);
        this.canon.setOrigin(.5);
        this.add(this.canon);

        if (index === 0) {
            this.canon.rotation = Math.PI * (3 / 2);
        }

        const creditIcon = new Phaser.GameObjects.Image(this.scene, 26, 29, "texture_atlas_1", "coin");
        this.add(creditIcon);

        this.turretPrice = BattleManager.engine.turretData[this.turretType].price;

        this.priceLabel = new Phaser.GameObjects.BitmapText(this.scene, 8, 30, "supercell-white", this.turretPrice.toString(), 17);
        this.priceLabel.setOrigin(1, .5);
        this.add(this.priceLabel);

        this.scene.sys.updateList.add(this);
    }

    public setFrame(frame: string) {
        this.base.setFrame(frame);
    }

    public preUpdate(time: number, delta: number): void {

        if (this.turretPrice > BattleManager.engine.credits) {
           
            this.button.input.enabled = false;
            this.setScale(1); 
            this.button.setScale(1, GameVars.scaleY);
            this.priceLabel.setTexture("supercell-red");

            this.maskShape.visible = true;

            const scaleY = BattleManager.engine.credits / this.turretPrice;

            this.maskShape.clear();
            this.maskShape.fillStyle(0xff0000, .5);
            this.maskShape.fillRect(-this.button.width / 2, (this.button.height / 2) * GameVars.scaleY, this.button.width, - this.button.height * scaleY * GameVars.scaleY);

            this.button.setMask(this.maskShape.createGeometryMask());

        } else {

            this.maskShape.visible = false;
            this.button.input.enabled = true;
            this.maskShape.scaleY = 1;
            this.priceLabel.setTexture("supercell-white");

            if (this.button.mask) {
                this.button.clearMask();
            }
        }
    }

    public hide(): void {

        this.visible = false;
        this.button.visible = false;
    }

    public showHandCursor(): void {

        this.handCursor = new Phaser.GameObjects.Image(this.scene, 50, -40, "texture_atlas_1", "hand_cursor");
        this.handCursor.setOrigin(.2);
        this.add(this.handCursor);

        this.scene.tweens.add({
            targets: this.handCursor,
            x: 75,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 750,
            yoyo: false,
            repeat: -1
        });
    }

    public updateTextPrice(): void {
        switch (this.turretType) {
            case TurretType.PROJECTILE: {
                this.turretPrice = Math.floor(BattleManager.engine.turretData[this.turretType].price * BattleManager.engine.ballistaCostsFactor);
                break;
            }
            case TurretType.GLUE: {
                this.turretPrice = Math.floor(BattleManager.engine.turretData[this.turretType].price * BattleManager.engine.timeWarpCostsFactor);
                break;
            }
            case TurretType.LASER: {
                this.turretPrice = Math.floor(BattleManager.engine.turretData[this.turretType].price * BattleManager.engine.torchCostsFactor);
                break;
            }
            case TurretType.LAUNCH: {
                this.turretPrice = Math.floor(BattleManager.engine.turretData[this.turretType].price * BattleManager.engine.cannonCostsFactor);
                break;
            }
            default:
                break;
        }
        this.priceLabel.text = this.turretPrice.toString();
    }

    private onDownTurret(): void {

        if (GameVars.paused || GameVars.gameOver) {
            return;
        }

        if (!GameVars.gameData.tutorialSeen && TutorialManager.isTurretPlaced) {
            return;
        }

        if (this.handCursor) {
            this.handCursor.destroy();
            this.handCursor = null;

            TutorialManager.onTurredSelected();
        }

        BattleManager.createTurret(this.turretType);

        AudioManager.playSoundEffect("btn_click");
    }
}
