
// import { API_HOST } from "../config";
import { getAuthHeader } from "./getAuthHeader";
// import { WebAppInitData } from "@twa-dev/types";

const API_HOST = 'https://api2.hackney.games'

const contentTypeHeader = { "Content-Type": "application/json" };
const getHeaders = () => ({
  ...contentTypeHeader,
  ...getAuthHeader(),
});

type Balance = {
    amount: number;
    currency: string;
}

export function converBalancesToMap(balancesArray: Balance[]) {
  const balancesMap: Map<string, number> = new Map();
  balancesArray.map((balance:Balance) => {
    balancesMap.set(balance.currency,balance.amount);  
  })
  return balancesMap;
}

export const getBalances = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/tx/balances`, requestOptions);
  const status = response.status;

  let body: any = undefined;
  if (status === 200) {
    const balancesArray = (await response.json()) as Balance[];
    body = converBalancesToMap(balancesArray);
  }

  return { status, body };
};

export type LeaderboardEntry = {
  score: number;
  telegramId: string;
  userId: string;
  username: string;
};

export const getLeaderboard = async (gameId: string) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/leaderboard/${gameId}`, requestOptions);
  const status = response.status;
  const body = (await response.json()) as Array<LeaderboardEntry>;
  return { status, body };
};

export const getGame = async (groupGameId: string) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  const response = await fetch(`${API_HOST}/tgg/${groupGameId}`, requestOptions);
  const status = response.status;
  const body = await response.json();
  return { status, body };
};

export type AuthByTelegram = {
  initData: string;
  initDataUnsafe: any;
  botId: string;
}

export const telegramAuthentication = async (payload: AuthByTelegram) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`https://id.hackney.games/telegram`, requestOptions);
  const status = response.status;

  let body: any = undefined;
  if (status === 200) {
    body = await response.json();
  }

  return { status, body };
};

export const payForGame = async (gameId) => {
  const payload = { gameId: gameId };
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  };

  const response = await fetch(`${API_HOST}/tx/pay`, requestOptions);
  const status = response.status;

  let body: any = undefined;
  if (status === 200) {
    body = await response.json();
  }

  return { status, body };
};

export const sendTMAScore = async (score: number, gameId: string) => {

  const response = await fetch(`${API_HOST}/tma/sendScore`, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      score: score,
      gameId: gameId,
    }),
  });

  const status = response.status;
  let body: any = undefined;
  if (status === 200) {
    body = await response.json();
  }

  return { status, body };
};

export const updateScore = async (scorePayload: any) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(scorePayload),
  };

  const response = await fetch(`${API_HOST}/tgg/updateScore`, requestOptions);
  const status = response.status;
  const body = await response.json();

  return { body, status};
};