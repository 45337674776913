import { TurretMenu } from "./TurretMenu";
import { GameVars } from "../../../GameVars";
import { BattleManager } from "../BattleManager";
import { AudioManager } from "../../../AudioManager";
import { TutorialManager } from "../TutorialManager";
import { BattleScene } from "../BattleScene";

export class ButtonLevelUp extends Phaser.GameObjects.Container {

    public priceLabel: Phaser.GameObjects.BitmapText;
    
    private button: Phaser.GameObjects.Image;
    private turretMenu: TurretMenu;
    private handCursor: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene, x: number, y: number) {

        super(scene);

        this.x = x;
        this.y = y;

        this.button = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_yellow");
        this.button.setInteractive({ useHandCursor: true });
        this.button.on("pointerdown", this.onDownButton, this);
        this.button.on("pointerover", () => { 

            if (this.alpha !== 1) {
                return;
            }

            this.setScale(1.05); 
        }, this);
        this.button.on("pointerout", () => { 

            if (this.alpha !== 1) {
                return;
            }
            
            this.setScale(1); }, this);
        this.add(this.button);

        const text = new Phaser.GameObjects.Text(this.scene, 0, -34, GameVars.gameText[GameVars.gameData.language].LEVEL_UP, {fontFamily: "Supercell", fontSize: "14px", color: "#FFFFFF"});
        text.setOrigin(.5);
        text.setStroke("#000000", 4);
        text.setShadow(3, 3, "#000000");
        this.add(text);

        const img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "icon_lvl_up");
        this.add(img);

        this.priceLabel = new Phaser.GameObjects.BitmapText(this.scene, 0, 36, "supercell-white", GameVars.formatNumber(10), 17);
        this.priceLabel.setOrigin(.5);
        this.add(this.priceLabel);

        if (!GameVars.gameData.tutorialSeen) {

            this.handCursor = new Phaser.GameObjects.Image(this.scene, 50, -15, "texture_atlas_1", "hand_cursor");
            this.handCursor.setOrigin(.2);
            this.add(this.handCursor);
    
            this.scene.tweens.add({
                targets: this.handCursor,
                x: 75,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 750,
                yoyo: false,
                repeat: -1
            });
        } else {
            this.handCursor = null;
        }
    }

    public setTurretMenu(turretMenu: TurretMenu): void {
        this.turretMenu = turretMenu;
    }

    public clearTurretMenu(): void {
        this.turretMenu = null;
    }

    public setInactiveButton(): void {  
        if (this.turretMenu == null) {
            return;
        }

        this.alpha = .5;

        if (this.turretMenu.turret.level === this.turretMenu.turret.maxLevel) {
            this.priceLabel.visible = false;
        } else {
            this.priceLabel.setTexture("supercell-red");
        }
    }

    public setActiveButton(): void {
        if (this.turretMenu == null) {
            return;
        }

        this.alpha = 1;
        this.priceLabel.visible = true;
        this.priceLabel.setTexture("supercell-white");
    }

    private onDownButton(): void {
        if (this.alpha !== 1 || this.turretMenu == null) {
            return;
        }

        this.setScale(1);

        BattleManager.improveTurret(this.turretMenu.turret.id);
        this.turretMenu.checkAndUpdateInfo();

        if (!GameVars.gameData.tutorialSeen) {
            this.handCursor.destroy();
            TutorialManager.onTurretUpgraded();
        }
        BattleScene.currentInstance.boardContainer.getTurretActorByID(this.turretMenu.turret.id).showLevel();

        AudioManager.playSoundEffect("btn_click");
    }
}
