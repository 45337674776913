
import { GameManager } from "../GameManager";
import { AudioManager } from "../AudioManager";
import { GameConstants } from "../GameConstants";
import { GameVars } from "../GameVars";

export class PreloadScene extends Phaser.Scene {

    public static currentInstance: PreloadScene;

    private progressBar: Phaser.GameObjects.Graphics;
    private scaledItemmContainer: Phaser.GameObjects.Container;

    constructor() {

        super("PreloadScene");

        PreloadScene.currentInstance = this;
    }

    public preload(): void {

        this.composeScene();

        this.loadAssets();
    }

    public create(): void {

        let musicJson = this.cache.json.get("music");
        musicJson = JSON.parse(JSON.stringify(musicJson).replace("urls", "src"));

        AudioManager.music = new Howl(musicJson);

        AudioManager.music.unload();
        AudioManager.music.load();
    
        AudioManager.music.on("load", function() {

            let soundJson = PreloadScene.currentInstance.cache.json.get("sound");
            soundJson = JSON.parse(JSON.stringify(soundJson).replace("urls", "src"));

            AudioManager.sound = new Howl(soundJson);

            AudioManager.sound.unload();
            AudioManager.sound.load();

            AudioManager.sound.on("load", function() {

                GameManager.setCurrentScene(PreloadScene.currentInstance);
                PreloadScene.currentInstance.scene.setVisible(false);
                GameManager.onGameAssetsLoaded();
            });
        });
    }
 
    private composeScene(): void {

        this.add.text(-100, -100, "abcdefg", { fontFamily: "Supercell", fontSize: 28, color: "#A6F834" });

        const backgroundContainer = this.add.container(0, 0);
        backgroundContainer.scaleY = GameVars.scaleY;

        let splashBg = "splash_bg";
        if (GameVars.isHalloween) {
            splashBg = "halloween_splash_bg";
        }
        else if (GameVars.isXmas) {
            splashBg = "splash_bg";
        }
        let n = Math.round(GameConstants.GAME_HEIGHT / 128 / GameVars.scaleY) + 1;

        for (let i = 0; i < n; i ++) {
            const backgroundSlice = new Phaser.GameObjects.Image(this, 0, i * 128, "texture_atlas_0", splashBg);
            backgroundSlice.setOrigin(0, 0);
            backgroundContainer.add(backgroundSlice);
        }

        let gradientColor = "blue_gradient";
        if (GameVars.isHalloween) {
            gradientColor = "purple_gradient";
        }
        else if (GameVars.isXmas) {
            gradientColor = "blue_gradient";
        }
        const gradient = this.add.image(0, 0, "texture_atlas_0", gradientColor);
        gradient.setOrigin(0);
        gradient.scaleX = GameConstants.GAME_WIDTH / 10;
        gradient.scaleY = 1.35 / GameVars.scaleY;

        this.scaledItemmContainer = this.add.container(GameConstants.GAME_WIDTH / 2, GameConstants.GAME_HEIGHT * .35);
        this.scaledItemmContainer.setScale(1.25, 1.25 * GameVars.scaleY);

        this.progressBar = new Phaser.GameObjects.Graphics(this);
        this.progressBar.fillGradientStyle(0xFF0098, 0xFF0098, 0x00DBFF, 0x00DBFF);
        this.progressBar.fillRect(0, 0, 8, 400);
        this.progressBar.angle = -90;
        this.progressBar.x = -200;
        this.progressBar.y = 54;
        this.progressBar.scaleY = 0;
        this.scaledItemmContainer.add(this.progressBar);
    }

    private loadAssets(): void {

        this.load.atlas("texture_atlas_1", "assets/texture_atlas_1.png", "assets/texture_atlas_1.json");
        this.load.atlas("texture_atlas_2", "assets/texture_atlas_2.png", "assets/texture_atlas_2.json");
        this.load.atlas("texture_atlas_3", "assets/texture_atlas_3.png", "assets/texture_atlas_3.json");
        this.load.atlas("texture_atlas_4", "assets/texture_atlas_4.png", "assets/texture_atlas_4.json");
        this.load.atlas("texture_atlas_5", "assets/texture_atlas_5.png", "assets/texture_atlas_5.json");
        this.load.atlas("texture_atlas_6", "assets/texture_atlas_6.png", "assets/texture_atlas_6.json");

        // EL TEXTO DEL JUEGO
        this.load.json("game-text", "assets/config/text.json");

        // fuentes
        this.load.bitmapFont("supercell-white", "assets/fonts/supercell-white.png", "assets/fonts/supercell-white.xml");
        this.load.bitmapFont("supercell-green", "assets/fonts/supercell-green.png", "assets/fonts/supercell-green.xml");
        this.load.bitmapFont("supercell-red", "assets/fonts/supercell-red.png", "assets/fonts/supercell-red.xml");

        this.load.json("sound", "assets/audio/sound.json");
        this.load.json("music", "assets/audio/music.json");

        (this.load as any).spine("chests", "assets/spine/chests.json", "assets/spine/chests.atlas");

        this.load.on("progress", this.updateLoadedPercentage, this);
    }

    private updateLoadedPercentage(percentageLoaded: number): void {

        if (this.progressBar.scaleY < percentageLoaded) {
            this.progressBar.scaleY = percentageLoaded;
        }
    }
}
