import { CoinsEffect } from "./CoinsEffect";

export class CoinsEffectPool {

    private scene: Phaser.Scene;
    private coinsEffects: CoinsEffect [];
    private coinsEffectsOnStage: CoinsEffect [];

    constructor(scene: Phaser.Scene, numCoinsEffect: number) {
        
        this.scene = scene;

        this.scene = scene;
        this.coinsEffects = [];
        this.coinsEffectsOnStage = [];

        for (let i = 0; i < numCoinsEffect; i++) {
            
            const coinsEffect = new CoinsEffect(this.scene);
            this.coinsEffects.push(coinsEffect);
        }
    }

    public update(): void {

        for (let i = 0; i < this.coinsEffectsOnStage.length; i++) {
            this.coinsEffectsOnStage[i].update(); 
        }
    }

    public getCoinsEffect(x: number, y: number, value: number): CoinsEffect {

        if (this.coinsEffects.length === 0) {
            this.increasePoolSize();
        }

        const coinsEffect = this.coinsEffects.pop();
        coinsEffect.reset(x, y, value);

        this.coinsEffectsOnStage.push(coinsEffect);

        return coinsEffect;
    }

    public onCoinsEffectRemoved(coinsEffect: CoinsEffect): void {

        const i = this.coinsEffectsOnStage.indexOf(coinsEffect);
        this.coinsEffectsOnStage.splice(i, 1);

        this.coinsEffects.push(coinsEffect);
    }

    private increasePoolSize(): void  {

        for (let i = 0; i < 5; i ++) {
            const coinsEffect = new CoinsEffect(this.scene);
            this.coinsEffects.push(coinsEffect);
        }
    }
}
