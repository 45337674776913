import { BattleScene } from "./BattleScene";
import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { BoardContainer } from "./board-container/BoardContainer";
import enemiesData from "../../../assets/config/enemies.json";
import turretsData from "../../../assets/config/turrets.json";
import wavesData from "../../../assets/config/waves.json";
import * as Creepts from "endless-siege-engine";
import { TutorialManager } from "./TutorialManager";
import { GameManager } from "../../GameManager";
import { ActionTypes } from "endless-siege-engine/build/main/lib/Types";


export class BattleManager {

    public static engine: Creepts.Engine;

    public static init(): void {

        GameVars.paused = true;

        BattleManager.onIdSelected();
    }

    public static onIdSelected(): void {

        const aspectRatio = window.innerHeight / window.innerWidth;

        if (aspectRatio > 1.5) {
            if (GameVars.currentMapData.size.c > 11) {
                GameVars.scaleCorrectionFactor = 1;
            } else if (GameVars.currentMapData.size.c > 10) {
                GameVars.scaleCorrectionFactor = 1.1;
            } else {
                GameVars.scaleCorrectionFactor = 1.2;
            }
        }

        let gameConfig: Creepts.GameConfig;

        GameVars.enemiesPathCells = GameVars.currentMapData.path;
        GameVars.plateausCells = GameVars.currentMapData.plateaus;
        GameVars.waterCells = GameVars.currentMapData.water;

        GameVars.enemiesData = enemiesData.enemies;
        GameVars.turretsData = turretsData.turrets;
        GameVars.wavesData = wavesData.waves;

        gameConfig = {
            timeStep: GameConstants.TIME_STEP,
            runningInClientSide: true,
            enemySpawningDeltaTicks: GameConstants.ENEMY_SPAWNING_DELTA_TICKS,
            credits: GameConstants.DEVELOPMENT ? GameConstants.INITIAL_CREDITS : GameConstants.INITIAL_CREDITS,
            lifes: GameConstants.INITIAL_LIFES,
            boardSize: GameVars.currentMapData.size,
            enemiesPathCells : GameVars.enemiesPathCells,
            plateausCells: GameVars.plateausCells
        };

        GameVars.logsObject = {
            actions: []
        };

        if (GameVars.currentScene === BattleScene.currentInstance || !GameVars.timeStepFactor) {
            GameVars.timeStepFactor = 1;
        }
        
        GameVars.wave = 1;
        GameVars.paused = false;
        GameVars.semiPaused = false;
        GameVars.waveOver = true;
        GameVars.autoSendWave = false;
        GameVars.loopNumber = 1;
        GameVars.loopRate = 1;
        GameVars.loopVolume = .2;
        GameVars.dangerRate = 1;
        GameVars.gameOver = false;
        GameVars.paymentDone = false;

        BattleManager.engine = new Creepts.Engine(gameConfig, GameVars.enemiesData, GameVars.turretsData, GameVars.wavesData);

        BattleManager.setTimeStepFactor(GameVars.timeStepFactor);

        GameVars.levelObject = {
            engineVersion: BattleManager.engine.version,
            gameConfig: gameConfig,
            enemiesData: GameVars.enemiesData,
            turretsData: GameVars.turretsData,
            wavesData: GameVars.wavesData
        };
        
        BattleManager.engine.addEventListener(Creepts.Event.ENEMY_SPAWNED, BattleManager.onEnemySpawned, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.ENEMY_REACHED_EXIT, BattleManager.onEnemyReachedExit, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.BULLET_SHOT, BattleManager.onBulletShot, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.GLUE_BULLET_SHOT, BattleManager.onGlueBulletShot, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.ENEMY_HIT, BattleManager.onEnemyHit, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.REMOVE_BULLET, BattleManager.removeBullet, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.REMOVE_GLUE_BULLET, BattleManager.removeGlueBullet, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.ENEMY_GLUE_HIT, BattleManager.onEnemyGlueHit, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.ENEMY_KILLED, BattleManager.onEnemyKilled, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.LASER_SHOT, BattleManager.onLaserBeamShot, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.MORTAR_SHOT, BattleManager.onMortarShot, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.GLUE_SHOT, BattleManager.onGlueShot, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.MINE_SHOT, BattleManager.onMineShot, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.GLUE_CONSUMED, BattleManager.onGlueConsumed, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.ENEMIES_TELEPORTED, BattleManager.onEnemiesTeleported, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.NO_ENEMIES_ON_STAGE, BattleManager.onNoEnemiesOnStage, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.WAVE_OVER, BattleManager.onWaveOver, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.GAME_OVER, BattleManager.onGameOver, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.ACTIVE_NEXT_WAVE, BattleManager.onNextWaveActive, BattleManager);
        BattleManager.engine.addEventListener(Creepts.Event.NEXT_ROUND_IS_REWARD, BattleManager.nextRoundIsReward, BattleManager);

        BattleScene.currentInstance.start();
    }

    public static update(time: number, delta: number): void {

        BattleManager.engine.update();
    }

    public static pause(): void {
        if (!GameVars.semiPaused) {
            GameVars.currentScene.anims.pauseAll();
        }
        GameVars.paused = true;
    }

    public static resume(): void {
        if (!GameVars.semiPaused) {
            GameVars.currentScene.anims.resumeAll();
        }
        GameVars.paused = false;
    }

    public static semiPause(): void {

        GameVars.currentScene.anims.pauseAll();

        GameVars.semiPaused = true;

        BattleScene.currentInstance.semiPause();
    }

    public static semiResume(): void {

        GameVars.currentScene.anims.resumeAll();

        GameVars.semiPaused = false;

        BattleScene.currentInstance.semiResume();
    }

    public static setTimeStepFactor(timeStepFactor: number): void {

        GameVars.timeStepFactor = timeStepFactor;

        BattleManager.engine.timeStep = GameConstants.TIME_STEP / timeStepFactor;
    }

    public static newWave(): number {

        const bonus = BattleManager.engine.newWave();

        if (bonus !== null) {

            GameVars.waveOver = false;

            BattleScene.currentInstance.newWave(bonus);
            
            const action = {ty: ActionTypes.TYPE_NEXT_WAVE, tk: BattleManager.engine.ticksCounter};
            BattleManager.addAction(action);
        }

        return bonus;
    }

    public static createTurret(type: string): void {

        if (GameVars.currentScene === BattleScene.currentInstance) {
            BattleScene.currentInstance.createTurret(type);
        }
    }

    public static nextRoundIsReward(): void {
        GameVars.timeToReward = true;
        BattleScene.currentInstance.gui.onShowRewardWave();
    }

    public static addTurretToScene(type: string, position: {r: number, c: number}): void {

        BoardContainer.currentInstance.addTurret(type, position);
    }

    public static addTurret(type: string, position: {r: number, c: number}): Creepts.Turret {

        let data = BattleManager.engine.addTurret(type, position);

        if (data.turret) {
            let action = {ty: ActionTypes.TYPE_ADD_TURRET, tk: BattleManager.engine.ticksCounter, trT: data.turret.type, p: position};
            BattleManager.addAction(action);
        }

        return data.turret;
    }

    public static sellTurret(id: number): void {

        if (BattleManager.engine.sellTurret(id).success) {

            let action = {ty: ActionTypes.TYPE_SELL_TURRET, tk: BattleManager.engine.ticksCounter, id: id};
            BattleManager.addAction(action);

            BoardContainer.currentInstance.onTurretSold(id);
        }
    }

    public static onClickMenu(): void {

        BoardContainer.currentInstance.showPauseMenu();
    }

    public static setAutoSendWave(value: boolean): void {

        GameVars.autoSendWave = value;
    }

    public static improveTurret(id: number): void {

        const success = BattleManager.engine.improveTurret(id).success;

        if (success) {
            
            const action = {ty: ActionTypes.TYPE_LEVEL_UP_TURRET, tk: BattleManager.engine.ticksCounter, id: id};
            BattleManager.addAction(action);

            BoardContainer.currentInstance.improveTurret(id);
        }
    }

    public static upgradeTower(id: number): void {
        
        const success = BattleManager.engine.upgradeTurret(id).success;

        if (success) {

            let action = {ty: ActionTypes.TYPE_UPGRADE_TURRET, tk: BattleManager.engine.ticksCounter, id: id};
            BattleManager.addAction(action);

            BoardContainer.currentInstance.upgradeTurret(id);
            
            if (GameVars.currentScene === BattleScene.currentInstance) {
                BattleScene.currentInstance.updateTurretMenu();
            }
        }
    }

    public static setNextStrategy(id: number): void {

        if (BattleManager.engine.setNextStrategy(id).success) {
            let action = {ty: ActionTypes.TYPE_CHANGE_STRATEGY_TURRET, tk: BattleManager.engine.ticksCounter, id: id};
            BattleManager.addAction(action);
        }
    }

    public static setFixedTarget(id: number): void {

        if (BattleManager.engine.setFixedTarget(id).success) {
            let action = {ty: ActionTypes.TYPE_CHANGE_FIXED_TARGET_TURRET, tk: BattleManager.engine.ticksCounter, id: id};
            BattleManager.addAction(action);
        }
    }

    public static createRangeCircle(range: number, x: number, y: number): Phaser.GameObjects.Image {

        return BoardContainer.currentInstance.createRangeCircle(range, x, y);
    }

    public static hideRangeCircles(): void {

        BoardContainer.currentInstance.hideRangeCircles();
    }

    public static showTurretMenu(turret: Creepts.Turret): void {

        BattleScene.currentInstance.showTurretMenu(turret);
    }

    public static hideTurretMenu(): void {

        BattleScene.currentInstance.hideTurretMenu();
    }

    public static setTurretPrePosition(c: number, r: number): void {

        BoardContainer.currentInstance.setTurretPrePosition(c, r);
    }

    public static showAllAvailbalePositions(): void {
        BoardContainer.currentInstance.showAvailablePositions();
    }

    public static clearAllAvailbalePositions(): void {
        BoardContainer.currentInstance.clearAllAvailablePositions();
    }

    public static onDiscardMatch(): void {
        
        GameManager.reset();
    }

    public static AddOnChestSelectedAction(index: number): void {
        const action = {ty: ActionTypes.TYPE_CHEST_SELECTED, tk: BattleManager.engine.ticksCounter, id: index};
        BattleManager.addAction(action);
    }

    public static AddOnContinueAfterChestAction(): void {
        const action = {ty: ActionTypes.TYPE_CONTINUE_AFTER_CHEST, tk: BattleManager.engine.ticksCounter};
        BattleManager.addAction(action);
    }

    private static addAction(action: Action): void {

        GameVars.logsObject.actions.push(action);
    }

    private static onEnemySpawned(enemy: Creepts.Enemy, p: {r: number, c: number} ): void {
        
        BoardContainer.currentInstance.addEnemy(enemy, p);
    }

    private static onEnemyReachedExit(enemy: Creepts.Enemy): void {

        BoardContainer.currentInstance.removeEnemy(enemy.id);

        if (!BattleManager.engine.gameOver) {

            GameVars.dangerRate = Math.max(GameVars.dangerRate - .0125, .75);

            BattleScene.currentInstance.onEnemyReachedExit();
            
        }

        BattleScene.currentInstance.hud.updateLifes();
    }

    private static onBulletShot(bullet: Creepts.Arrow, projectileTurret: Creepts.ProjectileTurret): void {

        BoardContainer.currentInstance.addBullet(bullet, projectileTurret);
    }

    private static onGlueBulletShot(bullet: Creepts.GlueBullet, turret: Creepts.GlueTurret): void {

        BoardContainer.currentInstance.addGlueBullet(bullet, turret);
    }

    private static onLaserBeamShot(laserTurret: Creepts.LaserTurret, enemies: Creepts.Enemy[]): void {

        BoardContainer.currentInstance.addLaserBeam(laserTurret, enemies);
    }

    private static onMortarShot(mortar: Creepts.Mortar, launchTurret: Creepts.LaunchTurret): void {

        BoardContainer.currentInstance.addMortar(mortar, launchTurret);
    }

    private static onGlueShot(glue: Creepts.Glue, turret: Creepts.GlueTurret): void {

        BoardContainer.currentInstance.addGlue(glue, turret);
    }

    private static onMineShot(mine: Creepts.Mine, launchMine: Creepts.LaunchTurret): void {

        BoardContainer.currentInstance.addMine(mine, launchMine);
    }

    private static onGlueConsumed(glue: Creepts.Glue): void {

        BoardContainer.currentInstance.onGlueConsumed(glue);
    }

    private static onEnemyHit(enemies: Creepts.Enemy[], bullet?: Creepts.Arrow, mortar?: Creepts.Mortar, mine?: Creepts.Mine, fire?: boolean, electric?: boolean): void {

        let impactType: string = null;

        if (fire && electric) {
            impactType = GameConstants.IMPACT_FIRE_ELECTRIC;
        } else if (bullet) {
            impactType = GameConstants.IMPACT_ARROW;
        } else if (mortar) {
            impactType = GameConstants.IMPACT_MORTAR;
        } else if (mine) {
            impactType = GameConstants.IMPACT_MINE;
        } else if (fire) {
            impactType = GameConstants.IMPACT_FIRE;
        } else if (electric) {
            impactType = GameConstants.IMPACT_ELECTRIC;
        } 

        for (let i = 0; i < enemies.length; i ++) {
            BoardContainer.currentInstance.onEnemyHit(enemies[i], impactType);
        }
        
        if (bullet) {
            BoardContainer.currentInstance.removeBullet(bullet);
        } 

        if (mortar) {
            BoardContainer.currentInstance.detonateMortar(mortar);
        }

        if (mine) {
            BoardContainer.currentInstance.detonateMine(mine);
        }
    }

    private static removeBullet(bullet?: Creepts.Arrow) {

        BoardContainer.currentInstance.removeBullet(bullet);
    }

    private static removeGlueBullet(bullet: Creepts.GlueBullet): void {
        
        BoardContainer.currentInstance.removeGlueBullet(bullet);
    }

    private static onEnemyGlueHit(enemies: Creepts.Enemy[], bullet: Creepts.GlueBullet): void {

        for (let i = 0; i < enemies.length; i ++) {
            BoardContainer.currentInstance.onEnemyGlueHit(enemies[i]);
        }

        if (bullet) {
            BoardContainer.currentInstance.removeGlueBullet(bullet);
        } 
    }

    private static onEnemiesTeleported(teleportedEnemiesData: {enemy: Creepts.Enemy, glueTurret: Creepts.GlueTurret} []): void {

        for (let i = 0; i < teleportedEnemiesData.length; i++) {
            BoardContainer.currentInstance.teleportEnemy(teleportedEnemiesData[i].enemy, teleportedEnemiesData[i].glueTurret);
        }
    }

    private static onEnemyKilled(enemy: Creepts.Enemy): void {

        BoardContainer.currentInstance.onEnemyKilled(enemy);

        if (!GameVars.gameData.tutorialSeen && !TutorialManager.isFirstEnemyKilled) {
            TutorialManager.onFirstEnemyKilled();
        }
    }
    
    private static onNoEnemiesOnStage(): void {

        if (!BattleManager.engine.gameOver) {
            BoardContainer.currentInstance.showRoundCompletedLayer();
            if (GameVars.timeToReward) {
                GameVars.timeToReward = false;
                BattleScene.currentInstance.gui.onHideRewardWave();
                BattleScene.currentInstance.showChestsLayer();
            }
        }
    }

    private static onWaveOver(): void {

        if (GameVars.autoSendWave) {
            BattleScene.currentInstance.gui.onClickNextWave();
        } else {
            GameVars.waveOver = true;
        }
    }

    private static onNextWaveActive(): void {
 
        GameVars.wave ++;

        if (GameVars.currentScene === BattleScene.currentInstance) {
            BattleScene.currentInstance.gui.activeNextWave();
        }
    }

    private static onGameOver(): void {

        if (GameConstants.DOWNLOAD_FILES) {
            BattleManager.downloadGameFiles();
        }

        GameVars.gameOver = true;

        BattleScene.currentInstance.showGameOverLayer();   
    }

    private static downloadGameFiles(): void {

        const a = document.createElement("a");
        const file = new Blob([JSON.stringify(GameVars.levelObject)], { type: "text/plain" });
        a.href = URL.createObjectURL(file);
        a.download = "level.json";
        a.click();

        const b = document.createElement("a");
        const file2 = new Blob([JSON.stringify(GameVars.logsObject)], { type: "text/plain" });
        b.href = URL.createObjectURL(file2);
        b.download = "logs.json";
        b.click();
    }
}
