import { RewardType } from "endless-siege-engine/build/main/lib/Types";
import { TurretType } from "endless-siege-engine/build/main/lib/Types";
import { AudioManager } from "../../../AudioManager";
import { GameConstants } from "../../../GameConstants";
import { GameVars } from "../../../GameVars";
import { BattleManager } from "../BattleManager";
import { BattleScene } from "../BattleScene";
import { Chest } from "./Chest";

export class ChestsManager extends Phaser.GameObjects.Container {
    
    private subtitle: Phaser.GameObjects.Text;
    private chests: Chest[];
    private chestsType: RewardType[];
    private chestSelectedType: RewardType;
    private boing: NodeJS.Timeout;
    private numChestsReady: number;

    constructor (scene: Phaser.Scene) {
        super(scene);

        GameVars.paused = true;

        this.numChestsReady = 0;

        this.chests = [];

        this.chestsType = BattleManager.engine.getChestsType();

        while (this.chestsType[0] === this.chestsType[1] || this.chestsType[0] === this.chestsType[2] || this.chestsType[1] === this.chestsType[2]) {
            console.log("Error! Same chests!");
            this.chestsType = BattleManager.engine.getChestsType();
        }

        const width = 700;
        const height = 640;
        const r = 15;
        const x = -width * 0.5;
        const y = -191;

        const menuBackground = new Phaser.GameObjects.Graphics(this.scene);
        menuBackground.fillStyle(0X040e18, .7);
        menuBackground.fillRoundedRect(x, y, width, height, r);

        menuBackground.lineStyle(2, 0xa9e8e8);
        menuBackground.strokeRoundedRect(x - 2, y - 2, width + 4, height + 4, r + 2);

        menuBackground.lineStyle(2, 0x040e18, .9);
        menuBackground.strokeRoundedRect(x + 2, y + 2, width - 4, height - 4, r - 2);
        this.add(menuBackground);

        const blueBanner = new Phaser.GameObjects.Image(this.scene, 0, y, "texture_atlas_1", "title_area");
        blueBanner.setScale(0.6, .8);
        this.add(blueBanner);

        const menuTitle = new Phaser.GameObjects.Text(this.scene, 0, -7 + y, GameVars.gameText[GameVars.gameData.language].ROYAL_AID, {fontFamily: "Supercell", fontSize: "26px", color: "#FFFFFF"});
        menuTitle.setOrigin(.5);
        menuTitle.setStroke("#000000", 6);
        menuTitle.setShadow(4, 4, "#000000");
        this.add(menuTitle);

        this.subtitle = new Phaser.GameObjects.Text(this.scene, 0, 50 + y,  GameVars.gameText[GameVars.gameData.language].CHOOSE_REWARD, {fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF"});
        this.subtitle.setOrigin(.5);
        this.subtitle.setStroke("#000000", 6);
        this.subtitle.setShadow(4, 4, "#000000");
        this.add(this.subtitle);

        let randomTimes = [500, 800, 1100];
        const timeFinish = randomTimes[2];
        for (let i = 0; i < 3; ++i) {           
            let index = Math.floor(Math.random() * randomTimes.length);

            let chest = new Chest(scene, {x: (i === 0) ? -200 : (i === 1) ? 20 : 240, y: 100}, this.chestsType[i], this);
            this.chests.push(chest);

            setTimeout(() => {
                chest.start();              
            }, randomTimes[index]);
            randomTimes.splice(index, 1);
        }
        
        this.boing = setTimeout(() => {
            this.boingRandomChest();
        }, timeFinish);
    }

    public onChestSelected(chest: Chest): void {
        this.subtitle.visible = false;
        this.chestSelectedType = chest.getRewardType();
        clearTimeout(this.boing);

        for (let i = 0; i < 3; ++i) {
            let rewardIndex: number;
            if (this.chests[i] === chest) {
                if (GameConstants.DEVELOPMENT) {
                    rewardIndex = BattleManager.engine.getGoodRewardIndex();
                    BattleManager.AddOnChestSelectedAction(i);
                } else {
                    rewardIndex = BattleManager.engine.getSelectedRandomRewardIndex();
                    BattleManager.AddOnChestSelectedAction(i);
                }
            } else {
                rewardIndex = Math.floor(Math.random() * 8);
            }
            this.chests[i].onOpenChest(BattleManager.engine.getChestValue(rewardIndex, this.chestsType[i]), rewardIndex);
        }
    }

    public onSelectedChestOpened(chest: Chest): void {
        this.applyChestReward(chest);
        this.createEndButtons();
    }

    public onChestOver(chest: Chest) {
        clearTimeout(this.boing);
        
        chest.playAnimation("chest_selected");
        for (let i = 0; i < 3; ++i) {
            if (this.chests[i] !== chest) {
                this.chests[i].playAnimation("chest_no_selected");
            }
        }
    }

    public onChestOut(chest: Chest) {
        this.boingRandomChest();
        for (let i = 0; i < 3; ++i) {
            if (this.chests[i] !== chest) {
                this.chests[i].playAnimation("chest_no_selected_reverse");
            }
        }
    }

    public chestReady(): void {
        ++this.numChestsReady;
    }

    public areChestsReady(): boolean {
        return this.numChestsReady === 3;
    }

    private onPlayButtonClicked(): void {
        setTimeout(() => {
            switch (this.chestSelectedType) {
                case RewardType.BALLISTA_RANGE:
                case RewardType.BALLISTA_DMG:
                case RewardType.BALLISTA_COSTS:
                    GameVars.ballistaUpgraded = true;
                    BattleScene.currentInstance.gui.turretButtonsContainer.turretButtons[0].setFrame("archer_upgraded_1_base");
                    BattleScene.currentInstance.boardContainer.changeTurretSprite(TurretType.PROJECTILE);
                    AudioManager.playSoundEffect("turret_upgrade");
                    break; 
                case RewardType.TORCH_RANGE:
                case RewardType.TORCH_DMG:
                case RewardType.TORCH_COSTS:
                    GameVars.torchUpgraded = true;
                    BattleScene.currentInstance.gui.turretButtonsContainer.turretButtons[1].setFrame("fire_upgraded_1_base");
                    BattleScene.currentInstance.boardContainer.changeTurretSprite(TurretType.LASER);
                    AudioManager.playSoundEffect("turret_upgrade");
                    break;
                case RewardType.CANNON_RANGE:
                case RewardType.CANNON_DMG:
                case RewardType.CANNON_COSTS:
                    GameVars.cannonUpgraded = true;
                    BattleScene.currentInstance.gui.turretButtonsContainer.turretButtons[2].setFrame("cannon_upgraded_1_base");
                    BattleScene.currentInstance.boardContainer.changeTurretSprite(TurretType.LAUNCH);
                    AudioManager.playSoundEffect("turret_upgrade");
                    break;  
                case RewardType.TIME_WARP_RANGE:
                case RewardType.TIME_WARP_COSTS:
                case RewardType.TIME_WARP_SPEED:
                    GameVars.timeWarpUpgraded = true;
                    BattleScene.currentInstance.gui.turretButtonsContainer.turretButtons[3].setFrame("crystal_upgraded_1_base");
                    BattleScene.currentInstance.boardContainer.changeTurretSprite(TurretType.GLUE);
                    AudioManager.playSoundEffect("turret_upgrade");
                    break;
                case RewardType.NEXT_ROUND_CREDITS:
                    BattleScene.currentInstance.gui.updateNextWaveButtonToGold();
                    AudioManager.playSoundEffect("turret_upgrade");
                    break;
                default:
                    break;
            }
        }, 350);
        BattleScene.currentInstance.hideChestLayer();
    }

    private onPayButtonClicked(): void {
        BattleScene.currentInstance.showChestLayerAgain();
    }

    private boingRandomChest(): void {
        this.boing = setTimeout(() => {
            if (this.subtitle.visible) {
                this.chests[Math.floor(Math.random() * 3)].playAnimation("chest_boing");
                this.boingRandomChest();
            }
        }, 5000);
    }

    private createEndButtons(): void {
        setTimeout(() => {
            const y = (this.chestSelectedType !== RewardType.NEXT_ROUND_CREDITS) ? 370 : 385;

            const playButtonLabel = new Phaser.GameObjects.Text(this.scene, 0, y,  GameVars.gameText[GameVars.gameData.language].PLAY_MAYUS,  {fontFamily: "Supercell", fontSize: "24px", color: "#FFFFFF"});
            playButtonLabel.setOrigin(.5);
            playButtonLabel.setStroke("#663333", 8);
            playButtonLabel.setShadow(5, 5, "#663333");

            const playButton = new Phaser.GameObjects.Image(this.scene, 0, y, "texture_atlas_1", "btn_yellow_wide");
            playButton.setInteractive({ useHandCursor: true });
            playButton.on("pointerover", () => {
                playButton.setScale(1.025);
                playButtonLabel.setScale(1.025);
            });
            playButton.on("pointerout", () => { 
                playButton.setScale(1);  
                playButtonLabel.setScale(1);
            });
            playButton.on("pointerdown", this.onPlayButtonClicked, this);
            this.add(playButton);
            this.add(playButtonLabel);


            /*if (!BattleScene.currentInstance.doingPayChestRound) {
                const payButtonImage = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_yellow_wide");
                const payButtonLabel = new Phaser.GameObjects.Text(this.scene, 0, 0,  GameVars.gameText[GameVars.gameData.language].EXTRA_ROYAL_AID + "\n         " +  GameVars.gameText[GameVars.gameData.language].WAX_10,  {fontFamily: "Supercell", fontSize: "9px", color: "#FFFFFF"});
                payButtonLabel.setOrigin(.5);
                payButtonLabel.setStroke("#663333", 8);
                payButtonLabel.setShadow(5, 5, "#663333");
            
                const playButton = new ButtonWithText(this.scene, payButtonImage, payButtonLabel, this.onPayButtonClicked, this);
                playButton.x = 240;
                playButton.y = 340;

                this.add(playButton);
            }*/
        }, 250);
    }

    private applyChestReward(chest: Chest): void {
        BattleManager.engine.applySelectedChestValue(chest.getRewardType());
        switch (chest.getRewardType()) {
            case RewardType.NEXT_ROUND_CREDITS: {
                // Nopthing
                break;
            }
            case RewardType.ENEMY_SPEED: {
                // Nothing to do
                break; 
            }
            case RewardType.BALLISTA_RANGE: {
                BattleScene.currentInstance.boardContainer.onTurretRangesModified(TurretType.PROJECTILE);
                break;
            }
            case RewardType.TORCH_RANGE: {
                BattleScene.currentInstance.boardContainer.onTurretRangesModified(TurretType.LASER);
                break; 
            }
            case RewardType.CANNON_RANGE: {
                BattleScene.currentInstance.boardContainer.onTurretRangesModified(TurretType.LAUNCH);
                break;  
            }
            case RewardType.TIME_WARP_RANGE: {
                BattleScene.currentInstance.boardContainer.onTurretRangesModified(TurretType.GLUE);
                break; 
            }
            case RewardType.BALLISTA_DMG: {
                // Nothing to do
                break; 
            }
            case RewardType.TORCH_DMG: {
                // Nothing to do
                break; 
            }
            case RewardType.CANNON_DMG: {
                // Nothing to do
                break; 
            }
            case RewardType.BALLISTA_COSTS: {
                BattleScene.currentInstance.gui.turretButtonsContainer.updatePriceText();
                break; 
            }
            case RewardType.TORCH_COSTS: {
                BattleScene.currentInstance.gui.turretButtonsContainer.updatePriceText();
                break;
            }
            case RewardType.CANNON_COSTS: {
                BattleScene.currentInstance.gui.turretButtonsContainer.updatePriceText();
                break;  
            }
            case RewardType.TIME_WARP_COSTS: {
                BattleScene.currentInstance.gui.turretButtonsContainer.updatePriceText();
                break; 
            }
            case RewardType.TIME_WARP_SPEED: {
                BattleScene.currentInstance.gui.turretButtonsContainer.updatePriceText();
                break;
            }
            default:
                break;
        }
    }
}
