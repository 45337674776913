import { GameConstants } from "./../../GameConstants";
import { GameVars } from "../../GameVars";
import { GameManager } from "../../GameManager";

export class SplashMapPreview extends Phaser.GameObjects.Container {

    private boardMatrix: number[][];
    private renderTexture: Phaser.GameObjects.RenderTexture;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.setScale(.4);

        this.y = 85;

        this.boardMatrix = new Array(GameVars.currentMapData.size.r);

        for (let i = 0; i < this.boardMatrix.length; i++) {
            this.boardMatrix[i] = new Array(GameVars.currentMapData.size.c).fill(2); 
        }

        for (let i = 0; i < GameVars.plateausCells.length; i++) {
            this.boardMatrix[GameVars.plateausCells[i].r][GameVars.plateausCells[i].c] = 0;
        }

        for (let i = 0; i < GameVars.enemiesPathCells.length; i++) {

            if (GameVars.enemiesPathCells[i].c < 0 || GameVars.enemiesPathCells[i].r < 0 || GameVars.enemiesPathCells[i].c >= GameVars.currentMapData.size.c || GameVars.enemiesPathCells[i].r >= GameVars.currentMapData.size.r) {
                continue;
            }

            this.boardMatrix[GameVars.enemiesPathCells[i].r][GameVars.enemiesPathCells[i].c] = 1;
        }

        this.renderTexture = this.scene.add.renderTexture(- GameConstants.CELLS_SIZE * GameVars.currentMapData.size.c / 2, - GameConstants.CELLS_SIZE * GameVars.currentMapData.size.r / 2, GameConstants.CELLS_SIZE * GameVars.currentMapData.size.c, GameConstants.CELLS_SIZE * GameVars.currentMapData.size.r);
        this.add(this.renderTexture);

        let cell_grass_1 = "cell_grass_1";
        let cell_grass_2 = "cell_grass_2";

        if (GameVars.isHalloween) {
            cell_grass_1 = "halloween_cell_grass_1";
            cell_grass_2 = "halloween_cell_grass_2";
        } else if (GameVars.isXmas) {
            cell_grass_1 = "cell_grass_1";
            cell_grass_2 = "cell_grass_2";
        }

        let cellDirt = "cell_dirt";
        if (GameVars.isHalloween) {
            cellDirt = "halloween_cell_dirt";
        } else if (GameVars.isXmas) {
            cellDirt = "cell_dirt";
        }

        for (let i = 0; i < this.boardMatrix.length; i++) {

            for (let j = 0; j < this.boardMatrix[i].length; j++) {

                if (this.boardMatrix[i][j] === 1) {
                    let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", cellDirt);
                    this.renderTexture.draw(img, GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);

                    this.addPathShadows(i, j);
                } else {
                    let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", ((i % 2 === 0 && j % 2 === 0) || (i % 2 === 1 && j % 2 === 1)) ? cell_grass_1 : cell_grass_2);
                    this.renderTexture.draw(img, GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
                }
            }
        }

        let waterCell = "cell_water_01";
        if (GameVars.isHalloween) {
            waterCell = "halloween_cell_water_01";
        } else if (GameVars.isXmas) {
            waterCell = "cell_water_01";
        }

        for (let i = 0; i < GameVars.obstaclesBoard.length; i++) {
            for (let j = 0; j < GameVars.obstaclesBoard[0].length; j++) {

                let c = j - 1;
                let r = i - 8;

                if (GameVars.obstaclesBoard[i][j] === 10) {
                    let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", waterCell);
                    this.renderTexture.draw(img, GameConstants.CELLS_SIZE * c + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * r + GameConstants.CELLS_SIZE / 2);
                } else if (GameVars.obstaclesBoard[i][j] !== 0) {
                    let img = GameManager.generateObstacle(this.scene, GameConstants.CELLS_SIZE * c + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * r + GameConstants.CELLS_SIZE, GameVars.obstaclesBoard[i][j]);
                    img.setOrigin(.5, 1);
                    this.renderTexture.draw(img);
                }
            }
        }

        const framework = new Phaser.GameObjects.Graphics(this.scene);
        framework.lineStyle(5, 0x27274e);
        framework.strokeRect(- GameConstants.CELLS_SIZE * GameVars.currentMapData.size.c / 2, - GameConstants.CELLS_SIZE * GameVars.currentMapData.size.r / 2, GameConstants.CELLS_SIZE * GameVars.currentMapData.size.c, GameConstants.CELLS_SIZE * GameVars.currentMapData.size.r + 3);
        this.add(framework);

        const gradientImg = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "gradient");
        gradientImg.setOrigin(.5, 1);
        gradientImg.y = GameConstants.CELLS_SIZE * GameVars.currentMapData.size.r / 2;
        gradientImg.scaleX = GameConstants.CELLS_SIZE * GameVars.currentMapData.size.c / gradientImg.width;
        gradientImg.scaleY = 4;
        gradientImg.alpha = .8;
        this.add(gradientImg);
    }

    private addPathShadows(i: number, j: number): void {

        // SIDE
        if (j === 0 || this.boardMatrix[i][j - 1] !== 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_side");
            this.renderTexture.draw(img,  GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        if (i === 0 || this.boardMatrix[i - 1][j] !== 1) {

            let name = i === 0 || GameVars.obstaclesBoard[i - 1 + 8][j + 1] !== 10 ? "shadow_side" : "light_side_1";

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", name);
            img.setAngle(90);
            this.renderTexture.draw(img, GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        if (j === this.boardMatrix[0].length - 1 || this.boardMatrix[i][j + 1] !== 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_side");
            img.setAngle(180);
            this.renderTexture.draw(img, GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        if (i === this.boardMatrix.length - 1 || this.boardMatrix[i + 1][j] !== 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_side");
            img.setAngle(270);
            this.renderTexture.draw(img, GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        // CORNER

        if (j !== 0 && i !== this.boardMatrix.length - 1 && this.boardMatrix[i + 1][j - 1] !== 1 && this.boardMatrix[i][j - 1] === 1 && this.boardMatrix[i + 1][j] === 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_corner");
            this.renderTexture.draw(img, GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        if (j !== 0 && i !== 0 && this.boardMatrix[i - 1][j - 1] !== 1 && this.boardMatrix[i][j - 1] === 1 && this.boardMatrix[i - 1][j] === 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_corner");
            img.setAngle(90);
            this.renderTexture.draw(img, GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        if (j !== this.boardMatrix[0].length - 1 && i !== 0 && this.boardMatrix[i - 1][j + 1] !== 1 && this.boardMatrix[i][j + 1] === 1 && this.boardMatrix[i - 1][j] === 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_corner");
            img.setAngle(180);
            this.renderTexture.draw(img, GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }

        if (j !== this.boardMatrix[0].length - 1 && i !== this.boardMatrix.length - 1 && this.boardMatrix[i + 1][j + 1] !== 1 && this.boardMatrix[i][j + 1] === 1 && this.boardMatrix[i + 1][j] === 1) {

            let img = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shadow_corner");
            img.setAngle(270);
            this.renderTexture.draw(img, GameConstants.CELLS_SIZE * j + GameConstants.CELLS_SIZE / 2, GameConstants.CELLS_SIZE * i + GameConstants.CELLS_SIZE / 2);
        }
    }
}
