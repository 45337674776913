import { BattleScene } from "./scenes/battle-scene/BattleScene";
import { SplashScene } from "./scenes/splash-scene/SplashScene";
import { GameConstants } from "./GameConstants";
import { GameVars } from "./GameVars";
import { AudioManager } from "./AudioManager";
import mapsData from "../assets/config/maps.json";
import * as seedrandom from "seedrandom";
import { BoardContainer } from "./scenes/battle-scene/board-container/BoardContainer";

export class GameManager {

    public static init(): void {

        if (GameVars.currentScene.game.device.os.desktop) {
            window.addEventListener("resize", () => {
                GameVars.currentScene.scale.refresh();
            }, false);
        }

        GameVars.mapsData = mapsData;

        if (GameVars.currentScene.sys.game.device.os.desktop) {
            GameVars.scaleY = 1;
            GameVars.scaleCorrectionFactor = 1;
        } else {

            GameVars.currentScene.game.scale.displaySize = GameVars.currentScene.game.scale.parentSize;
            GameVars.currentScene.game.scale.refresh();

            const aspectRatio = window.innerHeight / window.innerWidth;
            GameVars.scaleY = (GameConstants.GAME_HEIGHT / GameConstants.GAME_WIDTH) / aspectRatio;

            if (aspectRatio > 1.5) {
                GameVars.scaleCorrectionFactor = 1.2;
            } else if (aspectRatio < 1.33) {
                GameVars.scaleCorrectionFactor = .8;
            } else {
                GameVars.scaleCorrectionFactor = 1;
            }
        }

        const date = new Date();
        const seed = GameManager.hashCode(date.getUTCDay() + "/" + date.getUTCMonth() + "/" + date.getUTCFullYear());

        let month = date.getMonth() + 1;
        let day = date.getUTCDate();
        let dayWeekNumber = date.getDay();

        GameVars.isHalloween = (month === 10 && day >= 24) // halloween days
        || (month === 11 && day === 1 && (dayWeekNumber === 6 || dayWeekNumber === 0)) // day after halloween is saturday or sunday
        || (month === 11 && day === 2 && dayWeekNumber === 0); // 2nd day after halloween is sunday so halloween day was friday
        GameVars.isXmas = month === 12 && day >= 15;

        GameVars.isXmas = false;

        GameVars.seedRandom = seedrandom.default(seed);

        GameManager.readGameData();
    }
    
    public static resize(): void {

        GameVars.currentScene.time.delayedCall(250, function(): void {
            const canvas = document.querySelector("canvas");
            
            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;

            canvas.style.width = windowWidth + "px";
            canvas.style.height = windowHeight + "px";

            GameVars.currentScene.scale.refresh();
        }, [], this);
    }

    public static readGameData(): void {

        GameManager.getGameStorageData(
            GameConstants.SAVED_GAME_DATA_KEY,
            function (gameData: string): void {

                if (gameData) {
                    GameVars.gameData = JSON.parse(gameData);
                } else {
                    GameVars.gameData = {
                        soundMuted: false,
                        musicMuted: false,
                        language: GameConstants.LANGUAGE_ENGLISH,
                        highScore: 0,
                        tutorialSeen: false
                    };
                }

                GameManager.startGame(new Date().getUTCDate() - 1);
            }
        );
    }

    public static setCurrentScene(scene: Phaser.Scene): void {

        GameVars.currentScene = scene;
    }

    public static onGameAssetsLoaded(): void {

        AudioManager.init();

        GameVars.gameText = GameVars.currentScene.cache.json.get("game-text");

        GameManager.enterSplashScene();
        // GameManager.enterGraphicScene();
    }

    public static enterGraphicScene(): void {

        GameVars.currentScene.scene.start("GraphicsScene");
    }

    public static enterAnnouncementScene(): void {

        GameVars.currentScene.scene.start("AnnouncementScene");
    }

    public static enterSplashScene(): void {

        GameVars.currentScene.scene.start("SplashScene");
    }

    public static enterBattleScene(): void {

        GameVars.currentScene.scene.start("BattleScene");
    }

    public static reset(): void {

        if (GameVars.gameOver) {
            GameManager.enterSplashScene();
        } else {
            GameManager.enterBattleScene();
        }
    }

    public static writeGameData(): void {

        GameManager.setGameStorageData(
            GameConstants.SAVED_GAME_DATA_KEY,
            GameVars.gameData,
            function (): void {
                GameManager.log("game data successfully saved");
            }
        );
    }

    public static log(text: string, error?: Error): void {

        if (!GameConstants.VERBOSE) {
            return;
        }

        if (error) {
            console.error(text + ":", error);
        } else {
            console.log(text);
        }
    }

    public static getTimeUntilEndOfDay(): number {

        const d = new Date();
        const h = d.getUTCHours();
        const m = d.getUTCMinutes();
        const s = d.getUTCSeconds();

        return (24 * 60 * 60) - (h * 60 * 60) - (m * 60) - s;
    }

    public static generateObstaclesBoard(): void {

        GameVars.obstaclesBoard = [];

        for (let i = 0; i < 22; i++) {

            GameVars.obstaclesBoard[i] = [];
            for (let j = 0; j < 14; j++) {
                GameVars.obstaclesBoard[i][j] = 0;
            }
        }

        let rand = (Math.floor(GameVars.seedRandom() * 5) + 1);
        let prevRand = rand;

        let obstaclesAmount = (GameVars.isXmas || GameVars.isHalloween) ? 0.6 : 0.2;
        let obstaclesNumber = (GameVars.isXmas || GameVars.isHalloween) ? 8 : 4;

        for (let i = 0; i < 22; i++) {
            for (let j = 0; j < 14; j++) {

                let c = j - 1;
                let r = i - 8;

                if (GameVars.seedRandom() < obstaclesAmount) {
                    rand = (Math.floor(GameVars.seedRandom() * obstaclesNumber) + 6);
                }

                if (r < 0 || c === -1 || c === 12) {

                    if (GameVars.enemiesPathCells[0].r === -1) {
                        if ((c > GameVars.enemiesPathCells[0].c + 1 || c < GameVars.enemiesPathCells[0].c - 1) || (r > GameVars.enemiesPathCells[0].r || r < GameVars.enemiesPathCells[0].r - 1)) {
                            GameVars.obstaclesBoard[i][j] = rand;
                        }
                    } else {
                        if (GameVars.enemiesPathCells[0].r + 1 !== r || GameVars.enemiesPathCells[0].c !== c) {
                            GameVars.obstaclesBoard[i][j] = rand;
                        }
                    }
                }

                rand = prevRand;
            }
        }

        for (let i = 0; i < GameVars.plateausCells.length; i++) {

            if (GameVars.seedRandom() < .1) {
                rand = (Math.floor(GameVars.seedRandom() * 4) + 6);
            }

            GameVars.obstaclesBoard[GameVars.plateausCells[i].r + 8][GameVars.plateausCells[i].c + 1] = rand;

            rand = prevRand;
        }

        if (GameVars.waterCells) {
            for (let i = 0; i < GameVars.waterCells.length; i++) {
                GameVars.obstaclesBoard[GameVars.waterCells[i].r + 8][GameVars.waterCells[i].c + 1] = 10;
            }
        }
    }

    public static generateObstacle(scene: Phaser.Scene, x: number, y: number, obstacleNumber: number): Phaser.GameObjects.Image {

        let img: Phaser.GameObjects.Image;
        if (GameVars.isHalloween) {
            img = new Phaser.GameObjects.Image(scene, x, y, "texture_atlas_1", "halloween_obstacle_" + obstacleNumber);
        } else if (GameVars.isXmas) {
            img = new Phaser.GameObjects.Image(scene, x, y, "texture_atlas_1", "obstacle_0" + obstacleNumber);
        } else {
            img = new Phaser.GameObjects.Image(scene, x, y, "texture_atlas_1", "obstacle_0" + obstacleNumber);                    
        }
        return img;
    }

    private static startGame(mapIndex: number): void {

        GameVars.currentMapId = mapIndex;

        GameVars.currentMapData = GameVars.mapsData[mapIndex];
        GameVars.enemiesPathCells = GameVars.currentMapData.path;
        GameVars.plateausCells = GameVars.currentMapData.plateaus;
        GameVars.waterCells = GameVars.currentMapData.water;

        GameManager.generateObstaclesBoard();

        GameVars.currentScene.scene.start("PreloadScene");
    }

    private static getGameStorageData(key: string, successCb: Function): void {

        const gameDataStr = localStorage.getItem(key);
        successCb(gameDataStr);
    }

    private static setGameStorageData(key: string, value: any, successCb: Function): void {

        localStorage.setItem(key, JSON.stringify(value));
        successCb();
    }

    private static hashCode(s: string): string {
        
        return s.split("").reduce(function(a, b) {a = ((a << 5) - a) + b.charCodeAt(0); return a & a; }, 0).toString();              
    }
}
