
import { EnemyActor } from "./EnemyActor";
import * as Creepts from "endless-siege-engine";

export class HealerEnemyActor extends EnemyActor {

    private healFx: Phaser.GameObjects.Sprite;

    constructor(scene: Phaser.Scene) {

        super(scene);
    }

    public update(time: number, delta: number): void {

        super.update(time, delta);

        const enemy = <Creepts.HealerEnemy> this.creeptsEnemy;

        if (enemy.healing && !this.healFx) {
            
            this.healFx = this.scene.add.sprite(0, 0, "texture_atlas_3");
            this.add(this.healFx);

            this.healFx.anims.play("heal");

            this.healFx.on("animationcomplete", () => {
                this.healFx.destroy();
                this.healFx = null;
            }, this);
        } 
    }
}
