import { TurretSelected } from "./TurretSelected";
import { TurretButtonsContainer } from "./TurretButtonsContainer";
import { BattleManager } from "../BattleManager";
import { GameVars } from "../../../GameVars";
import { GameConstants } from "../../../GameConstants";
import { AudioManager } from "../../../AudioManager";
import { TutorialManager } from "../TutorialManager";
import { BattleScene } from "../BattleScene";
import { TutorialLayer } from "../TutorialLayer";

export class GUI extends Phaser.GameObjects.Container {

    public turretButtonsContainer: TurretButtonsContainer;

    private rewardChest: Phaser.GameObjects.Sprite;
    private menuButton: Phaser.GameObjects.Image;
    private timeStepButton: Phaser.GameObjects.Image;
    private timeStepImage: Phaser.GameObjects.Image;
    private pauseButton: Phaser.GameObjects.Image;
    private pauseImage: Phaser.GameObjects.Image;
    private nextWaveButton: Phaser.GameObjects.Image;
    private nextWaveText: Phaser.GameObjects.Text;
    private autoButton: Phaser.GameObjects.Image;
    private autoImage: Phaser.GameObjects.Image;
    private turretSelected: TurretSelected;
    private topContainer: Phaser.GameObjects.Container;
    private bottomContainer: Phaser.GameObjects.Container;
    private handCursor: Phaser.GameObjects.Image;
    private pauseButtonTween: Phaser.Tweens.Tween;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.handCursor = null;
        this.pauseButtonTween = null;

        this.topContainer = new Phaser.GameObjects.Container(this.scene);
        this.topContainer.scaleY = GameVars.scaleY;
        this.add(this.topContainer);

        this.bottomContainer = new Phaser.GameObjects.Container(this.scene);
        this.bottomContainer.y = GameConstants.GAME_HEIGHT - 20;
        this.bottomContainer.scaleY = GameVars.scaleY;
        this.add(this.bottomContainer);

        this.menuButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_menu");
        this.menuButton.setInteractive({ useHandCursor: true });
        this.menuButton.setOrigin(0);
        this.menuButton.on("pointerdown", this.onClickMenu, this);
        this.menuButton.on("pointerover", () => { 
            if (GameVars.paused || GameVars.gameOver) {
                return;
            }
            this.onBtnOver(this.menuButton); 
        });
        this.menuButton.on("pointerout", () => { 
            if (GameVars.paused || GameVars.gameOver) {
                return;
            }
            this.onBtnOut(this.menuButton); 
        });
        this.topContainer.add(this.menuButton);

        const prevPath = GameVars.currentMapData.path[0];
        const path = GameVars.currentMapData.path[1];
        
        let x = GameConstants.GAME_WIDTH / 2;
        let y = (GameConstants.GAME_HEIGHT / 2 - GameConstants.CELLS_SIZE / 2) / GameVars.scaleY;

        x -= (GameConstants.CELLS_SIZE * GameVars.currentMapData.size.c / 2) * GameVars.scaleCorrectionFactor;
        y -= (GameConstants.CELLS_SIZE * GameVars.currentMapData.size.r / 2) * GameVars.scaleCorrectionFactor;

        x += (GameConstants.CELLS_SIZE * path.c + GameConstants.CELLS_SIZE / 2) * GameVars.scaleCorrectionFactor;
        y += (GameConstants.CELLS_SIZE * path.r + GameConstants.CELLS_SIZE / 2) * GameVars.scaleCorrectionFactor;

        this.nextWaveButton = new Phaser.GameObjects.Image(this.scene, x, y, "texture_atlas_1", prevPath.r === -1 ? "box_next_wave" : "box_next_wave_side");
        this.nextWaveButton.setInteractive({ useHandCursor: true });
        this.nextWaveButton.setScale(prevPath.r !== -1 && prevPath.c !== -1 ? -1 : 1, 1);
        this.nextWaveButton.on("pointerdown",  this.onClickNextWave, this);
        this.nextWaveButton.on("pointerover", () => { 
            if (GameVars.paused || GameVars.gameOver || !GameVars.gameData.tutorialSeen) {
                return;
            }
            this.nextWaveButton.setScale(prevPath.r !== -1 && prevPath.c !== -1 ? -1.1 : 1.1, 1.1);
            this.nextWaveText.setScale(1.1);
        }, this);
        this.nextWaveButton.on("pointerout", () => {
            if (GameVars.paused || GameVars.gameOver) {
                return;
            }
            this.nextWaveButton.setScale(prevPath.r !== -1 && prevPath.c !== -1 ? -1 : 1, 1);
            this.nextWaveText.setScale(1);
        }, this);
        this.topContainer.add(this.nextWaveButton);

        this.nextWaveText = new Phaser.GameObjects.Text(this.scene, x, y, GameVars.gameText[GameVars.gameData.language].NEXT_WAVE, {fontFamily: "Supercell", fontSize: "13px", color: "#FFFFFF", align: "center"});
        this.nextWaveText.setOrigin(.5);
        this.nextWaveText.setStroke("#000000", 4);
        this.nextWaveText.setShadow(3, 3, "#000000");
        this.nextWaveText.lineSpacing = -3;
        this.nextWaveText.setWordWrapWidth(50);
        this.topContainer.add(this.nextWaveText);

        if (prevPath.r === -1) {
            this.nextWaveButton.y += 10 * GameVars.scaleCorrectionFactor;
            this.nextWaveText.y += 14 * GameVars.scaleCorrectionFactor;
        } else if (prevPath.c === -1) {
            this.nextWaveButton.x += 18 * GameVars.scaleCorrectionFactor;
            this.nextWaveText.x += 26 * GameVars.scaleCorrectionFactor;
            this.nextWaveText.y -= 2;
        } else {
            this.nextWaveButton.x -= 18 * GameVars.scaleCorrectionFactor;
            this.nextWaveText.x -= 26 * GameVars.scaleCorrectionFactor;
            this.nextWaveText.y -= 2;
            this.nextWaveButton.scaleX = -1;
        }

        this.createNextWaveTween();

        this.pauseButton = new Phaser.GameObjects.Image(this.scene, GameConstants.GAME_WIDTH - 186, 0, "texture_atlas_1", "btn_wave");
        this.pauseButton.setOrigin(1);
        this.pauseButton.setInteractive({ useHandCursor: true });
        this.pauseButton.on("pointerdown", this.onClickPause, this);
        this.pauseButton.on("pointerover", () => { 
            if (GameVars.gameOver || GameVars.paused) {
                return;
            }
            this.pauseButton.setFrame("btn_wave_on"); 
        }, this);
        this.pauseButton.on("pointerout", () => { 

            if (GameVars.gameOver || GameVars.paused) {
                return;
            }

            this.pauseButton.setFrame("btn_wave"); 
        }, this);
        this.bottomContainer.add(this.pauseButton);

        this.pauseImage = new Phaser.GameObjects.Image(this.scene, GameConstants.GAME_WIDTH - 232, -35, "texture_atlas_1", "icon_waves_4");
        this.bottomContainer.add(this.pauseImage);

        this.timeStepButton = new Phaser.GameObjects.Image(this.scene, GameConstants.GAME_WIDTH - 93, 0, "texture_atlas_1", "btn_wave");
        this.timeStepButton.setOrigin(1);
        this.timeStepButton.setInteractive({ useHandCursor: true });
        this.timeStepButton.on("pointerdown", this.onClickTimeStep, this);
        this.timeStepButton.on("pointerover", () => { 
            if (GameVars.paused || GameVars.gameOver) {
                return;
            }
            this.timeStepButton.setFrame("btn_wave_on"); 
        });
        this.timeStepButton.on("pointerout", () => { 
            if (GameVars.paused || GameVars.gameOver) {
                return;
            }
            this.timeStepButton.setFrame("btn_wave"); 
        });
        this.bottomContainer.add(this.timeStepButton);

        this.timeStepImage = new Phaser.GameObjects.Image(this.scene, GameConstants.GAME_WIDTH - 139, -35, "texture_atlas_1", "icon_x1");
        this.bottomContainer.add(this.timeStepImage);

        this.autoButton = new Phaser.GameObjects.Image(this.scene, GameConstants.GAME_WIDTH, 0, "texture_atlas_1", "btn_wave");
        this.autoButton.setOrigin(1);
        this.autoButton.setInteractive({ useHandCursor: true });
        this.autoButton.on("pointerdown", () => { 
            if (GameVars.gameOver || GameVars.paused) {
                return;
            }
            this.onClickAuto(); 
        });
        this.autoButton.on("pointerover", () => { 

            if (GameVars.gameOver || GameVars.paused) {
                return;
            }
            this.autoButton.setFrame("btn_wave_on"); 
        });
        this.autoButton.on("pointerout", () => { 
            if (GameVars.gameOver || GameVars.paused) {
                return;
            }
            this.autoButton.setFrame("btn_wave"); 
        });
        this.bottomContainer.add(this.autoButton);

        this.autoImage = new Phaser.GameObjects.Image(this.scene, GameConstants.GAME_WIDTH - 47, -35, "texture_atlas_1", "icon_waves_6");
        this.bottomContainer.add(this.autoImage);

        if (GameVars.autoSendWave) {
            this.autoImage.setFrame("icon_waves_5");
        } else {
            this.autoImage.setFrame("icon_waves_6");
        }

        this.turretButtonsContainer = new TurretButtonsContainer(this.scene);
        this.bottomContainer.add(this.turretButtonsContainer);
    }

    public createTurret(type: string): void {

        this.turretSelected = new TurretSelected(this.scene, type, this);
        this.bottomContainer.add(this.turretSelected);
    }

    public removeTurret(): void {

        this.bottomContainer.remove(this.turretSelected);
        this.turretSelected = null;
    }

    public activeNextWave(): void {

        if (!GameVars.gameData.tutorialSeen && TutorialManager.hasFirstWaveBeenReleased) {
            return;
        }

        this.scene.tweens.add({
            targets: [this.nextWaveButton, this.nextWaveText],
            alpha: 1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 200
        });
    }

    public updateNextWaveButtonToGold(): void {
        setTimeout(() => {
            this.nextWaveButton.setFrame((this.nextWaveButton.frame.name === "box_next_wave") ? "box_next wave_gold" : "box_next_wave_side_gold");
        }, 200);
        const effect = new Phaser.GameObjects.Sprite(this.scene, this.nextWaveButton.x, this.nextWaveButton.y, "texture_atlas_1", "fx_upgrades_towers_cards_01");
        this.scene.add.existing(effect);
        this.topContainer.add(effect);
        effect.scale *= 2;
        effect.anims.play("gold_turret");
    }

    public onShowRewardWave(): void {

        this.rewardChest = new Phaser.GameObjects.Sprite(this.scene, this.nextWaveButton.x, this.nextWaveButton.y, "texture_atlas_1", "icon_chest_01");
        this.rewardChest.setScale(1.65);
        this.scene.add.existing(this.rewardChest);
        this.topContainer.add(this.rewardChest);

        this.rewardChest.anims.play("chest_loop");
    }

    public onHideRewardWave(): void {

        this.rewardChest.destroy();
    }

    public onClickNextWave(): void {

        if (this.nextWaveButton.alpha !== 1 || GameVars.paused || GameVars.gameOver) {
            return;
        }

        if (!TutorialManager.isFirstEnemyKilled && this.handCursor) {

            this.handCursor.destroy();
            this.handCursor = null;

            TutorialManager.onFirstWaveReleased();
        }
    
        this.nextWaveButton.alpha = 0;
        this.nextWaveText.alpha = 0;

        const bonus = BattleManager.newWave();

        if (bonus > 0) {

            const bonusLabelContainer = new Phaser.GameObjects.Container(this.scene);
            bonusLabelContainer.x = this.nextWaveButton.x - 20;
            bonusLabelContainer.y = this.nextWaveButton.y + 5;
            this.topContainer.add(bonusLabelContainer);

            const coinIcon = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "info_icon_gold");
            coinIcon.setScale(.6);
            bonusLabelContainer.add(coinIcon);

            const bonusLabel = new Phaser.GameObjects.Text(this.scene, coinIcon.x + 12, coinIcon.y, GameVars.formatNumber(bonus), {fontFamily: "Supercell", fontSize: "20px", color: "#FFE734"});
            bonusLabel.setOrigin(0, .5);
            bonusLabel.setStroke("#B35B20", 4);
            bonusLabel.setShadow(1, 1.5, "#700000", 0, true, false);
            bonusLabelContainer.add(bonusLabel);

            this.scene.tweens.add({
                targets: bonusLabelContainer,
                y: bonusLabelContainer.y - 15,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: 450,
                delay: 150,
                onComplete: function(): void {
                    this.scene.tweens.add({
                        targets: bonusLabelContainer,
                        alpha: 0,
                        ease: Phaser.Math.Easing.Cubic.Out,
                        duration: 250,
                        onComplete: function(): void {
                            bonusLabelContainer.destroy();
                        },
                        onCompleteScope: this
                    });
                },
                onCompleteScope: this
            });

            AudioManager.playSoundEffect("coins_emitted");

        } else {
            
            AudioManager.playSoundEffect("btn_click");
        }
    }

    public hideButtonsAtTutorialStart(): void {

       this.turretButtonsContainer.hideButtonsAtTutorialStart();

       this.nextWaveButton.visible = false;
       this.nextWaveText.visible = false;

       this.pauseImage.visible = false;
       this.timeStepImage.visible = false;
       this.autoImage.visible = false;
    }

    public showButtonsAfterTutorialCompletion(): void {
        
        this.timeStepImage.visible = true;

        this.handCursor = new Phaser.GameObjects.Image(this.scene, this.timeStepImage.x, this.timeStepImage.y - 50, "texture_atlas_1", "hand_cursor");
        this.handCursor.setOrigin(.2);
        this.handCursor.angle = -90;
        this.bottomContainer.add(this.handCursor);

        this.scene.tweens.add({
            targets: this.handCursor,
            y: this.handCursor.y - 25,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 750,
            yoyo: false,
            repeat: -1
        });
    }

    public showNextWaveButtonInTutorial(): void {

        this.nextWaveButton.visible = true;
        this.nextWaveText.visible = true;

        this.handCursor = new Phaser.GameObjects.Image(this.scene, this.nextWaveButton.x + 50, this.nextWaveButton.y, "texture_atlas_1", "hand_cursor");
        this.handCursor.setOrigin(.2);
        this.topContainer.add(this.handCursor);

        let px = this.handCursor.x + 25;

        if (this.nextWaveButton.scaleX < 0) {
            this.handCursor.x = this.nextWaveButton.x - 70;
            this.handCursor.scaleX = -1;

            px = this.handCursor.x - 25;
        }

        this.scene.tweens.add({
            targets: this.handCursor,
            x: px,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 750,
            yoyo: false,
            repeat: -1
        });
    }

    private onClickTimeStep(): void {

        if (this.timeStepButton.alpha !== 1 || GameVars.paused || GameVars.gameOver || (!GameVars.gameData.tutorialSeen && !TutorialManager.isSpeedControlShown)) {
            return;
        }

        if (GameVars.timeStepFactor === 1) {

            BattleManager.setTimeStepFactor(4);
            this.timeStepImage.setFrame("icon_x2");

            if (!GameVars.gameData.tutorialSeen && !TutorialManager.isTutoriallCompleted) {
                TutorialManager.onSpeedX2();
            }

        } else if (GameVars.timeStepFactor === 4) {

            BattleManager.setTimeStepFactor(8);
            this.timeStepImage.setFrame("icon_x3");

            if (!GameVars.gameData.tutorialSeen && !TutorialManager.isTutoriallCompleted) {
                TutorialManager.onSpeedX3();
            }

        } else {

            BattleManager.setTimeStepFactor(1);
            this.timeStepImage.setFrame("icon_x1");

            if (!GameVars.gameData.tutorialSeen && !TutorialManager.isTutoriallCompleted) {

                this.handCursor.destroy();
                this.handCursor = null;
                TutorialManager.onSpeedX1();
            }
        }
    }

    private onClickMenu(): void {

        if (GameVars.paused || GameVars.gameOver || !GameVars.gameData.tutorialSeen) {
            return;
        }

        BattleManager.onClickMenu();

        AudioManager.playSoundEffect("btn_click");
    }

    private createNextWaveTween(): void {
        let fast = GameVars.waveOver;
        let small = this.nextWaveButton.scaleY > 1;
        let factor = (this.nextWaveButton.scaleX < 0) ? -1 : 1;
        this.scene.tweens.add({
            targets: this.nextWaveButton,
            scaleX: ((small) ? 1 : (fast) ? 1.2 : 1.1) * factor,
            scaleY: (small) ? 1 : (fast) ? 1.2 : 1.1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: (fast) ? 250 : 600,
            onComplete: () => {
                this.createNextWaveTween();
            },
            onCompleteScope: this,
            yoyo: true,
            delay: (fast) ? 100 : 800
        });

        this.scene.tweens.add({
            targets: this.nextWaveText,
            scaleX: (small) ? 1 : (fast) ? 1.2 : 1.1,
            scaleY: (small) ? 1 : (fast) ? 1.2 : 1.1,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: (fast) ? 250 : 600,
            onCompleteScope: this,
            yoyo: true,
            delay: (fast) ? 100 : 800
        });
    }

    private onClickPause(): void {

        if (GameVars.paused || GameVars.gameOver || !GameVars.gameData.tutorialSeen) {
            return;
        }

        if (GameVars.semiPaused) {

            BattleManager.semiResume();
            this.pauseImage.setFrame("icon_waves_4");

            this.pauseButtonTween.remove();
            this.pauseImage.alpha = 1;

        } else {

            BattleManager.semiPause();
            this.pauseImage.setFrame("icon_waves_1");

            this.pauseButtonTween = this.scene.tweens.add({
                targets: this.pauseImage,
                alpha: .35,
                ease: Phaser.Math.Easing.Cubic.InOut,
                duration: 600,
                repeat: -1,
                yoyo: true
            });
        }

        AudioManager.playSoundEffect("btn_click");
    }

    private onClickAuto(): void {

        if (!GameVars.gameData.tutorialSeen) {
            return;
        }

        if (GameVars.autoSendWave) {
            this.autoImage.setFrame("icon_waves_6");
            BattleManager.setAutoSendWave(false);
        } else {
            this.autoImage.setFrame("icon_waves_5");
            BattleManager.setAutoSendWave(true);
        }

        AudioManager.playSoundEffect("btn_click");
    }

    private onBtnOver(btn: any): void {

        btn.setScale(1.1);
    }

    private onBtnOut(btn: any): void {
        
        btn.setScale(1);
    }
}
