import { GameConstants } from "../../GameConstants";
import { GameVars } from "../../GameVars";
import { AudioManager } from "../../AudioManager";
import { TutorialManager } from "./TutorialManager";

export class TutorialLayer extends Phaser.GameObjects.Container {

    private ribbon: Phaser.GameObjects.Image;
    private textRibbon: Phaser.GameObjects.Text;
    private tutorialText: Phaser.GameObjects.Text;
    private scaledItemsContainer: Phaser.GameObjects.Container;
    private skipButtonContainer: Phaser.GameObjects.Container;
    private knight: Phaser.GameObjects.Image;
    private menuBackgroundCenter;
    private menuBackgroundTop;
    private menuBackgroundBottom;

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.scaledItemsContainer = new Phaser.GameObjects.Container(this.scene);
        this.scaledItemsContainer.x = GameConstants.GAME_WIDTH / 2;
        this.scaledItemsContainer.y = GameConstants.GAME_HEIGHT / 2;
        this.scaledItemsContainer.scaleY = GameVars.scaleY;
        this.add(this.scaledItemsContainer);

        this.menuBackgroundCenter = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "layout_box_3");
        this.menuBackgroundCenter.setOrigin(.5);
        this.menuBackgroundCenter.setScale(1.25, 1.25 * 5);
        this.scaledItemsContainer.add(this.menuBackgroundCenter);

        this.menuBackgroundTop = new Phaser.GameObjects.Image(this.scene, 0, -this.menuBackgroundCenter.height * this.menuBackgroundCenter.scaleY * .5, "texture_atlas_1", "layout_box_4");
        this.menuBackgroundTop.setOrigin(.5, 1);
        this.menuBackgroundTop.setScale(1.25);
        this.menuBackgroundTop.y -= this.menuBackgroundTop.height * this.menuBackgroundTop.scaleY;
        this.menuBackgroundTop.angle = 180;
        this.scaledItemsContainer.add(this.menuBackgroundTop);

        this.menuBackgroundBottom = new Phaser.GameObjects.Image(this.scene, 0, this.menuBackgroundCenter.height * this.menuBackgroundCenter.scaleY * .5, "texture_atlas_1", "layout_box_4");
        this.menuBackgroundBottom.setOrigin(.5, 0);
        this.menuBackgroundBottom.setScale(1.25);
        this.scaledItemsContainer.add(this.menuBackgroundBottom);

        this.tutorialText = new Phaser.GameObjects.Text(this.scene, 0, -150, "", {fontFamily: "Supercell", fontSize: "20px", color: "#FFFFFF", align: "center"});
        this.tutorialText.setOrigin(.5);
        this.tutorialText.setStroke("#000000", 8);
        this.tutorialText.setShadow(3, 3, "#000000");
        this.scaledItemsContainer.add(this.tutorialText);

        this.ribbon = new Phaser.GameObjects.Image(this.scene, 0, -210, "texture_atlas_1", "title_area");
        this.ribbon.setScale(1.35, 1.15);
        this.scaledItemsContainer.add(this.ribbon);

        this.textRibbon = new Phaser.GameObjects.Text(this.scene, 0, -220, "", {fontFamily: "Supercell", fontSize: "24px", color: "#FFFFFF", align: "center"});
        this.textRibbon.setOrigin(.5);
        this.textRibbon.setStroke("#000000", 8);
        this.textRibbon.setShadow(3, 3, "#000000");
        this.scaledItemsContainer.add(this.textRibbon);

        this.knight = new Phaser.GameObjects.Image(this.scene, 120, 208, "texture_atlas_1", "tutorial_knight");
        this.knight.setOrigin(.5, 1);
        this.scaledItemsContainer.add(this.knight);

        this.alpha = 0;
    }

    public hide(): void {

        this.scene.tweens.add({
            targets: this,
            alpha: 0,
            duration: 400
        });
    }

    public show(text: string, textRibbon?: string): void {

        if (textRibbon) {
            this.ribbon.visible = true;
            this.textRibbon.text = textRibbon;
            this.tutorialText.y = -75;
        } else {
            this.ribbon.visible = false;
            this.textRibbon.visible = false;
            this.tutorialText.y = -80;
        }

        if (text.length < 36) {
            this.tutorialText.setFontSize(28);
        } else if (text.length < 56) {
            this.tutorialText.setFontSize(24);
        } else {
            this.tutorialText.setFontSize(20);
        }

        this.tutorialText.text = text;

        this.alpha = 0;

        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            duration: 400
        });

        if (this.skipButtonContainer) {
            this.skipButtonContainer.destroy();
        }

        this.knight.scaleX *= -1;
        this.knight.x *= -1;

        // EL BOTON DE SKIP
        if (!TutorialManager.isTurretPlaced) {

            this.skipButtonContainer = new Phaser.GameObjects.Container(this.scene, 130, 195);
            this.skipButtonContainer.visible = false;
            this.scaledItemsContainer.add(this.skipButtonContainer);

            this.scene.time.delayedCall(1500, function(): void {
                this.skipButtonContainer.visible = true;
            }, [], this);

            const playButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_play_void");
            playButton.setInteractive({ useHandCursor: true });
            playButton.on("pointerover", () => { 
                this.skipButtonContainer.setScale(1.025);   
            });
            playButton.on("pointerout", () => { 
                this.skipButtonContainer.setScale(1);  
            });
            playButton.on("pointerdown", () => {
                this.onPlayDown();    
            });
            this.skipButtonContainer.add(playButton);

            const playText = new Phaser.GameObjects.Text(this.scene, 0, 0, GameVars.gameText[GameVars.gameData.language].TUTORIAL_SKIP, {fontFamily: "Supercell", fontSize: "18px", color: "#FFFFFF", align: "center"});
            playText.setOrigin(.5);
            playText.setStroke("#663333", 8);
            playText.setShadow(5, 5, "#663333");
            this.skipButtonContainer.add(playText);
        }
    }

    public showFinalMenu(textRibbon: string): void {

        if (textRibbon.length < 16) {
            this.textRibbon.setFontSize(30);
        }

        this.ribbon.visible = true;
        this.textRibbon.text = textRibbon;
        this.textRibbon.visible = true;
        this.tutorialText.visible = false;
        this.knight.visible = false;

        // resize menu background
        this.menuBackgroundCenter.setScale(1.25, 2.55);
        this.menuBackgroundCenter.setOrigin(0.5, 1.35);
        this.menuBackgroundBottom.setOrigin(0.5, 3.9);
        
        // AÑADIR UN BOTON DE OK
        const playButtonContainer = new Phaser.GameObjects.Container(this.scene, 0, -80);
        this.scaledItemsContainer.add(playButtonContainer);
        
        this.scene.tweens.add({
            targets: playButtonContainer,
            scaleX: 1.035,
            scaleY: 1.035,
            ease: Phaser.Math.Easing.Cubic.InOut,
            duration: 350,
            yoyo: true,
            repeat: -1
        });

        const playButton = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "btn_yellow_wide");
        playButton.setInteractive({ useHandCursor: true });
        playButton.on("pointerover", () => { 
            playButtonContainer.setScale(1.025);   
        });
        playButton.on("pointerout", () => { 
            playButtonContainer.setScale(1);  
        });
        playButton.on("pointerdown", () => {
            this.onPlayDown();    
        });
        playButtonContainer.add(playButton);

        const playText = new Phaser.GameObjects.Text(this.scene, 0, 0, GameVars.gameText[GameVars.gameData.language].PLAY, {fontFamily: "Supercell", fontSize: "22px", color: "#FFFFFF"});
        playText.setOrigin(.5);
        playText.setStroke("#663333", 6);
        playText.setShadow(3, 3, "#663333");
        playButtonContainer.add(playText);

        this.alpha = 0;

        this.scene.tweens.add({
            targets: this,
            alpha: 1,
            duration: 400
        });

        AudioManager.playSoundEffect("title_appears");
    }

    private onPlayDown(): void {

        TutorialManager.onTutorialOver();

        AudioManager.playSoundEffect("btn_click");
    }
}
