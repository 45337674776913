
import "howler";
import { GameVars } from "./GameVars";
import { GameManager } from "./GameManager";

// audiosprite --e "mp3,ogg" --o ../../assets/audio/sound *.mp3 --f howler -b 96
// audiosprite --e "mp3,ogg" --o ../../assets/audio/music *.mp3 --f howler -b 96
export class AudioManager {

    public static readonly GLOBAL_AUDIO_TEMPORARY_FACTOR = .5;

    public static sound: Howl;
    public static music: Howl;

    private static backgroundMusicKey: string;
    private static backgroundMusicID: number;

    public static init(): void {

        AudioManager.sound.mute(GameVars.gameData.soundMuted);
        AudioManager.music.mute(GameVars.gameData.musicMuted);

        AudioManager.backgroundMusicID = null;
        AudioManager.backgroundMusicKey = null;
    }

    public static toggleSoundState(): void {

        GameVars.gameData.soundMuted = !GameVars.gameData.soundMuted;
        GameManager.writeGameData();
        AudioManager.sound.mute(GameVars.gameData.soundMuted);
    }

    public static toggleMusicState(): void {

        GameVars.gameData.musicMuted = !GameVars.gameData.musicMuted;
        GameManager.writeGameData();
        AudioManager.music.mute(GameVars.gameData.musicMuted);
    }

    public static playSoundEffect(key: string, loop?: boolean, volume?: number, rate?: number): void {

        loop = loop || false;
       
        if (volume) {
            volume *= AudioManager.GLOBAL_AUDIO_TEMPORARY_FACTOR;
        } else {
            volume = AudioManager.GLOBAL_AUDIO_TEMPORARY_FACTOR;
        }

        rate = rate || 1;

        let id = AudioManager.sound.play(key);
        AudioManager.sound.loop(loop, id);
        AudioManager.sound.rate(rate, id);
        AudioManager.sound.volume(volume, id);
    }

    public static playMusic(key: string, rate?: number, volume?: number): void {

        if (AudioManager.backgroundMusicKey === key) {
            return;
        }

        if (AudioManager.backgroundMusicID) {
            AudioManager.stopMusic();
        }

        if (volume) {
            volume *= AudioManager.GLOBAL_AUDIO_TEMPORARY_FACTOR;
        } else {
            volume = AudioManager.GLOBAL_AUDIO_TEMPORARY_FACTOR;
        }

        AudioManager.backgroundMusicKey = key;
   
        AudioManager.backgroundMusicID = AudioManager.music.play(key);
        AudioManager.music.fade(0, volume, 1000, AudioManager.backgroundMusicID);
        AudioManager.music.loop(true, AudioManager.backgroundMusicID);
    }

    public static stopMusic(): void {

        AudioManager.music.fade(AudioManager.music.volume(AudioManager.backgroundMusicID) as number, 0, 1000, AudioManager.backgroundMusicID);
    }

    public static setMusicRate(rate: number): void {

        AudioManager.music.rate(rate, AudioManager.backgroundMusicID);
    }

    public static setMusicVolume(volume: number): void {

        AudioManager.music.volume(volume, AudioManager.backgroundMusicID);
    }
}
