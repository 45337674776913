
import { TurretActor } from "./TurretActor";
import { AudioManager } from "../../../AudioManager";
import { GameVars } from "../../../GameVars";
import * as Creepts from "endless-siege-engine";
import { TurretType } from "endless-siege-engine/build/main/lib/Types";
import { BattleScene } from "../BattleScene";

export class GlueTurretActor extends TurretActor {

    constructor(scene: Phaser.Scene, position: {r: number, c: number}, turret: Creepts.Turret) {

        super(scene, TurretType.GLUE, position, turret);

        this.base = new Phaser.GameObjects.Image(this.scene, 0, -25, "texture_atlas_1", GameVars.timeWarpUpgraded ? "crystal_upgraded_1_base" : "crystal_1_base");

        this.base.setInteractive({ useHandCursor: true });
        this.base.on("pointerdown", this.onDownTurret, this);
        this.addAt(this.base, 0);

        this.canon = this.scene.add.sprite(0, -25, "texture_atlas_1", "crystal_1_top_01");
        this.canon.anims.play("crystal_top");
        this.add(this.canon);
    }

    public upgrade(): void {

        super.upgrade();

        switch (this.turret.grade) {

            case 2:
                this.canon.anims.stop();
                this.base.setFrame(GameVars.timeWarpUpgraded ? "crystal_upgraded_2_base" : "crystal_2_base");
                this.canon.setFrame("crystal_2_top_01");

                break;
            case 3: 
            this.base.setFrame(GameVars.timeWarpUpgraded ? "crystal_upgraded_3_base" : "crystal_3_base");
            this.canon.setFrame("crystal_3_top_01");
                
                break;
            default:
       }
    }

    public shootGlue(): void {

        if (this.turret.grade === 2) {
            AudioManager.playSoundEffect("t3_pegamento");

            this.scene.tweens.add({
                targets: this.canon,
                y: -15,
                ease: Phaser.Math.Easing.Cubic.Out,
                duration: GameVars.timeStepFactor === 1 ? 100 : 25,
                onComplete: () => {
                    
                    if (!this.scene) {
                        return;
                    }
                    
                    this.scene.tweens.add({
                        targets: this.canon,
                        y: -25,
                        ease: Phaser.Math.Easing.Cubic.Out,
                        duration: GameVars.timeStepFactor === 1 ? 100 : 25
                    });
                },
                onCompleteScope: this
            });
        } 
    }
}
