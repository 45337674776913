
import { TurretActor } from "./TurretActor";
import { GameVars } from "../../../GameVars";
import * as Creepts from "endless-siege-engine";
import { TurretType } from "endless-siege-engine/build/main/lib/Types";
import { BattleScene } from "../BattleScene";

export class LaunchTurretActor extends TurretActor {

    private shine: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene, position: {r: number, c: number}, turret: Creepts.Turret) {

        super(scene, TurretType.LAUNCH, position, turret);

        this.base = new Phaser.GameObjects.Image(this.scene, 0, -20, "texture_atlas_1", GameVars.cannonUpgraded ? "cannon_upgraded_1_base" : "cannon_1_base");
        this.base.setInteractive({ useHandCursor: true });
        this.base.on("pointerdown", this.onDownTurret, this);
        this.addAt(this.base, 0);

        this.canonInitialY = -30;

        this.canon = new Phaser.GameObjects.Sprite(this.scene, 0, this.canonInitialY, "texture_atlas_1", "cannon_1_top_" + this.canonAngle);
        this.add(this.canon);

        this.canon.setFrame("cannon_" + this.turret.grade + "_top_" + this.canonAngle);
    }

    public upgrade(): void {

        super.upgrade();

        switch (this.turret.grade) {
 
            case 2:
                this.base.setFrame(GameVars.cannonUpgraded ? "cannon_upgraded_2_base" : "cannon_2_base");
                this.canon.visible = false;
                this.base.y = 30;
                this.base.setOrigin(.5, .95);
                break;
            case 3: 
                this.base.setFrame(GameVars.cannonUpgraded ? "cannon_upgraded_3_base" : "cannon_3_base");
                this.canon.setFrame("cannon_3_top_" + this.canonAngle);
                this.canon.visible = true;
                this.base.y = -25;
                this.base.setOrigin(.5);
                break;
            default:
        }
    }

    public setCanonRotation(dx: number, dy: number): void {

        super.setCanonRotation(dx, dy);

        if (this.turret.grade !== 2) {
            this.canon.setFrame("cannon_" + this.turret.grade + "_top_" + this.canonAngle);

            if (this.shine) {
                this.shine.setPosition(25 * Math.cos(this.canonPrevRotation), 25 * Math.sin(this.canonPrevRotation) + this.canonInitialY);
            }
        }
    }

    public shootMortar(): void {

        if (this.shine) {
            return;
        }

        this.shine = new Phaser.GameObjects.Image(this.scene, 25 * Math.cos(this.canonPrevRotation), 25 * Math.sin(this.canonPrevRotation) + this.canonInitialY, "texture_atlas_1", "cannon_shine"); 
        this.add(this.shine);

        this.scene.tweens.add({
            targets: this.shine,
            scaleX: 2,
            scaleY: 2,
            ease: Phaser.Math.Easing.Cubic.In,
            duration: 20,
            onComplete: () => {
                this.scene.tweens.add({
                    targets: this.shine,
                    scaleX: 4,
                    scaleY: 4,
                    alpha: 0,
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: 60,
                    onComplete: () => {
                        this.shine.destroy();
                        this.shine = null;
                    }, 
                    onCompleteScope: this
                });
            }, 
            onCompleteScope: this
        });

        this.scene.tweens.add({
            targets: this.canon,
            x: - 5 * Math.cos(this.canonPrevRotation),
            y: - 5 * Math.sin(this.canonPrevRotation) + this.canonInitialY,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: GameVars.timeStepFactor === 1 ? 40 : 10,
            onComplete: () => {
                
                if (!this.scene) {
                    return;
                }
                
                this.scene.tweens.add({
                    targets: this.canon,
                    x: 0,
                    y: this.canonInitialY,
                    ease: Phaser.Math.Easing.Cubic.Out,
                    duration: GameVars.timeStepFactor === 1 ? 40 : 10
                });
            },
            onCompleteScope: this
        });
    }

    public shootMine(): void {
        
        if (this.shine) {
            return;
        }

        this.scaleY = .75;

        this.scene.tweens.add({
            targets: this,
            scaleY: .9,
            ease: Phaser.Math.Easing.Cubic.Out,
            duration: 350
        });
        
        this.base.setFrame(GameVars.cannonUpgraded ? "cannon_upgraded_2_fire" : "cannon_2_fire");

        this.scene.time.delayedCall(GameVars.timeStepFactor === 1 ? 100 : 25, function(): void {
            this.base.setFrame(GameVars.cannonUpgraded ? "cannon_upgraded_" + this.turret.grade + "_base" : "cannon_" + this.turret.grade + "_base");
        }, [], this);
    }
}
