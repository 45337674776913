import { BattleManager } from "./../BattleManager";
import { GameConstants } from "./../../../GameConstants";
import { GUI } from "./GUI";
import { GameVars } from "../../../GameVars";
import * as Creepts from "endless-siege-engine";
import { GameManager } from "../../../GameManager";
import { TurretType } from "endless-siege-engine/build/main/lib/Types";
import { BattleScene } from "../BattleScene";

export class TurretSelected extends Phaser.GameObjects.Container {

    private base: Phaser.GameObjects.Image;
    private canon: Phaser.GameObjects.Image;

    private gui: GUI;

    private turretType: string;
    private offY = -65;
    private prevWorldY: number;

    constructor(scene: Phaser.Scene, type: string, gui: GUI) {

        super(scene);

        this.prevWorldY = 0;

        let base_name: string;
        let canon_name: string;

        this.turretType = type;
        this.gui = gui;

        let range = GameConstants.CELLS_SIZE;
        let canonY = -20;
        let canonR = 0;

        switch (type) {

            case TurretType.PROJECTILE:
                base_name = GameVars.ballistaUpgraded ? "archer_upgraded_1_base" : "archer_1_base";
                canon_name = "archer_1_top";
                canonY = -30;
                range *= 2.5 * BattleManager.engine.ballistaRangeFactor;
                canonR = Math.PI * (3 / 2);
                break;
            case TurretType.LASER:
                base_name = GameVars.torchUpgraded ? "fire_upgraded_1_base" : "fire_1_base";
                canon_name = "fire_01";
                canonY = -55;
                range *= 3.05 * BattleManager.engine.torchRangeFactor;
                break;
            case TurretType.LAUNCH:
                base_name = GameVars.cannonUpgraded ? "cannon_upgraded_1_base" : "cannon_1_base";
                canon_name = "cannon_1_top_19";
                canonY = -30;
                range *= 2.5 * BattleManager.engine.cannonRangeFactor;
                break;
            case TurretType.GLUE:
                base_name = GameVars.timeWarpUpgraded ? "crystal_upgraded_1_base" : "crystal_1_base";
                canon_name = "crystal_1_top_01";
                range *= 1.5 * BattleManager.engine.timeWarpRangeFactor;
                break;
            default:
        }

        this.base = new Phaser.GameObjects.Image(this.scene, 0, -20, "texture_atlas_1", base_name);
        this.add(this.base);

        if (canon_name) {
            this.canon = new Phaser.GameObjects.Image(this.scene, 0, canonY, "texture_atlas_1", canon_name);
            this.canon.rotation = canonR;
            this.add(this.canon);
        }

        const rangeCircle = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "shoot_range");
        rangeCircle.setScale((range * 2) / rangeCircle.width);
        this.add(rangeCircle);

        this.scene.sys.updateList.add(this);

        this.scene.input.on("pointerup", (pointer: Phaser.Input.Pointer) => { this.onPointerUp(pointer); }, this);
        this.scene.input.on("pointerdown", (pointer: Phaser.Input.Pointer) => { this.onPointerMove(pointer); }, this);
        this.scene.input.on("pointermove", (pointer: Phaser.Input.Pointer) => { this.onPointerMove(pointer); }, this);
        
        GameVars.turretSelectedOn = true;
    }

    public preUpdate(time: number, delta: number): void {

        if (!this.scene.input.activePointer.isDown && GameVars.turretSelectedOn) {
            this.onPointerUp(this.scene.input.activePointer);
        }
    }

    private onPointerMove(pointer: Phaser.Input.Pointer): void {

        this.setPosition(pointer.x, (pointer.y + this.offY - GameConstants.GAME_HEIGHT) / GameVars.scaleY);
        this.prevWorldY = pointer.worldY;

        var cells = this.convertPostitionToCells(pointer);

        BattleManager.setTurretPrePosition(cells.c, cells.r);
    }

    private onPointerUp(pointer: Phaser.Input.Pointer): void {

        BattleManager.addTurretToScene(this.turretType, this.convertPostitionToCells(pointer));
        BattleManager.clearAllAvailbalePositions();
        
        this.scene.input.removeAllListeners();
        this.gui.removeTurret();

        GameVars.turretSelectedOn = false;

        this.scene.input.on('pointerup', () => {
            var fullscreen = this.scene.sys.game.device.fullscreen;
            if (!this.scene.scale.isFullscreen && !this.scene.game.device.os.desktop && fullscreen.available) {
                this.scene.game.scale.startFullscreen();
                GameManager.resize();
            }
        }, this);
    }

    private convertPostitionToCells(pointer: Phaser.Input.Pointer) {
        const posX = (pointer.x - GameConstants.GAME_WIDTH / 2) / GameVars.scaleCorrectionFactor + ((GameVars.currentMapData.size.c * GameConstants.CELLS_SIZE) / 2);
        const posY = ((pointer.y + this.offY) - GameConstants.GAME_HEIGHT / 2 + GameConstants.CELLS_SIZE / 2) / (GameVars.scaleCorrectionFactor * GameVars.scaleY) + ((GameVars.currentMapData.size.r * GameConstants.CELLS_SIZE) / 2) - 20;

        const c = Math.floor(posX / GameConstants.CELLS_SIZE);
        const r = Math.floor(posY / GameConstants.CELLS_SIZE);

        return {c: c, r: r};
    }
}
