
import { TurretButton } from "./TurretButton";
import { TurretType } from "endless-siege-engine/build/main/lib/Types";

export class TurretButtonsContainer extends Phaser.GameObjects.Container {

    public turretButtons: TurretButton [];

    constructor(scene: Phaser.Scene) {

        super(scene);

        this.y = -60;
        this.x = 80;

        this.turretButtons = [];

        const types = [TurretType.PROJECTILE, TurretType.LASER, TurretType.LAUNCH, TurretType.GLUE];

        for (let i = 0; i < 4; i++) {

            const turretButton = new TurretButton(this.scene, types[i], i);
            this.add(turretButton);

            this.turretButtons.push(turretButton);
        }
    }

    public updatePriceText(): void {
        for (let i = 0; i < 4; ++i) {
            this.turretButtons[i].updateTextPrice();
        }
    }

    public hideButtonsAtTutorialStart(): void {

        for (let i = 0; i < this.turretButtons.length; i ++) {
            
            if (this.turretButtons[i].name === TurretType.PROJECTILE) {
                this.turretButtons[i].showHandCursor();
            } else {
                this.turretButtons[i].hide();
            }
        }
    }
}
