import { EnemyActor } from "./EnemyActor";
import { HealerEnemyActor } from "./HealerEnemyActor";
import * as Creepts from "endless-siege-engine";
import { EnemyType } from "endless-siege-engine/build/main/lib/Types";

export class EnemyActorsPool {

    private enemyActors: EnemyActor[];
    private healerEnemyActors: HealerEnemyActor[];
    private scene: Phaser.Scene;
    private increasePoolDefault: number;
    private increasePoolHealers: number;

    constructor(scene: Phaser.Scene, numDefaultEnemies: number, numHealerEnemies: number, numIncreaseDefault: number, numIncreaseHealers: number) {
        
        this.scene = scene;

        this.scene = scene;
        this.enemyActors = [];
        this.healerEnemyActors = [];

        this.increasePoolDefault = numIncreaseDefault;
        this.increasePoolHealers = numIncreaseHealers;

        for (let i = 0; i < numDefaultEnemies; ++i) {
            this.enemyActors.push(new EnemyActor(this.scene));
        }

        for (let i = 0; i < numHealerEnemies; ++i) {
            this.healerEnemyActors.push(new HealerEnemyActor(this.scene));
        }
    }

    public getEnemy(creeptsEnemy: Creepts.Enemy, position: {r: number, c: number}): EnemyActor {

        let enemyActor: EnemyActor;

        switch (creeptsEnemy.type) {
            case EnemyType.HEALER:
                if (this.healerEnemyActors.length === 0) {
                    this.increaseHealerActorsPoolSize();
                }
        
                const healerEnemyActor = this.healerEnemyActors.pop();
                healerEnemyActor.reset(creeptsEnemy, position);
                enemyActor = <EnemyActor> healerEnemyActor;

                break;
            default:
                if (this.enemyActors.length === 0) {
                    this.increaseEnemyActorsPoolSize();
                }
        
                enemyActor = this.enemyActors.pop();
                enemyActor.reset(creeptsEnemy, position);
                break;
        }

        return enemyActor;
    }

    public increaseHealerActorsPoolSize(): void {
        for (let i = 0; i < this.increasePoolHealers; i ++) {
            this.healerEnemyActors.push(new HealerEnemyActor(this.scene));
        }
    }

    public increaseEnemyActorsPoolSize(): void {
        for (let i = 0; i < this.increasePoolDefault; i ++) {
            this.enemyActors.push(new EnemyActor(this.scene));
        }
    }

    public onEnemyRemovedFromBoard(enemyActor: EnemyActor): void {
        enemyActor.isActive = false;
        switch (enemyActor.type) {
            case EnemyType.HEALER:
                this.healerEnemyActors.push(<HealerEnemyActor>enemyActor);
                break;
            default:
                this.enemyActors.push(enemyActor);
                break;
        }
    }
}
