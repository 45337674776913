import { BattleScene } from "./BattleScene";
import { BattleManager } from "./BattleManager";
import { GameVars } from "../../GameVars";
import { GameManager } from "../../GameManager";

export class TutorialManager {

    public static isTurretPlaced: boolean;
    public static hasFirstWaveBeenReleased: boolean;
    public static isFirstEnemyKilled: boolean;
    public static isSpeedControlShown: boolean;
    public static isTurretFirstTimeUpgraded: boolean;
    public static isTutoriallCompleted: boolean;

    public static init(): void {
        
        TutorialManager.isTurretPlaced = false;
        TutorialManager.isFirstEnemyKilled = false;
        TutorialManager.isSpeedControlShown = false;
        TutorialManager.hasFirstWaveBeenReleased = false;
        TutorialManager.isTutoriallCompleted = false;

        BattleScene.currentInstance.hideButtonsAtTutorialStart();
    }

    public static onTurredSelected(): void {

        BattleScene.currentInstance.onTutorialTurredSelected();
    }

    public static onTurretPlaced(): void {

        // bloquar el boton de la torre 
        // mostrar el boton de next wave
       
        TutorialManager.isTurretPlaced = true;

        BattleScene.currentInstance.onTutorialTurretPlaced();
    }

    public static onFirstWaveReleased(): void {

        TutorialManager.hasFirstWaveBeenReleased = true;

        BattleScene.currentInstance.onFirstWaveReleased();
    }

    public static onFirstEnemyKilled(): void {

        TutorialManager.isFirstEnemyKilled = true;

        BattleManager.semiPause();

        BattleScene.currentInstance.onFirstEnemyKilled();
    }

    public static onTurretMenuShown(): void {

        BattleScene.currentInstance.onTurretMenuShown();
    }

    public static onTurretUpgraded(): void {
        BattleScene.currentInstance.boardContainer.tutorialTurretUpgraded();
        if (!this.isTurretFirstTimeUpgraded) {
            this.isTurretFirstTimeUpgraded = true;
            setTimeout(() => {
                BattleManager.semiResume();
                TutorialManager.isSpeedControlShown = true;
                BattleScene.currentInstance.onTurretUpgraded();
            }, 700);
        }
    }

    public static onSpeedX2(): void {

        BattleScene.currentInstance.onSpeedX2();
    }

    public static onSpeedX3(): void {

        BattleScene.currentInstance.onSpeedX3();
    }

    public static onSpeedX1(): void {

        TutorialManager.isTutoriallCompleted = true;

        BattleScene.currentInstance.onSpeedX1();

        setTimeout(() => {
            BattleScene.currentInstance.onTutorialOver();
        }, 1500);
    }

    public static onTutorialOver(): void {

        GameVars.gameData.tutorialSeen = true;
        GameManager.writeGameData();

        GameManager.enterBattleScene();
    }
}
