import { GameConstants } from "../../../GameConstants";
import { GameVars } from "../../../GameVars";
import { HUD } from "../hud/HUD";
import { AudioManager } from "../../../AudioManager";
import { BattleScene } from "../BattleScene";

export class CoinsEffect extends Phaser.GameObjects.Container {

    public static coinsOnStage: number;

    public n: number;

    private coins: {coin: Phaser.GameObjects.Image, f: number, levitatingSpeed: number, smoothFactor: number}[];
    private f: number;
    private endPx: number;
    private endPy: number;
    private areCoinsCloseToEndPosition: boolean;
   
    constructor(scene: Phaser.Scene) {

        super(scene);

        this.visible = false;

        this.endPx = GameConstants.GAME_WIDTH * .5 + 35;
        this.endPy = 32 * GameVars.scaleY;

        this.coins = [];

        for (let i = 0; i < 12; i ++) {

            const coin = new Phaser.GameObjects.Image(this.scene, 0, 0, "texture_atlas_1", "coin");
            coin.visible = false;
            this.add(coin);

            this.coins.push({coin: coin, f: 30 + Math.floor(35 * Math.random()), levitatingSpeed: .3 + .5 * Math.random(), smoothFactor: .01 + .0125 * Math.random()});
        }
    }

    public update(): void {

        if (!this.visible) {
            return;
        }

        this.f ++;

        let sumDistances = 0;
      
        for (let i = 0; i < this.n; i ++) {

            const coin = this.coins[i].coin;
            const framesLevitating = this.coins[i].f;
            const levitatingSpeed = this.coins[i].levitatingSpeed;

            if (this.f < framesLevitating) {

                if (i % 2 === 0) {
                    coin.x += levitatingSpeed;
                } else {
                    coin.x -= levitatingSpeed;
                }

                coin.y -= levitatingSpeed * .5 * GameVars.scaleY;

                let s = coin.scaleX;
                
                if (s < 1) {
                    s += .075;
                } 

                coin.setScale(s, s * GameVars.scaleY);

            } else {
                
                this.coins[i].smoothFactor *= 1.075;

                const dx = (this.endPx - coin.x) * this.coins[i].smoothFactor;
                const dy = (this.endPy - coin.y) * this.coins[i].smoothFactor;

                sumDistances += (Math.abs(this.endPx - coin.x) + Math.abs(this.endPy - coin.y));
        
                coin.x += dx;
                coin.y += dy;

                coin.alpha *= .975;
            }
        }   

        if (this.f > 50 && sumDistances < 50 && !this.areCoinsCloseToEndPosition) {

            this.areCoinsCloseToEndPosition = true;
            HUD.currentInstance.onCoinsReachedEndPosition();
            AudioManager.playSoundEffect("coins_collected");
        }

        if (this.f > 50 && sumDistances < 2.5) {

            BattleScene.currentInstance.onCoinsRemoved(this);
        }
    }

    public reset(x: number, y: number, value: number): void {

        this.visible = true;
        this.f = 0;
      
        this.areCoinsCloseToEndPosition = false;
        
        if (value <= 10) {
            this.n = 4;
        } else if (value <= 15) {
            this.n = 6;
        } else if (value <= 20) {
            this.n = 8;
        } else {
            this.n = 12;
        }

        for (let i = 0; i < this.coins.length; i ++) {

            const coin = this.coins[i].coin;
            coin.x = x + 12 - 24 * Math.random();
            coin.y = y + 20 - 30 * Math.random();
            coin.setScale(0);
            coin.alpha = 1;
            coin.visible = i < this.n;

            this.coins[i].smoothFactor = .02 + .02 * Math.random();

            if (GameVars.timeStepFactor === 8) {
                this.coins[i].smoothFactor *= 3; 
            }
        }

        AudioManager.playSoundEffect("coins_emitted");
    }
}
